import { useEffect, useState } from "react";
import { Api } from "../../../api";
import { FullCourseTestRun } from "../../../types";

export function useFullTests() {
  const [isLoadingTests, setIsLoadingTests] = useState(true);
  const [items, setItems] = useState<FullCourseTestRun[]>([]);
  useEffect(() => {
    if (!isLoadingTests) {
      return;
    }

    let didCancel = false;

    Api.onDemandTestRun
      .getAllFullTestRuns()
      .then(function ({ Items }) {
        if (didCancel) {
          return;
        }

        setItems(Items);
      })
      .catch(function (error) {
        if (didCancel) {
          return;
        }
        // handle error
        console.log(error);
      })
      .finally(function () {
        if (didCancel) {
          return;
        }

        setIsLoadingTests(false);
      });

    return () => {
      didCancel = true;
    };
  }, [isLoadingTests]);

  return {
    items,
    isLoadingTests,
  };
}
