import { Box, Typography } from "@mui/material";
import { FindElementByTextNavigateToAncestorAnchorHrefStep } from "../../../../../types";

interface IDisplayFindElementByTextNavigateToAncestorAnchorHref {
  step: FindElementByTextNavigateToAncestorAnchorHrefStep;
}

function DisplayFindElementByTextNavigateToAncestorAnchorHref({
  step,
}: IDisplayFindElementByTextNavigateToAncestorAnchorHref) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Typography>
        Find element ({step.metadata?.element}) with text: "
        {step.metadata?.text}". Navigate to ancestor href.
      </Typography>
    </Box>
  );
}

export default DisplayFindElementByTextNavigateToAncestorAnchorHref;
