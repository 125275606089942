import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

interface IWrappedSelectProps {
  hasError: boolean;
  shownErrorMessage: string;
  value: any;
  label: string;
  options: IWrappedSelectOption[];
  onChange: (event: SelectChangeEvent<any>) => void;
  disabled?: boolean;
}

export interface IWrappedSelectOption {
  value: any;
  label: string;
}

function WrappedSelect({
  hasError,
  shownErrorMessage,
  value,
  label,
  options,
  onChange,
  disabled,
}: IWrappedSelectProps) {
  return (
    <FormControl fullWidth sx={{
      marginTop: 1,
    }}>
      <InputLabel error={hasError}>{label}</InputLabel>
      <Select
        label={label}
        onChange={onChange}
        value={value}
        error={hasError}
        disabled={!!disabled}
      >
        {options.map(({ value, label }) => {
          return (
            <MenuItem value={value} key={value}>
              {label}
            </MenuItem>
          );
        })}
      </Select>
      {!!shownErrorMessage && (
        <FormHelperText error={hasError}>{shownErrorMessage}</FormHelperText>
      )}
    </FormControl>
  );
}

export default WrappedSelect;
