import { Box, Typography } from "@mui/material";
import { useMemo } from "react";
import { CenterLoadingSpinner } from "../../../components/centerLoadingSpinner";
import Status from "../../../components/status";
import { FullCourseMetadata, FullCourseTestRun, Status as StatusType, TeeTimeSchedulingSoftware } from "../../../types";
import CommonTeeTimeFields from "../courses/coursesTable/courseActions/commonTeeTimeDialog/commonTeeTimeFields";
import { ImageSliderProps } from "./imageSlider";
import ImageSliders from "./imageSliders";
import { useFullTest } from "./useFullTest";
import { LogDisplay } from "../../../components/logDisplay";
import WrappedTextField from "../../../components/wrappedTextField";

function FullTest() {
  const { isLoading, fullTestRun, course, bookingTimeDisplay } = useFullTest();
  const fullTestV2Folder = fullTestRun?.PK || "";
  const v2ImageSliderProps = useMemo<ImageSliderProps[]>(() => {
    return (
      [{
        folderKey: fullTestV2Folder,
        headerText: "Select Best Tee Time",
      }]
    );
  }, [fullTestV2Folder])

  const testRunIsComplete = !!fullTestRun && fullTestRun.status !== StatusType.PENDING;
  return (
    <Box
      sx={{
        padding: 2,
      }}
    >
      {isLoading || course === null ? (
        <CenterLoadingSpinner />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              flexBasis: 0,
              flexShrink: 0,
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 1,
              }}
            >
              <Typography variant="h6">
                Full Test Metadata For {(course as FullCourseMetadata).name}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Status
                  status={(fullTestRun as FullCourseTestRun).status}
                  tooltip={(fullTestRun as FullCourseTestRun).bookingAttemptData?.errorCode || ""}
                />
              </Box>
            </Box>
            <CommonTeeTimeFields
              fullCourseMetadata={course as FullCourseMetadata}
              isFullTestRun
              scheduleTeeTimeBase={fullTestRun?.teeTime}
              disabled
              requireUsernameAndPassword
              didUseDevelopmentTestScript={fullTestRun?.runWithDevelopmentScript}
            />
            {bookingTimeDisplay && (
              <WrappedTextField
                value={bookingTimeDisplay}
                label="Attempted Tee Time"
                disabled
              />
            )}
          </Box>
          {testRunIsComplete && (
            <Box
              sx={{
                flexGrow: 1,
                flexBasis: 0,
                flexShrink: 0,
                display: "flex",
                flexDirection: "column",
                width: "100%",
                gap: 2,
              }}
            >
              <ImageSliders imageSliders={v2ImageSliderProps} />
              {!!fullTestRun.bookingAttemptData && (
                <LogDisplay
                  logsWithTime={fullTestRun.bookingAttemptData.logs}
                  timezone={course.timezone}
                />
              )}
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}

export default FullTest;
