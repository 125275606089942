import { Box, Typography } from "@mui/material";
import { FindElementByTextStep } from "../../../../../types";

interface IDisplayFindElementByTextStep {
  step: FindElementByTextStep;
}

function DisplayFindElementByTextStep({ step }: IDisplayFindElementByTextStep) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Typography>
        Find element ({step.metadata?.element}) with text: "
        {step.metadata?.text}"
      </Typography>
    </Box>
  );
}

export default DisplayFindElementByTextStep;
