import { useEffect, useState } from "react";
import { Api } from "../../../../../../api";
import { useNavigateToFullTest } from "../../../../../../hooks/useNavigateToFullTest";
import { FullCourseTestRun, ScheduleTeeTimeCreateRequest } from "../../../../../../types";

export function useFullTestRunDialog(
  onClose: () => void,
) {
  const [request, setRequest] = useState<ScheduleTeeTimeCreateRequest | null>(
    null,
  );
  function onClickSubmitForm(
    scheduleTeeTimeCreateRequest: ScheduleTeeTimeCreateRequest,
  ) {
    setRequest(scheduleTeeTimeCreateRequest);
  }

  const navigateToFullTest = useNavigateToFullTest();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [fullCourseTestRun, setFullCourseTestRun] = useState<null | FullCourseTestRun>(null);
  const [hideDialogInternal, setHideDialogInternal] = useState(false);
  useEffect(() => {
    if (!request) {
      return;
    }

    let didCancel = false;

    Api.onDemandTestRun
      .createFullTest(request)
      .then((fullCourseTestRunFromData) => {
        if (didCancel) {
          return;
        }

        setFullCourseTestRun(fullCourseTestRunFromData)
        setShowSnackbar(true);
        // Hiding the dialog instead of closing because the snackbar is included in the component
        // and closing the entire component gets rid of the snackbar as well. When we close the
        // snackbar, we close the whole component effectively resetting it for the next time
        // around.
        setHideDialogInternal(true);
      })
      .catch(() => {
        if (didCancel) {
          return;
        }
      })
      .finally(() => {
        if (didCancel) {
          return;
        }
        setRequest(null);
      });

    return () => {
      didCancel = true;
    };
  }, [request]);

  function onClickAction() {
    if (fullCourseTestRun) {
      navigateToFullTest(fullCourseTestRun);
    }
  }

  return {
    onClickSubmitForm,
    actionInProgress: !!request,
    fullCourseTestRun,
    showSnackbar,
    navigateToFullTest,
    onClickAction,
    hideDialogInternal,
  };
}
