import { Box, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { Api } from "../../api";
import FullTestRunDialog from "../../oneTimeComponents/admin/courses/coursesTable/courseActions/fullTestRunDialog";
import { FullCourseMetadata, ScheduleTeeTimeBase } from "../../types";
import { CenterLoadingSpinner } from "../centerLoadingSpinner";

interface AutofillFullTestMenuItemProps {
    scheduleTeeTimeBase: ScheduleTeeTimeBase;
    didUseDevelopmentTestScript?: boolean;
}

function AutofillFullTestMenuItem(
    {
        scheduleTeeTimeBase,
        didUseDevelopmentTestScript,
    }: AutofillFullTestMenuItemProps
) {
    const [isFullTestRunOpen, setIsFullTestRunOpen] = useState(false);
    const [isLoadingCourse, setIsLoadingCourse] = useState(false);
    function onClickAutofillFullTest() {
        if (!fullCourseMetadata) {
            setIsLoadingCourse(true);
        }
        setIsFullTestRunOpen(true);
    }
    function onCloseAutofillFullTest() {
        setIsFullTestRunOpen(false);
    }

    const [fullCourseMetadata, setFullCourseMetadata] = useState<null | FullCourseMetadata>(null);
    useEffect(() => {
        if (!isLoadingCourse) {
            return;
        }

        let didCancel = false;

        Api.course.getCourse(scheduleTeeTimeBase.coursePK).then((courseFromData) => {
            if (didCancel) {
                return;
            }

            setFullCourseMetadata(courseFromData);
            setIsLoadingCourse(false);
        });

        return () => {
            didCancel = true;
        };
    }, [isLoadingCourse]);

    console.log(scheduleTeeTimeBase);


    return (
        <>
            <Box sx={{
                position: "relative",
            }}>
                {isLoadingCourse && (
                    <Box sx={{
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                        <CenterLoadingSpinner />
                    </Box>
                )}
                <MenuItem onClick={onClickAutofillFullTest}>Autofill Full Test</MenuItem>
                {isFullTestRunOpen && fullCourseMetadata && (
                    <FullTestRunDialog
                        onClose={onCloseAutofillFullTest}
                        fullCourseMetadata={fullCourseMetadata}
                        scheduleTeeTimeBase={scheduleTeeTimeBase}
                        didUseDevelopmentTestScript={didUseDevelopmentTestScript}
                    />
                )}
            </Box>
        </>
    )
}

export default AutofillFullTestMenuItem;