import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import AutofillFullTestMenuItem from "../../../../../components/autofillFullTestMenuItem";
import { FullCourseTestRun } from "../../../../../types";

interface FullTestRunTableActionsProps {
  fullCourseTestRun: FullCourseTestRun;
}

function FullTestRunTableActions({
  fullCourseTestRun,
}: FullTestRunTableActionsProps) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const onClose = () => {
    setAnchorEl(null);
  };

  const onClickViewDetails = () => {
    const pkWithoutStart = fullCourseTestRun.PK.replace(
      "FULL_COURSE_TEST_RUN#",
      "",
    );
    navigate(`/app/admin/full-tests/${pkWithoutStart}`);
  };

  return (
    <>
      <IconButton aria-label="edit" onClick={onClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={onClose}>
        <MenuItem onClick={onClickViewDetails}>View Details</MenuItem>
        <AutofillFullTestMenuItem scheduleTeeTimeBase={fullCourseTestRun.teeTime} didUseDevelopmentTestScript={fullCourseTestRun.runWithDevelopmentScript} />
      </Menu>
    </>
  );
}

export default FullTestRunTableActions;
