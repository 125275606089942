import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import { ReactNode } from "react";
import { CenterLoadingSpinner } from "../centerLoadingSpinner";
import WrappedButton from "../wrappedButton";

interface IWrappedTableProps<T> {
  title: string;
  headerActionButton?: IWrappedHeaderActionButton;
  items: T[];
  columns: IWrappedTableColumn<T>[];
  createDataKey: (item: T) => string;
  isLoading?: boolean;
  noItemsText: string;
  reactNodeInlineWithActionButton?: ReactNode;
}

export interface IWrappedHeaderActionButton {
  text: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export interface IWrappedTableColumn<T> {
  text: string;
  key: string;
  createContent: (item: T) => React.ReactNode;
}

function WrappedTable<T>({
  title,
  headerActionButton,
  items,
  columns,
  createDataKey,
  isLoading,
  noItemsText,
  reactNodeInlineWithActionButton,
}: IWrappedTableProps<T>) {
  return (
    <Paper>
      <Toolbar
        sx={{
          padding: 2,
        }}
      >
        <Box sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          width: "100%",
        }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6">{title}</Typography>
            <Box sx={{
              display: "flex",
              flexDirection: "row",
              gap: 1,
            }}>
              {reactNodeInlineWithActionButton}
              {!!headerActionButton && (
                <WrappedButton onClick={headerActionButton.onClick} color="primary" variant="contained">
                  {headerActionButton.text}
                </WrappedButton>
              )}
            </Box>
          </Box>
        </Box>
      </Toolbar>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map(({ text, key }) => {
              return <TableCell key={key}>{text}</TableCell>;
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item) => {
            const key = createDataKey(item);
            return (
              <TableRow key={key}>
                {columns.map(({ key, createContent }) => {
                  return <TableCell key={key}>{createContent(item)}</TableCell>;
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {(isLoading || items.length === 0) && (
        <Box
          sx={{
            width: "100%",
            height: "200px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {isLoading ? (
            <CenterLoadingSpinner />
          ) : (
            <Typography>{noItemsText}</Typography>
          )}
        </Box>
      )}
    </Paper>
  );
}

export default WrappedTable;
