import { Box, Checkbox, FormControlLabel, FormHelperText } from "@mui/material";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import BoxWithBorder from "../../../../../../../components/boxWithBorder";
import WrappedSelect from "../../../../../../../components/wrappedSelect";
import WrappedTextField from "../../../../../../../components/wrappedTextField";
import {
  FullCourseMetadata,
  ScheduleTeeTimeBase,
} from "../../../../../../../types";
import { useCommonTeeTimeDialog } from "../useCommonTeeTimeDialog";

interface ICommonTeeTimeFieldProps {
  fullCourseMetadata: FullCourseMetadata;
  isFullTestRun: boolean;
  requireUsernameAndPassword?: boolean;
  disabled?: boolean;
  scheduleTeeTimeBase?: ScheduleTeeTimeBase;
  didUseDevelopmentTestScript?: boolean;
}

function CommonTeeTimeFields({
  fullCourseMetadata,
  isFullTestRun,
  requireUsernameAndPassword,
  disabled,
  scheduleTeeTimeBase,
  didUseDevelopmentTestScript,
}: ICommonTeeTimeFieldProps) {
  const {
    playerControl,
    playerOptions,
    rangeEndControl,
    rangeStartControl,
    dateControl,
    shouldDisableDate,
    dateHelperText,
    teeTimeHoleControl,
    teeTimeHoleOptions,
    courseTransitControl,
    courseTransitOptions,
    paymentOptionControl,
    paymentOptions,
    usernameControl,
    passwordControl,
    runWithDevelopmentScript,
    onChangeRunWithDevelopmentScript,
  } = useCommonTeeTimeDialog({
    isFullTestRun,
    requireUsernameAndPassword: !!requireUsernameAndPassword,
    fullCourseMetadata,
    scheduleTeeTimeBase,
    disabled,
    didUseDevelopmentTestScript,
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        paddingTop: 1,
        paddingBottom: 1,
      }}
    >
      <WrappedSelect
        label="Players"
        onChange={playerControl.onChange}
        value={playerControl.value}
        options={playerOptions}
        hasError={playerControl.hasError}
        shownErrorMessage={playerControl.shownErrorMessage}
        disabled={disabled}
      />
      {playerControl.value && (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <DatePicker
              onChange={dateControl.onChange}
              shouldDisableDate={shouldDisableDate}
              label="What Day Do You Want To Golf?"
              disabled={disabled}
              value={dateControl.value}
            />
            {dateControl.shownErrorMessage ? (
              <FormHelperText error>
                {dateControl.shownErrorMessage}
              </FormHelperText>
            ) : (
              <FormHelperText>{dateHelperText}</FormHelperText>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 1,
              paddingTop: 2,
            }}
          >
            <TimePicker
              sx={{
                flexGrow: 1,
              }}
              label="Earliest You Want To Start Golfing"
              value={rangeStartControl.value}
              onChange={rangeStartControl.onChange}
              disabled={disabled}
            />
            <TimePicker
              sx={{
                flexGrow: 1,
              }}
              label="Latest You Want To Start Golfing"
              value={rangeEndControl.value}
              onChange={rangeEndControl.onChange}
              disabled={disabled}
            />
          </Box>
          <WrappedSelect
            label="Holes"
            onChange={teeTimeHoleControl.onChange}
            value={teeTimeHoleControl.value}
            options={teeTimeHoleOptions}
            hasError={teeTimeHoleControl.hasError}
            shownErrorMessage={teeTimeHoleControl.shownErrorMessage}
            disabled={disabled}
          />
          <WrappedSelect
            label="Transit Preference"
            onChange={courseTransitControl.onChange}
            value={courseTransitControl.value}
            options={courseTransitOptions}
            hasError={courseTransitControl.hasError}
            shownErrorMessage={courseTransitControl.shownErrorMessage}
            disabled={disabled}
          />
          <WrappedSelect
            label="Payment Preference"
            onChange={paymentOptionControl.onChange}
            value={paymentOptionControl.value}
            options={paymentOptions}
            hasError={paymentOptionControl.hasError}
            shownErrorMessage={paymentOptionControl.shownErrorMessage}
            disabled={disabled}
          />
          {requireUsernameAndPassword && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
              }}
            >
              <WrappedTextField
                label="Username"
                type="text"
                onChange={usernameControl.onChange}
                error={usernameControl.hasError}
                helperText={usernameControl.shownErrorMessage}
                value={usernameControl.value}
                disabled={disabled}
              />
              <WrappedTextField
                label="Password"
                type="text"
                onChange={passwordControl.onChange}
                error={passwordControl.hasError}
                helperText={passwordControl.shownErrorMessage}
                value={passwordControl.value}
                disabled={disabled}
              />
            </Box>
          )}
          {isFullTestRun && (
            <BoxWithBorder sx={{
              display: "flex",
              flexDirection: "row",
            }}>
              <FormControlLabel disabled={disabled} control={<Checkbox checked={runWithDevelopmentScript} onChange={onChangeRunWithDevelopmentScript} />} label={"Run With Development Script"} />
            </BoxWithBorder>
          )}
        </>
      )}
    </Box>
  );
}

export default CommonTeeTimeFields;
