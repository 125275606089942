import {
    ScheduleChronoTeeTimeStep,
    Step,
    StepType,
} from "../../../../../../../types";

export function useScheduleChronoTeeTimeStep(
    onClickCreateStep: (step: Step) => void,
) {
    function onSubmit() {
        const scheduleForeUpTeeTimeStep: ScheduleChronoTeeTimeStep = {
            type: StepType.ScheduleChronoUpTeeTime,
        };

        onClickCreateStep(scheduleForeUpTeeTimeStep);
    }

    return {
        onSubmit,
    };
}
