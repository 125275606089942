import { IControl } from "../../hooks/useControl";

export function allControlsAreValid(...controls: IControl<any, any>[]) {
  for (let control of controls) {
    if (!control.isValid) {
      return false;
    }
  }

  return true;
}
