import { ChangeEvent } from "react";
import { useControl } from "../../../hooks/useControl";
import { SimpleCourse } from "../../../types";


export function useCourseSelector(
    courses: SimpleCourse[],
    activeCoursePK?: string,
) {
    const searchControl = useControl({
        value: "",
        onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            return event.target.value;
        },
    });

    const filteredCourses = courses.filter((course) => {
        const value = searchControl.value.trim().toLowerCase();
        if (value === "") {
            return true;
        } else {
            return course.name.toLowerCase().includes(value) || course.city.toLowerCase().includes(value) || course.state.toLowerCase().includes(value);
        }
    }, []);

    const activeCourse = courses.find((course) => {
        return course.PK === activeCoursePK;
    });

    return {
        filteredCourses,
        searchControl,
        activeCourse,
    }
}