import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import AutofillFullTestMenuItem from "../../../../../components/autofillFullTestMenuItem";
import { ScheduleTeeTimeBase } from "../../../../../types";

interface ScheduledTeeTimeActionsProps {
  onClickDelete: () => void;
  scheduleTeeTimeBase: ScheduleTeeTimeBase;
}

function ScheduledTeeTimeActions({
  onClickDelete,
  scheduleTeeTimeBase,
}: ScheduledTeeTimeActionsProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const onClose = () => {
    setAnchorEl(null);
  };

  const onClickDeleteInternal = () => {
    onClose();
    onClickDelete();
  };

  const navigate = useNavigate();
  const onClickViewDetails = () => {
    const pkWithoutStart = scheduleTeeTimeBase.PK.replace(
      "SCHEDULED_TEE_TIME#",
      "",
    );
    console.log(pkWithoutStart);
    navigate(`/app/admin/scheduled-tee-times/${pkWithoutStart}`);
  };

  return (
    <>
      <IconButton aria-label="edit" onClick={onClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={onClose}>
        <MenuItem onClick={onClickDeleteInternal}>Delete</MenuItem>
        <MenuItem onClick={onClickViewDetails}>View Details</MenuItem>
        <AutofillFullTestMenuItem scheduleTeeTimeBase={scheduleTeeTimeBase} />
      </Menu>
    </>
  );
}

export default ScheduledTeeTimeActions;
