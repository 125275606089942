import { useNavigate } from "react-router-dom";
import { useBreakpoint } from "../../hooks/useBreakpoint";

export function useUnauthenticatedAppContainer() {
    const breakpoints = useBreakpoint();
    const logoSize = breakpoints.max768 ? "small" : "large" as "small" | "large";

    const navigate = useNavigate();
    function onClickSignUp() {
        navigate("/sign-up");
    }
    function onClickLogin() {
        navigate("/login")
    }

    function onClickLogo() {
        navigate("/");
    }

    return {
        onClickLogin,
        onClickSignUp,
        onClickLogo,
        logoSize,
    }
}