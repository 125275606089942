import { useEffect, useState } from "react";
import { Api } from "../../../api";
import { User } from "../../../types";

export function useUsers() {
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    if (!isLoading) {
      return;
    }

    let didCancel = false;

    Api.user
      .getAllUsers()
      .then(({ Items }) => {
        if (didCancel) {
          return;
        }

        setUsers(Items);
      })
      .catch(() => {
        if (didCancel) {
          return;
        }
      })
      .finally(() => {
        if (didCancel) {
          return;
        }
        setIsLoading(false);
      });

    return () => {
      didCancel = true;
    };
  }, [isLoading]);

  return {
    isLoading,
    users,
  };
}
