import { Box, Typography } from "@mui/material";
import { ConditionalClickElementWithTextStep } from "../../../../../../types";

interface IDisplayConditionalClickElementWithTextStep {
    step: ConditionalClickElementWithTextStep;
}

function DisplayConditionalClickElementWithTextStep({
    step,
}: IDisplayConditionalClickElementWithTextStep) {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
            }}
        >
            {step.metadata.conditions.map((condition, index) => {
                const conditionText = index === 0 ? "If" : "Else If";
                const qualifierText = (function () {
                    if (condition.holes === "ignore") {
                        return `${condition.players} players `;
                    } else if (condition.players === "ignore") {
                        return `${condition.holes} holes `;
                    } else {
                        return `${condition.players} players and ${condition.holes} holes `
                    }
                })();
                const actionText = `click the ${condition.element} with text "${condition.text}" .`;
                return (
                    <Typography>
                        <Typography component="span"><strong>{conditionText}</strong></Typography>
                        {" "}<Typography component="span">{qualifierText}{actionText}</Typography>
                    </Typography>
                )
            })}
        </Box>
    );
}

export default DisplayConditionalClickElementWithTextStep;
