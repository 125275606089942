import axios from "axios";
import { environmentVariables } from "../environmentVariables";

export interface ISignedUrlsAPI {
  getSignedUrls(folderKey: string): Promise<
    {
      description: string;
      signedURL: string;
      lastModified: string | null;
    }[]
  >;
}

export class SignedURLApi implements ISignedUrlsAPI {
  public async getSignedUrls(folderKey: string): Promise<
    {
      description: string;
      signedURL: string;
      lastModified: string | null;
    }[]
  > {
    const axiosResponse = await axios.get<{
      signedUrlsWithMetadata: {
        description: string;
        signedURL: string;
        lastModified: string | null;
      }[];
    }>(`${environmentVariables.baseAuthenticatedApiUrl}/in-order-signed-urls`, {
      params: {
        folderKey: folderKey,
      },
    });

    return axiosResponse.data.signedUrlsWithMetadata;
  }
}
