import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { OffsetByPlayers } from "../../../../../types";
import CustomSchedulingOffset from "./customSchedulingOffset";
import SharedSchedulingOffset from "./sharedSchedulingOffset";
import { useSchedulingOffset } from "./useSchedulingOffset";

interface ISchedulingOffsetProps {
  schedulingOffset: OffsetByPlayers;
  onChangeSchedulingOffset: (offsetByPlayers: OffsetByPlayers) => void;
}

function SchedulingOffset({
  schedulingOffset,
  onChangeSchedulingOffset,
}: ISchedulingOffsetProps) {
  const { isCustom, onChangeIsCustom, onChangeAllSchedulingOffset } =
    useSchedulingOffset(onChangeSchedulingOffset, schedulingOffset);

  return (
    <Box
      sx={{
        border: 1,
        borderRadius: 1,
        padding: 1,
        borderColor: "grey.400",
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Typography variant="h6">Scheduling Offset</Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 4,
        }}
      >
        <ToggleButtonGroup
          color="primary"
          value={isCustom}
          exclusive
          onChange={onChangeIsCustom}
        >
          <ToggleButton value={false}>Common</ToggleButton>
          <ToggleButton value={true}>Custom</ToggleButton>
        </ToggleButtonGroup>
        {!isCustom ? (
          <SharedSchedulingOffset
            isAll
            onChangeSchedulingOffset={onChangeAllSchedulingOffset}
            schedulingOffset={schedulingOffset[1]}
          />
        ) : (
          <CustomSchedulingOffset
            onChangeSchedulingOffset={onChangeSchedulingOffset}
            schedulingOffset={schedulingOffset}
          />
        )}
      </Box>
    </Box>
  );
}

export default SchedulingOffset;
