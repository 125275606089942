import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SavedUsernameAndPassword } from '../types'

export interface CredentialsState {
    credentials: SavedUsernameAndPassword[]
}

const initialState: CredentialsState = {
    credentials: [],
}

export const credentialsSlice = createSlice({
    name: 'credentials',
    initialState,
    reducers: {
        setCredentials: (state, action: PayloadAction<SavedUsernameAndPassword[]>) => {
            state.credentials = action.payload;
        },
    },
})

// Action creators are generated for each case reducer function
export const { setCredentials } = credentialsSlice.actions

export default credentialsSlice.reducer;