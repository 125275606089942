import { Box, Checkbox, FormControlLabel, Link, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { ChangeEvent, ReactNode, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Api } from "../../../api";
import { StringValidator } from "../../../classes/stringValidator";
import BoxWithBorder from "../../../components/boxWithBorder";
import WrappedButton from "../../../components/wrappedButton";
import { useControl } from "../../../hooks/useControl";
import { RootState } from "../../../redux/store";
import { ScheduleTeeTimeEditRequestV2, SimpleCourse, TeeTimeSchedulingSoftware } from "../../../types";
import CourseCredentialsStep from "../courseCredentialsStep";
import CourseSelector from "../courseSelector";
import { teeTimeWillBeAvailableForCourse } from "./teeTimeWillBeAvailableForCourse";

interface TabMetadata {
    title: string,
    nextDisabled: boolean,
    key: string,
    element?: ReactNode,
}

export function useBackupTeeTimes() {
    const [searchParams] = useSearchParams();
    const teeTime = useSelector((state: RootState) => {
        const teeTimePK = searchParams.get("teeTime");
        if (teeTimePK === null) {
            return undefined;
        }

        return state.teeTimes.teeTimes.find((teeTime) => {
            return teeTime.PK.includes(teeTimePK);
        });
    });
    const isAfterTeeTimeBooking = !!searchParams.get("isAfterTeeTimeBooking");

    function onClickCourse(simpleCourse: SimpleCourse) {
        setSimpleCoursePK(simpleCourse.PK);
        delayedOnClickNext();
    }
    function onClearCourse() {
        setSimpleCoursePK("");
    }

    const [simpleCoursePK, setSimpleCoursePK] = useState<string>(() => {
        const backupTeeTimes = teeTime?.backupTeeTimes;
        if (!!backupTeeTimes) {
            const backup = backupTeeTimes[0];
            if (!!backup) {
                return backup.coursePK;
            }
        }

        return "";
    });
    const simpleCoursesDoNotUse = useSelector((state: RootState) => {
        return state.simpleCourses.courses;
    });
    const filteredSimpleCourses = useMemo(() => {
        const now = DateTime.now();
        return simpleCoursesDoNotUse.filter((compareCourse) => {
            const isPrimaryCourse = compareCourse.PK === teeTime?.coursePK;
            if (isPrimaryCourse) {
                return false;
            }

            if (teeTime) {
                return teeTimeWillBeAvailableForCourse(
                    teeTime,
                    compareCourse,
                    now,
                )
            }

            return true;
        })
    }, [simpleCoursesDoNotUse, teeTime])
    const simpleCourse = useMemo(() => {
        return simpleCoursesDoNotUse.find((course) => {
            return course.PK === simpleCoursePK;
        }) || null;
    }, [simpleCoursesDoNotUse, simpleCoursePK]);

    const [request, setRequest] = useState<ScheduleTeeTimeEditRequestV2 | null>(null);
    function onClickEditTeeTime() {
        if (!teeTime) {
            return;
        }

        const {
            day,
            month,
            year,
            hour: rangeStartInclusiveHour,
            minute: rangeStartInclusiveMinute,
        } = DateTime.fromISO(teeTime.rangeStartInclusive).setZone(teeTime.timezone);
        const {
            hour: rangeEndInclusiveHour,
            minute: rangeEndInclusiveMinute,
        } = DateTime.fromISO(teeTime.rangeEndInclusive).setZone(teeTime.timezone);

        const scheduleTeeTimeEditRequest: ScheduleTeeTimeEditRequestV2 = {
            day,
            month,
            year,
            rangeStartInclusive: {
                hour: rangeStartInclusiveHour,
                minute: rangeStartInclusiveMinute,
            },
            rangeEndInclusive: {
                hour: rangeEndInclusiveHour,
                minute: rangeEndInclusiveMinute,
            },
            players: teeTime.players,
            holes: teeTime.holes,
            transitOption: teeTime.transitOption,
            paymentOption: teeTime.paymentOption,
            courses: [{
                PK: teeTime.coursePK,
                login: teeTime.login || "",
                password: teeTime.password || "",
            }, {
                PK: simpleCourse?.PK || "",
                login: usernameControl.value,
                password: passwordControl.value,
            }],
            teeTimePKToDelete: teeTime.PK,
        }
        setRequest(scheduleTeeTimeEditRequest);
    }

    const cardInstructionsContent = (function () {
        if (simpleCourse?.teeTimeSchedulingSoftware === TeeTimeSchedulingSoftware.ForeUp) {
            return (
                <Box>
                    <Typography><strong>1.</strong> Log in to the <Link href={`https://foreupsoftware.com/index.php/booking/index/${simpleCourse.courseID}#/teetimes`} target="_blank" rel="noopener" underline="none">course page ({simpleCourse.name})</Link></Typography>
                    <Typography><strong>2.</strong> Verify a card is on file or <Link href={`https://foreupsoftware.com/index.php/booking/index/${simpleCourse.courseID}#/account/billing/payment-methods`} target="_blank" rel="noopener" underline="none">add a card ({simpleCourse.name})</Link> if none is present.</Typography>
                </Box>
            );
        }

        if (simpleCourse?.teeTimeSchedulingSoftware === TeeTimeSchedulingSoftware.Chrono) {
            return (
                <Box>
                    <Typography><strong>1.</strong> Log in to the <Link href="https://www.chronogolf.com/login" target="_blank" rel="noopener" underline="none">course page ({simpleCourse.name})</Link></Typography>
                    <Typography><strong>2.</strong> Verify a card is on file or <Link href={`https://www.chronogolf.com/dashboard/#/club-credit-cards/${simpleCourse.courseID}`} target="_blank" rel="noopener" underline="none">add a card ({simpleCourse.name})</Link> if none is present.</Typography>
                </Box>
            )
        }
    })();

    const navigate = useNavigate();
    useEffect(() => {
        if (request === null || !teeTime) {
            return;
        }

        let didCancel = false;

        Api.scheduledTeeTime.editScheduledTeeTimeV2({
            ...request,
            teeTimePKToDelete: teeTime.PK,
        }).then(() => {
            if (didCancel) {
                return;
            }

            navigate("/app/user/tee-times");
        }).finally(() => {
            if (didCancel) {
                return;
            }

            setRequest(null);
        });

        return () => {
            didCancel = true;
        }
    }, [request]);

    function onClickBackToTeeTimeList() {
        navigate("/app/user/tee-times");
    }

    const credentials = useSelector((state: RootState) => {
        if (!simpleCourse) {
            return null;
        }

        const usernameAndPassword = state.credentials.credentials.find((credential) => {
            if (simpleCourse.teeTimeSchedulingSoftware === TeeTimeSchedulingSoftware.ForeUp) {
                const dynamoCourseID = simpleCourse.PK.replace("COURSE#", "");
                return credential.PK.includes(dynamoCourseID);
            }

            if (simpleCourse.teeTimeSchedulingSoftware === TeeTimeSchedulingSoftware.Chrono) {
                return credential.PK === "PASSWORD_CHRONO";
            }

            return false;
        });

        return usernameAndPassword || null;
    });

    const usernameControl = useControl({
        value: "",
        onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            return event.target.value;
        },
        validatorError: (username: string) => {
            const stringValidator = new StringValidator();
            return stringValidator.required("").validate(username);
        },
    });

    const passwordControl = useControl({
        value: "",
        onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            return event.target.value;
        },
        validatorError: (password: string) => {
            const stringValidator = new StringValidator();
            return stringValidator.required("").validate(password);
        },
    });

    useEffect(() => {
        usernameControl.resetControlState(credentials?.login || "");
        passwordControl.resetControlState(credentials?.password || "");
    }, [credentials]);

    const [hasVerifiedInformation, setHasVerifiedInformation] = useState(false);
    const onChangeHasVerifiedInformation: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void = (
        _unused,
        checked,
    ) => {
        setHasVerifiedInformation(checked);
    }
    const teeTimeReminderMessage = "I have verified a card is on file with the course (if available)."

    const steps: TabMetadata[] = [{
        title: isAfterTeeTimeBooking ? "Add A Backup Course?" : "Additional Course",
        nextDisabled: false,
        key: "backup-tee-time-description",
        element: (function () {
            return (
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                }}>
                    <Typography>Along with <strong>{teeTime?.courseName}</strong>, you can select one other course for your booking at no extra charge. Green Fee Pro will work to secure the first available tee time at either of the two courses giving preference to the public availability date.</Typography>
                    {isAfterTeeTimeBooking && (
                        <WrappedButton
                            onClick={onClickBackToTeeTimeList}
                            variant="outlined"
                        >
                            No Thanks - Back to Tee Time List
                        </WrappedButton>
                    )}
                </Box>
            )
        })(),
    }, {
        title: "Select Course",
        nextDisabled: !simpleCoursePK,
        key: "select-course",
        element: (
            <Box sx={{
                maxHeight: "400px",
            }}>
                <CourseSelector activeCoursePK={simpleCoursePK} courses={filteredSimpleCourses} onClickCourse={onClickCourse} showFiltering onClearCourse={onClearCourse} />
            </Box>
        )
    }, {
        title: `Credentials For ${simpleCourse?.name}`,
        nextDisabled: !usernameControl.isValid || !passwordControl.isValid,
        key: "credentials",
        element: (
            <CourseCredentialsStep usernameControl={usernameControl} passwordControl={passwordControl} simpleCourse={simpleCourse || undefined} />
        )
    }, {
        title: "Verify Card On File",
        nextDisabled: !hasVerifiedInformation,
        key: "card-on-file",
        element: (
            <Box sx={{
                display: "flex",
                gap: 2,
                flexDirection: "column",
            }}>
                <BoxWithBorder sx={{
                    display: "flex",
                    flexDirection: "row",
                }}>
                    <FormControlLabel control={<Checkbox checked={hasVerifiedInformation} onChange={onChangeHasVerifiedInformation} />} label={teeTimeReminderMessage} />
                </BoxWithBorder>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                }}>
                    <Typography><strong>Verification Instructions</strong></Typography>
                    {cardInstructionsContent}
                </Box>
            </Box>
        )
    }];

    const [activeKey, setActiveKey] = useState(() => {
        return searchParams.get("initialActiveStepKey") || steps[0].key;
    });
    const activeStepIndex = steps.findIndex((step) => {
        return step.key === activeKey;
    })
    function onClickNext() {
        const nextKey = steps[activeStepIndex + 1].key;
        setActiveKey(nextKey);
    }
    function delayedOnClickNext() {
        setTimeout(() => {
            onClickNext();
        }, 300);
    }

    function onClickBack() {
        const lastKey = steps[activeStepIndex - 1].key;
        setActiveKey(lastKey);
    }

    const activeStep = steps[activeStepIndex];
    const isLastStep = activeStepIndex === steps.length - 1;
    const disableNext = isLastStep || activeStep.nextDisabled;
    const isSubmitting = !!request;
    const linearProgressValue = ((activeStepIndex + 1) / steps.length * 100);

    return {
        onClickEditTeeTime,
        activeStep,
        steps,
        onClickBack,
        onClickNext,
        disableNext,
        activeStepIndex,
        isLastStep,
        hasVerifiedInformation,
        onClickBackToTeeTimeList,
        isSubmitting,
        linearProgressValue,
    }
}