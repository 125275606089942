import { FullCourseTestRun } from "../../types";

export function useNavigateToFullTest() {
    return function (fullCourseTestRun: FullCourseTestRun) {
        const pkWithoutStart = fullCourseTestRun.PK.replace(
            "FULL_COURSE_TEST_RUN#",
            "",
        );
        window.open(`/app/admin/full-tests/${pkWithoutStart}`)
    }
}