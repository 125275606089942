import { Box } from "@mui/material";
import WrappedButton from "../../../../../../../components/wrappedButton";
import { Step } from "../../../../../../../types";
import { useScheduleChronoTeeTimeStep } from "./useScheduleChronoTeeTimeStep";

interface IScheduleForeUpTeeTimeProps {
    onClickCreateStep: (step: Step) => void;
}

function ScheduleChronoTeeTimeStep({
    onClickCreateStep,
}: IScheduleForeUpTeeTimeProps) {
    const { onSubmit } = useScheduleChronoTeeTimeStep(onClickCreateStep);

    return (
        <form onSubmit={onSubmit}>
            <Box
                sx={{
                    display: "flex",
                    gap: 2,
                    flexDirection: "column",
                }}
            >
                <WrappedButton type="button" onClick={onSubmit}>
                    Create Step
                </WrappedButton>
            </Box>
        </form>
    );
}

export default ScheduleChronoTeeTimeStep;
