import BoxWithBorder from "../../../../components/boxWithBorder";
import ImageSlider, { ImageSliderProps } from "../imageSlider";

interface ImageSlidersProps {
    imageSliders: ImageSliderProps[],
}

/**
 * Simple component to display the image sliders in a nice way.
 */
function ImageSliders({ imageSliders }: ImageSlidersProps) {
    const children = imageSliders.map((imageSlider, index) => {
        return <ImageSlider {...imageSlider} />
    });

    return (
        <BoxWithBorder sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            padding: 2,
        }}>
            {children}
        </BoxWithBorder>
    )
}

export default ImageSliders;