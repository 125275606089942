import { Box, Typography } from "@mui/material";
import { UnauthenticatedAppContainer } from "../../components/unauthenticatedAppContainer";
import WrappedButton from "../../components/wrappedButton";
import WrappedSnackbar from "../../components/wrappedSnackbar";
import WrappedTextField from "../../components/wrappedTextField";
import { useResetPassword } from "./useResetPassword";

function ResetPassword() {
    const {
        emailControl,
        formIsValid,
        onClickResetPassword,
        onSubmit,
        errorMessage,
        clearErrorMessage,
        isTriggeringPasswordReset,
    } = useResetPassword();

    return (
        <UnauthenticatedAppContainer>
            <Box
                sx={{
                    flexGrow: "1",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Box sx={{
                    width: "100%",
                    maxWidth: "600px",
                    paddingLeft: 2,
                    paddingRight: 2,
                }}>
                    <form onSubmit={onSubmit}>
                        <Box
                            sx={{
                                flexDirection: "column",
                                display: "flex",
                                gap: 2,
                            }}
                        >
                            <Box
                                sx={{
                                    marginBottom: 2,
                                    gap: 2,
                                }}
                            >
                                <Typography variant="h5">Reset Password</Typography>
                            </Box>
                            <WrappedTextField
                                value={emailControl.value}
                                label="Email"
                                onChange={emailControl.onChange}
                                error={emailControl.hasError}
                                helperText={emailControl.shownErrorMessage}
                            />
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <WrappedButton
                                    onClick={onClickResetPassword}
                                    disabled={!formIsValid || isTriggeringPasswordReset}
                                    showSpinner={isTriggeringPasswordReset}
                                    variant="text"
                                    type="submit"
                                >
                                    Reset Password
                                </WrappedButton>
                                <WrappedSnackbar
                                    open={!!errorMessage}
                                    onClose={clearErrorMessage}
                                    message={errorMessage}
                                />
                            </Box>
                        </Box>
                    </form>
                </Box>
            </Box>
        </UnauthenticatedAppContainer>
    );
}

export default ResetPassword;
