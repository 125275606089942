import { Box, Typography } from "@mui/material";
import { cloneDeep } from "lodash";
import { Step } from "../../../../../types";
import AddDeleteStepWrapper from "./addDeleteStepWrapper";
import CreateStepButton from "./createStepButton";

export interface IStepsProps {
  steps: Step[];
  setSteps: React.Dispatch<React.SetStateAction<Step[]>>;
}

function Steps({ steps, setSteps }: IStepsProps) {
  function wrappedOnClickCreateStep(index: null | number) {
    return (step: Step) => {
      if (index === null) {
        setSteps([step]);
      } else {
        setSteps((existingSteps) => {
          const copy = cloneDeep(existingSteps);
          copy.splice(index, 0, step);
          return copy;
        });
      }
    };
  }

  function wrappedOnClickDeleteStep(index: number) {
    return () => {
      setSteps((existingSteps) => {
        const copy = cloneDeep(existingSteps);
        copy.splice(index, 1);
        return copy;
      });
    };
  }

  const displayedSteps = (function () {
    if (steps.length === 0) {
      return (
        <CreateStepButton
          onClickCreateStep={wrappedOnClickCreateStep(null)}
          text="Create Initial Step"
        />
      );
    }

    return steps.map((step, index) => {
      const onClickDeleteStep = wrappedOnClickDeleteStep(index);
      return (
        <AddDeleteStepWrapper
          key={index}
          step={step}
          index={index}
          wrappedOnClickCreateStep={wrappedOnClickCreateStep}
          onClickDeleteStep={onClickDeleteStep}
        />
      );
    });
  })();

  return (
    <Box
      sx={{
        border: 1,
        borderRadius: 1,
        padding: 1,
        borderColor: "grey.400",
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Typography variant="h6">Steps</Typography>
      {displayedSteps}
    </Box>
  );
}

export default Steps;
