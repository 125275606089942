import { Box, Typography } from "@mui/material";
import FullScreenPage from "../../components/fullScreenPage";
import WrappedButton from "../../components/wrappedButton";
import { useNotFound } from "./useNotFound";

function NotFound() {
    const { onClickGoToUserPortal } = useNotFound();

    return (
        <FullScreenPage sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: 2,
        }}>
            <Typography variant="h3">Oops, there's nothing here</Typography>
            <Box sx={{
                width: "400px",
                textAlign: "center",
            }}>
                <Typography>It looks like this page doesn't exist. Click the button below to navigate back to the user portal.</Typography>
            </Box>
            <Box>
                <WrappedButton color="primary" variant="outlined" onClick={onClickGoToUserPortal}>
                    User Portal
                </WrappedButton>
            </Box>
        </FullScreenPage>
    )
}

export default NotFound;