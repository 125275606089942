import { orderBy } from "lodash";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { Api } from "../../../api";
import { useSelector, useDispatch } from 'react-redux'
import { setTeeTimes } from "../../../redux/teeTimes";
import { RootState } from "../../../redux/store";
import { useControl } from "../../../hooks/useControl";
import { SelectChangeEvent } from "@mui/material";
import { Status } from "../../../types";

export function useUserTeeTimes() {
  const [isLoadingUserTeeTimes, setIsLoadingUserTeeTimes] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!isLoadingUserTeeTimes) {
      return;
    }

    let didCancel = false;

    Api.scheduledTeeTime.getTeeTimesForUser().then((teeTimes) => {
      if (didCancel) {
        return;
      }

      const setTeeTimesAction = setTeeTimes(teeTimes.Items);
      dispatch(setTeeTimesAction);
    }).catch(() => {
      if (didCancel) {
        return;
      }
    })
      .finally(() => {
        if (didCancel) {
          return;
        }

        setIsLoadingUserTeeTimes(false);
      });


    return () => {
      didCancel = true;
    };
  }, [isLoadingUserTeeTimes]);

  const [teeTimeOptions] = useState(() => {
    return [
      {
        value: "All",
        label: "All",
      },
      {
        value: "Confirmed",
        label: "Booked (Upcoming)",
      },
      {
        value: "Pending",
        label: "Pending",
      },
      {
        value: "Failed",
        label: "Failed"
      },
      {
        value: "Past",
        label: "Past",
      }
    ];
  });

  const teeTimeOptionControl = useControl({
    value: "All",
    onChange: (event: SelectChangeEvent<string>) => {
      return event.target.value as string;
    },
  });

  const teeTimes = useSelector((state: RootState) => {
    const orderedTeeTimes = orderBy(state.teeTimes.teeTimes, "created", ["desc"]);

    const now = DateTime.now();
    if (teeTimeOptionControl.value === "All") {
      return orderedTeeTimes;
    } else if (teeTimeOptionControl.value === "Confirmed") {
      return orderedTeeTimes.filter((teeTime) => {
        const teeTimeDay = DateTime.fromISO(teeTime.rangeEndInclusive);
        const isBeforeNow = teeTimeDay >= now;
        return teeTime.status === Status.SUCCESS && isBeforeNow;
      });
    } else if (teeTimeOptionControl.value === "Pending") {
      return orderedTeeTimes.filter((teeTime) => {
        return teeTime.status === Status.PENDING;
      });
    } else if (teeTimeOptionControl.value === "Failed") {
      return orderedTeeTimes.filter((teeTime) => {
        return teeTime.status === Status.ERROR;
      })
    } else {
      return orderedTeeTimes.filter((teeTime) => {
        const teeTimeDay = DateTime.fromISO(teeTime.rangeEndInclusive);
        return teeTimeDay <= now;
      });
    }
  });


  return {
    isLoading: isLoadingUserTeeTimes,
    teeTimes,
    teeTimeOptionControl,
    teeTimeOptions,
  };
}
