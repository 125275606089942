import { SimpleCourse } from "../../../../../../types";

export function getOffsetByPlayer({
    fullCourseMetadata,
    players,
}: {
    fullCourseMetadata?: SimpleCourse,
    players: 1 | 2 | 3 | 4
}) {
    if (fullCourseMetadata) {
        return fullCourseMetadata.offsetByPlayers[players];
    }

    return {
        canSchedule: true,
        daysOffset: 7,
        hoursOffset: 0,
        minutesOffset: 0,
        secondsOffset: 0,
    }
}