import { FullCourseMetadata } from "../../../../types";
import CommonCourseDialog from "../commonCourseDialog";

interface ICreateCourseDialogProps {
  open: boolean;
  onClose: () => void;
  onCreateCourseSuccess: (fullCourseMetadata: FullCourseMetadata) => void;
}

function CreateCourseDialog({
  open,
  onClose,
  onCreateCourseSuccess,
}: ICreateCourseDialogProps) {
  return open ? (
    <CommonCourseDialog
      open={open}
      onClose={onClose}
      title="Create Course"
      buttonText="Create Course"
      fullCourseMetadata={null}
      onUpsertSuccess={onCreateCourseSuccess}
    />
  ) : null;
}

export default CreateCourseDialog;
