import { Outlet, useMatch } from "react-router-dom";
import { useAxiosInterceptor } from "../../hooks/useAxiosInterceptor";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { StripeElementsOptions } from '@stripe/stripe-js';
import FullScreenPage from "../../components/fullScreenPage";

const stripePromise = loadStripe('pk_live_51PXm2GKm6joSDUjBkDjVO31Hg1e00njWZmjL2UF8gYiri19aE7sXNfM5yfmfassyFelcKdFD5IkHJOxACV2pcEaj00n1ePnigt');
export function App() {
  useAxiosInterceptor();
  const isAdmin = useMatch('app/admin/*');
  const options: StripeElementsOptions = {
    mode: 'setup',
    currency: 'usd',
    appearance: {
      theme: "flat",
    },
  };

  return (
    <FullScreenPage sx={{
      overflow: !isAdmin ? "hidden" : "auto",
    }}>
      <Elements stripe={stripePromise} options={options}>
        <Outlet />;
      </Elements>
    </FullScreenPage>
  );
}
