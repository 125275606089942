import { Box } from "@mui/material";
import WrappedButton from "../../../../../../../components/wrappedButton";
import WrappedSelect from "../../../../../../../components/wrappedSelect";
import WrappedTextField from "../../../../../../../components/wrappedTextField";
import { Step } from "../../../../../../../types";
import { useFindElementByTextStep } from "./useFindElementByTextStep";

interface IFindElementsByTextStep {
  onClickCreateStep: (step: Step) => void;
}

function FindElementByTextStep({ onClickCreateStep }: IFindElementsByTextStep) {
  const {
    elementOptions,
    elementOptionsControl,
    onSubmit,
    textControl,
    canSubmitForm,
  } = useFindElementByTextStep(onClickCreateStep);

  return (
    <form onSubmit={onSubmit}>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          flexDirection: "column",
        }}
      >
        <WrappedSelect
          label="Element"
          onChange={elementOptionsControl.onChange}
          value={elementOptionsControl.value}
          options={elementOptions}
          hasError={elementOptionsControl.hasError}
          shownErrorMessage={elementOptionsControl.shownErrorMessage}
        />
        <WrappedTextField
          value={textControl.value}
          label="Text"
          onChange={textControl.onChange}
          error={textControl.hasError}
          helperText={textControl.shownErrorMessage}
        />
        <WrappedButton disabled={!canSubmitForm} type="submit">
          Create Step
        </WrappedButton>
      </Box>
    </form>
  );
}

export default FindElementByTextStep;
