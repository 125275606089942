import { FullCourseMetadata } from "../../../../../../types";
import CommonTeeTimeDialog from "../commonTeeTimeDialog";
import { useScheduleTeeTimeDialog } from "./useScheduleTeeTimeDialog";

interface IScheduleTeeTimeDialogProps {
  onClose: () => void;
  fullCourseMetadata: FullCourseMetadata;
  onSubmitButtonSuccess: () => void;
  allowBookNow?: boolean;
}

function ScheduleTeeTimeDialog(props: IScheduleTeeTimeDialogProps) {
  const { onClickSubmitForm, actionInProgress } = useScheduleTeeTimeDialog(
    props.onSubmitButtonSuccess,
  );

  const title = `Schedule Tee Time For ${props.fullCourseMetadata.name}`;
  return (
    <CommonTeeTimeDialog
      onClickSubmitForm={onClickSubmitForm}
      isFullTestRun={false}
      title={title}
      actionInProgress={actionInProgress}
      actionButtonText="Schedule Tee Time"
      requireUsernameAndPassword
      {...props}
    />
  );
}

export default ScheduleTeeTimeDialog;
