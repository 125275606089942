import { useEffect, useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { Api } from "../../api";
import { AdminRole, ScheduleTeeTimeBase, User } from "../../types";
import { useScheduleTeeTimeDialog } from "../admin/courses/coursesTable/courseActions/scheduleTeeTimeDialog/useScheduleTeeTimeDialog";
import { useDispatch, useSelector } from "react-redux";
import { addTeeTime } from "../../redux/teeTimes";
import { useBreakpoint } from "../../hooks/useBreakpoint";
import { setSimpleCourses } from "../../redux/courses";
import { RootState } from "../../redux/store";
import { setCredentials } from "../../redux/credentials";

export function useUser() {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<null | User>(null);

  const dispatch = useDispatch();
  function onSubmitFormSuccess(teeTime: ScheduleTeeTimeBase) {
    const action = addTeeTime(teeTime);
    dispatch(action);
  }
  const { onClickSubmitForm, actionInProgress } = useScheduleTeeTimeDialog(onSubmitFormSuccess);
  useEffect(() => {
    if (!isLoading) {
      return;
    }

    let didCancel = false;

    Api.user
      .getUser()
      .then((userFromData) => {
        if (didCancel) {
          return;
        }

        setUser(userFromData);
      })
      .catch(() => {
        if (didCancel) {
          return;
        }
      })
      .finally(() => {
        if (didCancel) {
          return;
        }

        setIsLoading(false);
      });

    return () => {
      didCancel = true;
    };
  }, [isLoading]);

  useEffect(() => {
    let didCancel = false;

    Promise.all([
      Api.course.getPublishedCourses(),
      Api.credentials.getCredentialsForUser(),
    ]).then(([
      simpleCourses,
      credentials,
    ]) => {
      if (didCancel) {
        return;
      }

      const setSimpleCoursesAction = setSimpleCourses(simpleCourses.Items);
      dispatch(setSimpleCoursesAction);
      const setCredentialsAction = setCredentials(credentials.Items);
      dispatch(setCredentialsAction);
    }).catch(() => {
      if (didCancel) {
        return;
      }
    })
      .finally(() => {
        if (didCancel) {
          return;
        }
      });


    return () => {
      didCancel = true;
    };
  }, []);

  function goToAdminPortal() {
    navigate("/app/admin/courses");
  }

  function onClickNewTeeTime() {
    navigate("/app/user/book-tee-time")
  }

  function onClickBackToTeeTimeList() {
    navigate("/app/user/tee-times");
  }

  const breakpoints = useBreakpoint();
  const showAdminButton = !breakpoints.max768;

  const isOnTeeTimesPage = useMatch('app/user/tee-times');

  const isLoadingCourses = useSelector((state: RootState) => {
    return state.simpleCourses.isLoading;
  });

  return {
    user,
    isAdmin: user?.adminRole === AdminRole.Super,
    goToAdminPortal,
    onClickNewTeeTime,
    onClickSubmitForm,
    actionInProgress,
    showAdminButton,
    isOnTeeTimesPage,
    onClickBackToTeeTimeList,
    isLoadingCourses,
  };
}
