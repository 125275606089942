import WrappedSnackbar from "../../../../../../components/wrappedSnackbar";
import { FullCourseMetadata, ScheduleTeeTimeBase } from "../../../../../../types";
import CommonTeeTimeDialog from "../commonTeeTimeDialog";
import { useFullTestRunDialog } from "./useFullTestRunDialog";

interface IFullTestRunDialogProps {
  onClose: () => void;
  fullCourseMetadata: FullCourseMetadata;
  scheduleTeeTimeBase?: ScheduleTeeTimeBase;
  didUseDevelopmentTestScript?: boolean;
}

function FullTestRunDialog(props: IFullTestRunDialogProps) {
  const {
    onClickSubmitForm,
    actionInProgress,
    showSnackbar,
    onClickAction,
    hideDialogInternal,
  } = useFullTestRunDialog(
    props.onClose,
  );

  const title = `Run Full Test For ${props.fullCourseMetadata.name}`;
  return (
    <>
      {!hideDialogInternal && (
        <CommonTeeTimeDialog
          onClickSubmitForm={onClickSubmitForm}
          isFullTestRun
          title={title}
          actionInProgress={actionInProgress}
          actionButtonText="Run Full Test"
          requireUsernameAndPassword
          didUseDevelopmentTestScript={props.didUseDevelopmentTestScript}
          {...props}
        />
      )}
      <WrappedSnackbar
        open={showSnackbar}
        onClose={props.onClose}
        message="Success! Click the button to open the full test in a separate tab."
        actionMetadata={{
          onClick: onClickAction,
          text: "Open Full Test",
        }}
      />
    </>
  );
}

export default FullTestRunDialog;
