import { useState } from "react";
import { ScheduleTeeTimeBase } from "../../../../types";

export function useScheduledTeeTimesTable() {
  const [itemToDelete, setItemToDelete] = useState<ScheduleTeeTimeBase | null>(
    null,
  );
  function onClickDeleteInternal(teeTime: ScheduleTeeTimeBase) {
    return () => {
      setItemToDelete(teeTime);
    };
  }
  function onClickCloseDialog() {
    setItemToDelete(null);
  }

  return {
    itemToDelete,
    onClickDeleteInternal,
    onClickCloseDialog,
  };
}
