import { Box, Typography } from "@mui/material";

function DisplayScheduleForeUpTeeTimeStep() {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Typography>Schedule Fore Up tee time</Typography>
    </Box>
  );
}

export default DisplayScheduleForeUpTeeTimeStep;
