import { DateTime } from "luxon";
import { SimpleCourse } from "../../../../../../types";
import { getOffsetByPlayer } from "./getOffsetByPlayer";

/**
 * This function tells us the first moment when tee times
 * become available for a specific course / player combination
 */
export function firstAvailableMoment({
    simpleCourse,
    players,
}: {
    simpleCourse?: SimpleCourse,
    players: 1 | 2 | 3 | 4
}) {
    const { daysOffset, hoursOffset } = getOffsetByPlayer({
        fullCourseMetadata: simpleCourse,
        players,
    });

    return DateTime.now()
        .plus({
            days: daysOffset + 1,
        }).set({
            hour: 0,
            second: 0,
            minute: 0,
            millisecond: 0,
        }).minus({
            hours: hoursOffset ? 24 - hoursOffset : hoursOffset,
        });
}