import { Box, Typography } from "@mui/material";
import WrappedButton from "../../../../../../components/wrappedButton";
import WrappedDialog from "../../../../../../components/wrappedDialog";
import { ScheduleTeeTimeBase } from "../../../../../../types";
import { useDeleteTeeTimeDialog } from "./useDeleteTeeTimeDialog";

interface IDeleteTeeTimeDialogProps {
  onClose: () => void;
  teeTime: ScheduleTeeTimeBase;
  onDeleteSuccess: (PK: string) => void;
}

function DeleteTeeTimeDialog({
  onClose,
  teeTime,
  onDeleteSuccess,
}: IDeleteTeeTimeDialogProps) {
  const { onClickDelete, isDeletingTeeTime } = useDeleteTeeTimeDialog(
    teeTime,
    onDeleteSuccess,
    onClose,
  );

  return (
    <WrappedDialog
      open={true}
      onCloseExplicit={onClose}
      title="Deleted Scheduled Tee Time"
      sx={{
        width: "600px",
        padding: 0,
      }}
      rightFooterContent={
        <WrappedButton
          onClick={onClickDelete}
          disabled={isDeletingTeeTime}
          showSpinner={isDeletingTeeTime}
          color="primary"
          variant="contained"
        >
          Delete
        </WrappedButton>
      }
      leftFooterContent={
        <WrappedButton
          onClick={onClose}
          disabled={isDeletingTeeTime}
          variant="text"
        >
          Cancel
        </WrappedButton>
      }
    >
      <Box
        sx={{
          paddingTop: 1,
          paddingBottom: 1,
        }}
      >
        <Typography>
          Are you sure you want to delete the scheduled tee time? This action is
          permanent and cannot be undone.
        </Typography>
      </Box>
    </WrappedDialog>
  );
}

export default DeleteTeeTimeDialog;
