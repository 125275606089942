import { Box } from "@mui/material";
import WrappedButton from "../../../../../../../components/wrappedButton";
import WrappedTextField from "../../../../../../../components/wrappedTextField";
import { Step } from "../../../../../../../types";
import { useNavigationStep } from "./useNavigationStep";

interface INavigationStep {
  onClickCreateStep: (step: Step) => void;
}

function NavigationStep({ onClickCreateStep }: INavigationStep) {
  const { urlControl, onSubmit } = useNavigationStep(onClickCreateStep);

  return (
    <form onSubmit={onSubmit}>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          flexDirection: "column",
        }}
      >
        <WrappedTextField
          value={urlControl.value}
          label="URL"
          onChange={urlControl.onChange}
          error={urlControl.hasError}
          helperText={urlControl.shownErrorMessage}
        />
        <WrappedButton disabled={!urlControl.isValid} type="submit">
          Create Step
        </WrappedButton>
      </Box>
    </form>
  );
}

export default NavigationStep;
