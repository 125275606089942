import { Box, IconButton, Typography } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { useImageSlider } from "./useImageSlider";
import { DateTime } from "luxon";
import { CenterLoadingSpinner } from "../../../../components/centerLoadingSpinner";

export interface ImageSliderProps {
  folderKey: string;
  headerText: string;
}

function ImageSlider({ folderKey, headerText }: ImageSliderProps) {
  const {
    onClickLeftButton,
    onClickRightButton,
    onClickGoToLastPage,
    onClickGoToFirstPage,
    index,
    signedURLsWithMetadata,
    isLoadingImages,
  } = useImageSlider(folderKey);

  const position = signedURLsWithMetadata.length ? `${index + 1}/${signedURLsWithMetadata.length}` : null;
  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        gap: signedURLsWithMetadata.length === 0 ? 0 : 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
        }}
      >
        <Box
          sx={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Typography variant="h6">
            {headerText} {position}
          </Typography>
        </Box>
        {isLoadingImages ? (
          <Box>
            <CenterLoadingSpinner />
          </Box>
        ) : signedURLsWithMetadata.length > 0 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <IconButton
              aria-label="first"
              onClick={onClickGoToFirstPage}
              disabled={index === 0}
            >
              <KeyboardDoubleArrowLeftIcon />
            </IconButton>
            <IconButton
              aria-label="left"
              onClick={onClickLeftButton}
              disabled={index === 0}
            >
              <KeyboardArrowLeftIcon />
            </IconButton>
            <IconButton
              aria-label="right"
              onClick={onClickRightButton}
              disabled={index === signedURLsWithMetadata.length - 1}
            >
              <KeyboardArrowRightIcon />
            </IconButton>
            <IconButton
              aria-label="last"
              onClick={onClickGoToLastPage}
              disabled={index === signedURLsWithMetadata.length - 1}
            >
              <KeyboardDoubleArrowRightIcon />
            </IconButton>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        {!isLoadingImages && signedURLsWithMetadata.length === 0 && (
          <Typography>No images found in this folder</Typography>
        )}
        {signedURLsWithMetadata.map(
          ({ description, signedURL, lastModified }, compareIndex) => {
            const visibility = index !== compareIndex ? "hidden" : "visible";
            const width = index !== compareIndex ? "0px" : undefined;

            // We can't guarantee the last modified date is present so we default
            // to an empty string if it's not present 
            const displayDate = !lastModified ? "" :
              DateTime.fromISO(lastModified).toLocaleString(DateTime.TIME_WITH_SECONDS);
            const maybeDateWithDescription = !!displayDate ? `${displayDate} - ${description}` : description;
            return (
              <Box
                sx={{
                  display: "inline-flex",
                  flexDirection: "column",
                  gap: 2,
                  visibility,
                  width,
                }}
              >
                <Typography>{maybeDateWithDescription}</Typography>
                <img
                  style={{
                    objectFit: "contain",
                    maxWidth: "800px",
                  }}
                  src={signedURL}
                />
              </Box>
            );
          },
        )}
      </Box>
    </Box>
  );
}

export default ImageSlider;
