import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import WrappedSelect from "../../../../../../components/wrappedSelect";
import { Step, StepType } from "../../../../../../types";
import NavigationStep from "./navigationStep";
import { useCreateStepButton } from "./useCreateStepButton";
import AddIcon from "@mui/icons-material/Add";
import FindElementByTextStep from "./findElementByTextStep";
import FindElementByTextNavigateToAncestorHrefStep from "./findElementByTextNavigateToAncestorHrefStep";
import ClickElementWithTextStep from "./clickElementWithTextStep";
import ScheduleForeUpTeeTimeStep from "./scheduleForeUpTeeTimeStep";
import ScheduleChronoTeeTimeStep from "./scheduleChronoTeeTimeStep";
import ConditionalClickElementWithTextStep from "./conditionalClickElementWithTextStep";

export interface IStepsProps {
  onClickCreateStep: (step: Step) => void;
  text: string;
  useIcon?: boolean;
}

function CreateStepButton({ onClickCreateStep, text, useIcon }: IStepsProps) {
  const {
    showDialog,
    onClickButton,
    onClose,
    stepTypeOptionControl,
    stepTypeOptions,
    wrappedOnClickCreateStep,
  } = useCreateStepButton(onClickCreateStep);

  const button = (function () {
    if (useIcon) {
      return (
        <Tooltip title={text}>
          <IconButton aria-label="add-before" onClick={onClickButton}>
            <AddIcon />
          </IconButton>
        </Tooltip>
      );
    }

    return <Button onClick={onClickButton}>{text}</Button>;
  })();

  return (
    <Box>
      {button}
      <Dialog onClose={onClose} open={showDialog}>
        <Box
          sx={{
            width: "600px",
          }}
        >
          <DialogTitle>Create Step</DialogTitle>
          <Box
            sx={{
              padding: 2,
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <WrappedSelect
              label="Step Type"
              onChange={stepTypeOptionControl.onChange}
              value={stepTypeOptionControl.value}
              options={stepTypeOptions}
              hasError={stepTypeOptionControl.hasError}
              shownErrorMessage={stepTypeOptionControl.shownErrorMessage}
            />
            {stepTypeOptionControl.value === StepType.Route && (
              <NavigationStep onClickCreateStep={wrappedOnClickCreateStep} />
            )}
            {stepTypeOptionControl.value === StepType.FindElementByText && (
              <FindElementByTextStep
                onClickCreateStep={wrappedOnClickCreateStep}
              />
            )}
            {stepTypeOptionControl.value ===
              StepType.FindElementByTextNavigateToAncestorAnchorHref && (
                <FindElementByTextNavigateToAncestorHrefStep
                  onClickCreateStep={wrappedOnClickCreateStep}
                />
              )}
            {stepTypeOptionControl.value === StepType.ClickElementWithText && (
              <ClickElementWithTextStep
                onClickCreateStep={wrappedOnClickCreateStep}
              />
            )}
            {stepTypeOptionControl.value === StepType.ConditionalClickElementWithText && (
              <ConditionalClickElementWithTextStep
                onClickCreateStep={wrappedOnClickCreateStep}
              />
            )}
            {stepTypeOptionControl.value === StepType.ScheduleForeUpTeeTime && (
              <ScheduleForeUpTeeTimeStep
                onClickCreateStep={wrappedOnClickCreateStep}
              />
            )}
            {stepTypeOptionControl.value === StepType.ScheduleChronoUpTeeTime && (
              <ScheduleChronoTeeTimeStep
                onClickCreateStep={wrappedOnClickCreateStep}
              />
            )}
            {stepTypeOptionControl.value === StepType.ScheduleTeeItUpTeeTime && (
              <ScheduleChronoTeeTimeStep
                onClickCreateStep={wrappedOnClickCreateStep}
              />
            )}
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
}

export default CreateStepButton;
