import { Box, Button, LinearProgress, Typography, useTheme } from "@mui/material";
import WrappedButton from "../../../components/wrappedButton";
import { useBackupTeeTimes } from "./useBackupTeeTimes";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";

function BackupTeeTimes() {
    const {
        onClickEditTeeTime,
        activeStep,
        onClickBack,
        onClickNext,
        disableNext,
        activeStepIndex,
        isLastStep,
        hasVerifiedInformation,
        isSubmitting,
        linearProgressValue,
    } = useBackupTeeTimes();
    const theme = useTheme();

    return (
        <Box sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
        }}>
            <Box sx={{
                display: "flex",
                flexGrow: 1,
                flexDirection: "column",
                overflow: "auto",
                padding: 2,
                paddingTop: 3,
            }}>
                <Typography variant="h6" sx={{
                    paddingBottom: 2,
                }}>{activeStep.title}</Typography>
                {activeStep.element}
            </Box>
            <Box sx={{
                flexBasis: "auto",
                flexGrow: 0,
                flexShrink: 0,
                backgroundColor: theme.palette.grey["100"],
                display: "flex",
                flexDirection: "column",
            }}>
                <Box sx={{
                    flexGrow: 1,
                }}>
                    <LinearProgress variant="determinate" value={linearProgressValue} />
                </Box>
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: 1,
                }}>
                    <Box>
                        <Button size="small" onClick={onClickBack} disabled={activeStepIndex === 0 || isSubmitting}>
                            <KeyboardArrowLeft />
                            Back
                        </Button>
                    </Box>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 1,
                    }}>
                        <Button size="small" onClick={onClickNext} disabled={disableNext || isSubmitting}>
                            Next
                            <KeyboardArrowRight />
                        </Button>
                        <WrappedButton onClick={onClickEditTeeTime} variant="contained" disabled={(!isLastStep || !hasVerifiedInformation) || isSubmitting} showSpinner={isSubmitting}>Update</WrappedButton>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default BackupTeeTimes;