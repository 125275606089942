import axios from "axios";
import { environmentVariables } from "../environmentVariables";
import { ScheduleTeeTimeBase, ScheduleTeeTimeCreateRequest, ScheduleTeeTimeEditRequestV2, ScheduleTeeTimeLogs } from "../types";

export interface IScheduledTeeTimeAPI {
  createScheduledTeeTime(
    scheduleTeeTimeCreateRequest: ScheduleTeeTimeCreateRequest,
  ): Promise<ScheduleTeeTimeBase>;

  editScheduledTeeTimeV2(
    scheduleTeeTimeEditRequest: ScheduleTeeTimeEditRequestV2,
  ): Promise<ScheduleTeeTimeBase>;

  getAllScheduledTeeTimes(): Promise<{
    Items: ScheduleTeeTimeBase[];
    Count: number;
  }>;

  deleteScheduleTeeTime(PK: string, SK: string): Promise<void>;

  getTeeTimesForUser(): Promise<{
    Items: ScheduleTeeTimeBase[];
    Count: number;
  }>;

  getScheduledTeeTime(PK: string): Promise<ScheduleTeeTimeBase>;

  getScheduledTeeTimeLogs(PK: string): Promise<ScheduleTeeTimeLogs | null>;
}

export class ScheduledTeeTimeAPI implements IScheduledTeeTimeAPI {
  public async createScheduledTeeTime(
    scheduleTeeTimeCreateRequest: ScheduleTeeTimeCreateRequest,
  ) {
    const axiosResponse = await axios.post<ScheduleTeeTimeBase>(
      `${environmentVariables.baseAuthenticatedApiUrl}/scheduled-tee-time`,
      scheduleTeeTimeCreateRequest,
    );

    return axiosResponse.data;
  }

  public async editScheduledTeeTimeV2(
    scheduleTeeTimeEditRequest: ScheduleTeeTimeEditRequestV2,
  ) {
    const axiosResponse = await axios.post<ScheduleTeeTimeBase>(
      `${environmentVariables.baseAuthenticatedApiUrl}/edit-scheduled-tee-time-v2`,
      scheduleTeeTimeEditRequest,
    );

    return axiosResponse.data;
  }

  public async getAllScheduledTeeTimes() {
    const axiosResponse = await axios.get<{
      Items: ScheduleTeeTimeBase[];
      Count: number;
    }>(`${environmentVariables.baseAuthenticatedApiUrl}/scheduled-tee-times`);

    return axiosResponse.data;
  }

  public async deleteScheduleTeeTime(PK: string, SK: string): Promise<void> {
    await axios.delete(
      `${environmentVariables.baseAuthenticatedApiUrl}/scheduled-tee-time`,
      {
        params: {
          PK,
          SK,
        },
      },
    );
  }

  public async getTeeTimesForUser() {
    const axiosResponse = await axios.get<{
      Items: ScheduleTeeTimeBase[];
      Count: number;
    }>(`${environmentVariables.baseAuthenticatedApiUrl}/user-tee-times`);

    return axiosResponse.data;
  }

  public async getScheduledTeeTime(PK: string) {
    const axiosResponse = await axios.get<ScheduleTeeTimeBase>(
      `${environmentVariables.baseAuthenticatedApiUrl}/scheduled-tee-time`,
      {
        params: {
          PK,
        },
      },
    );

    return axiosResponse.data;
  }

  public async getScheduledTeeTimeLogs(PK: string) {
    const axiosResponse = await axios.get<ScheduleTeeTimeLogs | null>(
      `${environmentVariables.baseAuthenticatedApiUrl}/scheduled-tee-time-logs`,
      {
        params: {
          PK,
        },
      },
    );

    return axiosResponse.data;
  }
}
