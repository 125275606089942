import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SimpleCourse } from '../types';
import { orderBy } from "lodash";

export interface SimpleCoursesState {
    courses: SimpleCourse[],
    isLoading: boolean,
}

const initialState: SimpleCoursesState = {
    courses: [],
    isLoading: true,
}

export const simpleCoursesSlice = createSlice({
    name: 'simpleCourses',
    initialState,
    reducers: {
        setSimpleCourses: (state, action: PayloadAction<SimpleCourse[]>) => {
            state.courses = orderBy(action.payload, "name");
            state.isLoading = false;
        },
    },
})

// Action creators are generated for each case reducer function
export const { setSimpleCourses } = simpleCoursesSlice.actions

export default simpleCoursesSlice.reducer;