import { useTheme } from "@mui/material";
import { DateTime } from "luxon";
import { useState } from "react";
import { ScheduleTeeTimeBase, Status } from "../../../../types";
import { runTimeFromTeeTime } from "../../../../utils/runTimeFromTeeTime";
import { useDispatch } from 'react-redux'
import { deleteTeeTime } from "../../../../redux/teeTimes";
import { useNavigate } from "react-router-dom";

export function useUserTeeTime(
    teeTime: ScheduleTeeTimeBase,
) {
    const theme = useTheme();
    const teeTimeDay = DateTime.fromISO(
        teeTime.rangeStartInclusive,
    ).toFormat("LLLL d");
    const isSuccess = teeTime.status === Status.SUCCESS;

    const bookingTimeDisplay = teeTime.bookedTime && isSuccess ? ` at ${DateTime.fromISO(teeTime.bookedTime).setZone(teeTime.timezone).toLocaleString(DateTime.TIME_SIMPLE)}` : "";
    const cardTitle = `${teeTimeDay}${bookingTimeDisplay}`;
    const runTime = !!teeTime.runNow
        ? DateTime.fromISO(teeTime.created).toLocaleString(DateTime.DATETIME_FULL)
        : runTimeFromTeeTime(teeTime).toLocaleString(DateTime.DATETIME_FULL);

    const text = (function () {
        if (teeTime.status === Status.PENDING) {
            if (!!teeTime.runNow) {
                return `${teeTime.courseName} is scheduled to immediately run because the target day is already available.`
            } else {
                return `This tee time is scheduled to automatically book on ${runTime} when ${teeTime.courseName} makes it available. ${!!teeTime.backupTeeTimes ? `We will first attempt to book a tee time at ${teeTime.courseName}.` : ""}`;
            }
        } else if (teeTime.status === Status.ERROR) {
            return "There was an error when attempting to book this tee time.";
        } else if (teeTime.status === Status.SUCCESS) {
            return "Please note that any cancellations should be handled directly with the course and will not reflect in Green Fee Pro."
        }

        return "";
    })();

    const borderColor = (function () {
        if (teeTime.status === Status.PENDING) {
            return theme.palette.warning.main;
        } else if (teeTime.status === Status.SUCCESS) {
            return theme.palette.success.main;
        } else {
            return theme.palette.error.main;
        }
    })();
    const border = `5px solid ${borderColor}`;

    const [isShowingDeleteModal, setIsShowingDeleteModal] = useState(false);
    function onClickDeleteModal() {
        setIsShowingDeleteModal(true);
    }
    function onHideDeleteModal() {
        setIsShowingDeleteModal(false);
    }

    const dispatch = useDispatch();
    function onDeleteSuccess() {
        const action = deleteTeeTime(teeTime.PK);
        dispatch(action);
    }

    const [expanded, setExpanded] = useState(false);
    function toggleExpanded() {
        setExpanded((previous) => !previous);
    }

    const chipText = (function () {
        if (teeTime.status === Status.SUCCESS) {
            return "Booked";
        } else if (teeTime.status === Status.ERROR) {
            return "Error";
        } else if (teeTime.status === Status.PENDING) {
            return "Pending";
        }
    })();

    const navigate = useNavigate();
    function onClickEditPrimary() {
        const teeTimeIDOnly = teeTime.PK.replace("SCHEDULED_TEE_TIME#", "");
        navigate(`/app/user/book-tee-time?teeTime=${teeTimeIDOnly}`);
    }

    const backup = (function () {
        const internalBackups = teeTime.backupTeeTimes
        if (!internalBackups) {
            return undefined;
        }

        if (internalBackups[0]) {
            return internalBackups[0];
        }
    })();
    function onClickEditBackup() {
        const teeTimeIDOnly = teeTime.PK.replace("SCHEDULED_TEE_TIME#", "");
        const url = `/app/user/backup-tee-times?teeTime=${teeTimeIDOnly}`;
        if (!!backup) {
            navigate(`${url}&initialActiveStepKey=select-course`)
        } else {
            navigate(url);
        }
    }

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    function onClickEdit(event: React.MouseEvent<HTMLButtonElement>) {
        setAnchorEl(event.currentTarget);
    }
    function onClosePrimaryOrBackupMenu() {
        setAnchorEl(null);
    }

    const orText = (function () {
        if (!teeTime.backupTeeTimes?.length) {
            return "";
        }

        return ` or ${teeTime.backupTeeTimes[0].courseName}`;
    })();
    const fullCourseText = `${teeTime.courseName}${orText}`;
    const backupCourseName = backup?.courseName;

    return {
        text,
        border,
        isShowingDeleteModal,
        onClickDeleteModal,
        onHideDeleteModal,
        onDeleteSuccess,
        expanded,
        toggleExpanded,
        chipText,
        cardTitle,
        onClickEdit,
        onClickEditBackup,
        onClickEditPrimary,
        showPrimaryOrBackupMenu: !!anchorEl,
        anchorEl,
        onClosePrimaryOrBackupMenu,
        fullCourseText,
        backupCourseName,
    }
}