import { FullCourseMetadata } from "../../../../types";
import CommonCourseDialog from "../commonCourseDialog";

interface IEditCourseDialog {
  open: boolean;
  onClose: () => void;
  fullCourseMetadata: FullCourseMetadata | null;
  onUpdateCourseSuccess: (fullCourseMetadata: FullCourseMetadata) => void;
}

function EditCourseDialog({
  open,
  onClose,
  fullCourseMetadata,
  onUpdateCourseSuccess,
}: IEditCourseDialog) {
  return open ? (
    <CommonCourseDialog
      open={open}
      onClose={onClose}
      title="Update Course"
      buttonText="Update Course"
      fullCourseMetadata={fullCourseMetadata}
      onUpsertSuccess={onUpdateCourseSuccess}
    />
  ) : null;
}

export default EditCourseDialog;
