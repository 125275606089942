import {
  CourseSchedulingOffset,
  OffsetByPlayers,
} from "../../../../../../types";

export function useCustomSchedulingOffset(
  schedulingOffset: OffsetByPlayers,
  onChangeSchedulingOffset: (offsetByPlayers: OffsetByPlayers) => void,
) {
  const {
    1: playerOneSchedulingOffset,
    2: playerTwoSchedulingOffset,
    3: playerThreeSchedulingOffset,
    4: playerFourSchedulingOffset,
  } = schedulingOffset;

  function onChangeCourseSchedulingOffset(playerNumber: 1 | 2 | 3 | 4) {
    return (courseSchedulingOffset: CourseSchedulingOffset) => {
      onChangeSchedulingOffset({
        ...schedulingOffset,
        [playerNumber]: courseSchedulingOffset,
      });
    };
  }

  const onChangePlayerOne = onChangeCourseSchedulingOffset(1);
  const onChangePlayerTwo = onChangeCourseSchedulingOffset(2);
  const onChangePlayerThree = onChangeCourseSchedulingOffset(3);
  const onChangePlayerFour = onChangeCourseSchedulingOffset(4);

  return {
    playerOneSchedulingOffset,
    playerTwoSchedulingOffset,
    playerThreeSchedulingOffset,
    playerFourSchedulingOffset,
    onChangePlayerOne,
    onChangePlayerTwo,
    onChangePlayerThree,
    onChangePlayerFour,
  };
}
