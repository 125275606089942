import { SelectChangeEvent } from "@mui/material";
import { ChangeEvent, useEffect } from "react";
import { StringValidator } from "../../../../classes/stringValidator";
import { useControl } from "../../../../hooks/useControl";
import {
  ChronoMetadata,
  CourseSchedulingOffset,
  CourseStatus,
  FullCourseMetadata,
  OffsetByPlayers,
  State,
  Step,
  TeeTimeHoleOption,
  TeeTimeSchedulingSoftware,
  Timezone,
  UpsertCourseMetadata,
} from "../../../../types";
import { useState } from "react";
import { states } from "./states";
import { allControlsAreValid } from "../../../../utils/allControlsAreValid";
import { cloneDeep } from "lodash";
import { resetAllControls } from "../../../../utils/resetAllControls";
import { Api } from "../../../../api";

export function useCommonCourseDialog(
  open: boolean,
  onClose: () => void,
  fullCourseMetadata: FullCourseMetadata | null,
  onUpsertSuccess: (fullCourseMetadata: FullCourseMetadata) => void,
) {
  const nameControl = useControl({
    value: fullCourseMetadata?.name || "",
    onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      return event.target.value;
    },
    validatorError: (name: string) => {
      return new StringValidator().required("").validate(name);
    },
  });

  const cityControl = useControl({
    value: fullCourseMetadata?.city || "",
    onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      return event.target.value;
    },
    validatorError: (city: string) => {
      return new StringValidator().required("").validate(city);
    },
  });

  const [stateOptions] = useState(() => {
    return states.map((state) => {
      return {
        value: state,
        label: state,
      };
    });
  });
  const stateControl = useControl({
    value: fullCourseMetadata?.state || "",
    onChange: (event: SelectChangeEvent<null | string>) => {
      return event.target.value;
    },
    validatorError: (city: null | string) => {
      return new StringValidator().required("").validate(city || "");
    },
  });

  const [courseStatusOptions] = useState(() => {
    return [
      {
        value: CourseStatus.Draft,
        label: "Draft",
      },
      {
        value: CourseStatus.Published,
        label: "Published",
      },
      {
        value: CourseStatus.ReadyToPublish,
        label: "Ready To Publish",
      },
      {
        value: CourseStatus.Archived,
        label: "Archived",
      }
    ];
  });
  const courseStatusControl = useControl({
    value: fullCourseMetadata?.status || CourseStatus.Draft,
    onChange: (event: SelectChangeEvent<CourseStatus>) => {
      return event.target.value as CourseStatus;
    },
  });

  const [teeTimeSoftwareOptions] = useState(() => {
    return [
      {
        value: TeeTimeSchedulingSoftware.ForeUp,
        label: "Fore Up",
      },
      {
        value: TeeTimeSchedulingSoftware.Chrono,
        label: "Chrono",
      },
      {
        value: TeeTimeSchedulingSoftware.TeeItUp,
        label: "Tee It Up",
      }
    ];
  });
  const teeTimeSchedulingSoftwareControl = useControl({
    value: fullCourseMetadata?.teeTimeSchedulingSoftware || null,
    onChange: (event: SelectChangeEvent<null | TeeTimeSchedulingSoftware>) => {
      return event.target.value as null | TeeTimeSchedulingSoftware;
    },
    validatorError: (
      teeTimeSchedulingSoftware: null | TeeTimeSchedulingSoftware,
    ) => {
      if (teeTimeSchedulingSoftware === null) {
        return "This is a required field";
      }

      return "";
    },
  });

  const [teeTimeHoleOptions] = useState(() => {
    return [
      {
        value: TeeTimeHoleOption.Nine,
        label: "9",
      },
      {
        value: TeeTimeHoleOption.Eighteen,
        label: "18",
      },
      {
        value: TeeTimeHoleOption.Both,
        label: "Both",
      },
    ];
  });
  const teeTimeHoleControl = useControl({
    value: fullCourseMetadata?.teeTimeHoleOption || null,
    onChange: (event: SelectChangeEvent<null | TeeTimeHoleOption>) => {
      return event.target.value as null | TeeTimeHoleOption;
    },
    validatorError: (teeTimeHoleOption: null | TeeTimeHoleOption) => {
      if (teeTimeHoleOption === null) {
        return "This is a required field";
      }

      return "";
    },
  });

  const [timezoneOptions] = useState(() => {
    return [
      {
        value: "America/Los_Angeles",
        label: "Pacific",
      },
      {
        value: "America/Denver",
        label: "Mountain",
      },
      {
        value: "America/Phoenix",
        label: "Arizona",
      },
      {
        value: "America/Chicago",
        label: "Central",
      },
      {
        value: "America/New_York",
        label: "Eastern",
      },
    ];
  });
  const timezoneControl = useControl({
    value: fullCourseMetadata?.timezone || null,
    onChange: (event: SelectChangeEvent<null | string>) => {
      return event.target.value as null | string;
    },
    validatorError: (courseTransitOption: null | string) => {
      if (courseTransitOption === null) {
        return "This is a required field";
      }

      return "";
    },
  });

  const [steps, setSteps] = useState<Step[]>(() => {
    if (!fullCourseMetadata) {
      return [];
    }

    return fullCourseMetadata.steps;
  });
  const [schedulingOffset, setSchedulingOffset] = useState<OffsetByPlayers>(
    () => {
      if (!fullCourseMetadata) {
        return defaultSchedulingOffset();
      }

      return fullCourseMetadata.offsetByPlayers;
    },
  );

  const courseIDControl = useControl({
    value: fullCourseMetadata?.courseID || "",
    onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      return event.target.value as string;
    },
    validatorError: (courseID: string) => {

      return new StringValidator().required().integer().validate(courseID);
    },
  });


  const canSubmitForm = allControlsAreValid(
    nameControl,
    cityControl,
    stateControl,
    teeTimeSchedulingSoftwareControl,
    timezoneControl,
    teeTimeHoleControl,
    courseIDControl,
  );

  function onChangeSchedulingOffset(offsetByPlayers: OffsetByPlayers) {
    setSchedulingOffset(offsetByPlayers);
  }

  const [isUpsertingCourse, setIsUpsertingCourse] = useState(false);
  const onClickActionButton: React.MouseEventHandler<HTMLButtonElement> = (
    event,
  ) => {
    event.preventDefault();
    setIsUpsertingCourse(true);
  };


  useEffect(() => {
    if (!isUpsertingCourse) {
      return;
    }

    const teeTimeSchedulingSoftware = teeTimeSchedulingSoftwareControl.value as TeeTimeSchedulingSoftware;
    const chronoMetadata = (function () {
      if (teeTimeSchedulingSoftware !== TeeTimeSchedulingSoftware.Chrono) {
        return undefined;
      }

      const courseDropdownOptions = chronoCourseOptions.filter((chrono) => !!chrono.trim());
      if (!!courseDropdownOptions.length) {
        const metadata: ChronoMetadata = {
          courseDropdownOptions,
        }
        return metadata;
      }

      return undefined;
    })();
    const upsertCourseMetadata: UpsertCourseMetadata = {
      PK: fullCourseMetadata?.PK,
      SK: fullCourseMetadata?.SK,
      name: nameControl.value,
      city: cityControl.value,
      steps,
      status: courseStatusControl.value,
      offsetByPlayers: schedulingOffset,
      state: stateControl.value as State,
      timezone: timezoneControl.value as Timezone,
      teeTimeSchedulingSoftware:
        teeTimeSchedulingSoftwareControl.value as TeeTimeSchedulingSoftware,
      teeTimeHoleOption: teeTimeHoleControl.value as TeeTimeHoleOption,
      chronoMetadata,
      courseID: courseIDControl.value,
    };

    let didCancel = false;

    Api.course
      .upsertCourse(upsertCourseMetadata)
      .then(function (fullCourseMetadata) {
        if (didCancel) {
          return;
        }

        onUpsertSuccess(fullCourseMetadata);
        onClose();
      })
      .catch(function (error) {
        if (didCancel) {
          return;
        }
        // handle error
        console.error(error);
      })
      .finally(function () {
        if (didCancel) {
          return;
        }

        setIsUpsertingCourse(false);
      });

    return () => {
      didCancel = true;
    };
  }, [isUpsertingCourse]);

  useEffect(() => {
    if (open) {
      return;
    }

    resetAllControls(
      nameControl,
      cityControl,
      stateControl,
      teeTimeSchedulingSoftwareControl,
      timezoneControl,
    );

    setSteps([]);
    setSchedulingOffset(defaultSchedulingOffset());
  }, [open]);

  const [chronoCourseOptions, setChronoCourseOptions] = useState<string[]>(() => {
    return fullCourseMetadata?.chronoMetadata?.courseDropdownOptions || [];
  });
  const onUpdateChronoCourseOptions = (index: number) => {
    return (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setChronoCourseOptions((previous) => {
        return previous.map((str, compareIndex) => {
          if (index === compareIndex) {
            return event.target.value;
          }
          return str;
        })
      })
      return event.target.value;
    };
  }
  const onRemoveChronoCourseOption = (index: number) => {
    return () => {
      setChronoCourseOptions((previous) => {
        return previous.filter((_, compareIndex) => index !== compareIndex);
      })
    }
  }
  const onAddChronoCourseOption = () => {
    setChronoCourseOptions((previous) => {
      const updated = [...previous, ""];
      return updated;
    });
  }

  return {
    courseIDControl,
    nameControl,
    cityControl,
    stateControl,
    teeTimeSchedulingSoftwareControl,
    canSubmitForm,
    onClickActionButton,
    stateOptions,
    teeTimeSoftwareOptions,
    timezoneControl,
    timezoneOptions,
    steps,
    setSteps,
    schedulingOffset,
    onChangeSchedulingOffset,
    isUpsertingCourse,
    teeTimeHoleControl,
    teeTimeHoleOptions,
    courseStatusOptions,
    courseStatusControl,
    chronoCourseOptions,
    onUpdateChronoCourseOptions,
    onRemoveChronoCourseOption,
    onAddChronoCourseOption,
  };
}

function defaultSchedulingOffset() {
  const defaultSchedulingOffset: CourseSchedulingOffset = {
    canSchedule: true,
    daysOffset: 7,
    hoursOffset: 0,
    minutesOffset: 0,
    secondsOffset: 0,
  };

  return {
    1: cloneDeep(defaultSchedulingOffset),
    2: cloneDeep(defaultSchedulingOffset),
    3: cloneDeep(defaultSchedulingOffset),
    4: cloneDeep(defaultSchedulingOffset),
  };
}
