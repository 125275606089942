import { Box } from "@mui/material";
import ScheduledTeeTimesTable from "./scheduledTeeTimesTable";
import { useAllScheduledTeeTimes } from "./useAllScheduledTeeTimes";

function AllScheduleTeeTimes() {
  const { isLoading, items, onDeleteSuccess } = useAllScheduledTeeTimes();

  return (
    <Box
      sx={{
        padding: 2,
      }}
    >
      <ScheduledTeeTimesTable
        items={items}
        isLoading={isLoading}
        onDeleteSuccess={onDeleteSuccess}
      />
    </Box>
  );
}

export default AllScheduleTeeTimes;
