import { orderBy } from "lodash";
import { useEffect, useState } from "react";
import { Api } from "../../api";
import { SimpleCourse } from "../../types";

export function useHome() {
    const [isLoadingCourses, setIsLoadingCourses] = useState(true);
    const [publishedCourses, setPublishedCourses] = useState<SimpleCourse[]>([]);
    useEffect(() => {
        if (!isLoadingCourses) {
            return;
        }

        let didCancel = false;

        Api.course.getPublishedCourses().then((response) => {
            if (didCancel) {
                return;
            }

            const orderedCourses = orderBy(response.Items, "name");
            setPublishedCourses(orderedCourses);
        }).finally(() => {
            if (didCancel) {
                return;
            }

            setIsLoadingCourses(false);
        })

        return () => {
            didCancel = true;
        }
    }, [isLoadingCourses]);
    const greenFeeProEmail = "info@greenfeepro.com";
    const mailTo = `mailto:${greenFeeProEmail}`;

    return {
        isLoadingCourses,
        publishedCourses,
        greenFeeProEmail,
        mailTo,
    }
}