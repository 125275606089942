import { SelectChangeEvent } from "@mui/material";
import { useState } from "react";
import { useControl } from "../../../../../../hooks/useControl";
import { Step, StepType } from "../../../../../../types";

export function useCreateStepButton(onClickCreateStep: (step: Step) => void) {
  const [showDialog, setShowDialog] = useState(false);

  function onClickButton() {
    setShowDialog(true);
  }

  function onClose() {
    setShowDialog(false);
  }

  const [stepTypeOptions] = useState(() => {
    return [
      {
        value: StepType.Route,
        label: "Navigate",
      },
      {
        value: StepType.FindElementByText,
        label: "Find Element By Text",
      },
      {
        value: StepType.ClickElementWithText,
        label: "Click Element With Text",
      },
      {
        value: StepType.ConditionalClickElementWithText,
        label: "Conditional Click Element With Text",
      },
      {
        value: StepType.FindElementByTextNavigateToAncestorAnchorHref,
        label: "Find Element By Text - Navigate To Ancestor HREF",
      },
      {
        value: StepType.ScheduleForeUpTeeTime,
        label: "Schedule Fore Up Tee Time",
      },
      {
        value: StepType.ScheduleChronoUpTeeTime,
        label: "Schedule Chrono Tee Time",
      },
      {
        value: StepType.ScheduleTeeItUpTeeTime,
        label: "Schedule Tee It Up Tee Time",
      }
    ];
  });
  const stepTypeOptionControl = useControl({
    value: null,
    onChange: (event: SelectChangeEvent<null | StepType>) => {
      return event.target.value as null | StepType;
    },
    validatorError: (stepType: null | StepType) => {
      if (stepType === null) {
        return "This is a required field";
      }

      return "";
    },
  });

  function wrappedOnClickCreateStep(step: Step) {
    onClose();
    onClickCreateStep(step);
  }

  return {
    showDialog,
    setShowDialog,
    onClickButton,
    onClose,
    stepTypeOptions,
    stepTypeOptionControl,
    wrappedOnClickCreateStep,
  };
}
