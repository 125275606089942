import { Box, Link, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CenterLoadingSpinner } from "../../components/centerLoadingSpinner";
import { LandingPageCommonSection } from "../../components/landingPageCommonSection";
import { UnauthenticatedAppContainer } from "../../components/unauthenticatedAppContainer";
import { IWrappedButtonProps } from "../../components/wrappedButton";
import { useBreakpoint } from "../../hooks/useBreakpoint";
import CourseSelector from "../user/courseSelector";
import { PlayingGolf } from "./landingPageSvgs/playingGolf";
import { Relaxing } from "./landingPageSvgs/relaxing";
import { StripePayment } from "./landingPageSvgs/stripePayment";
import { useHome } from "./useHome";

export function Home() {
    const navigate = useNavigate();
    const {
        publishedCourses,
        isLoadingCourses,
        greenFeeProEmail,
        mailTo,
    } = useHome();
    const wrappedButtonProps: IWrappedButtonProps[] = [
        {
            onClick: () => {
                navigate("/sign-up");
            },
            children: "Sign Up",
            color: "primary",
            variant: "outlined",
        },
    ];

    const breakpoints = useBreakpoint();
    const size = breakpoints.max768 ? 200 : 300;
    const initialContactNode = (
        <Typography component="span">
            <Link href={mailTo} underline="none"><strong>Contact our support team</strong></Link>
        </Typography>
    )

    return (
        <UnauthenticatedAppContainer>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
            }}>
                <LandingPageCommonSection
                    title={"Be the hero for your group."}
                    textSections={[
                        <Typography>
                            Tired of missing out on the best tee times? Green Fee Pro uses automated booking software to snag the best tee times right when they become available. We even offer a free backup tee time in the event your primary course is unavailable.
                        </Typography>
                    ]}
                    placeContent="right"
                    wrappedButtonProps={wrappedButtonProps}
                >
                    <PlayingGolf size={size} />
                </LandingPageCommonSection>
                <LandingPageCommonSection
                    title={"Prebook a foursome for less than a sleeve of Pro V1s."}
                    textSections={[
                        <Typography>
                            We charge a $2 per person convenience fee only after your desired tee time successfully books. All payments are securely handled using Stripe.
                        </Typography>
                    ]}
                    placeContent="left"
                    wrappedButtonProps={wrappedButtonProps}
                >
                    <StripePayment size={size} />
                </LandingPageCommonSection>
                <LandingPageCommonSection
                    title={"What courses are currently available?"}
                    textSections={[
                        <Typography>
                            Scroll through the list to see what courses are currently available. New courses are added every week. Don't see the course you want? {initialContactNode} and we'll get it added.
                        </Typography>
                    ]}
                    placeContent="right"
                    wrappedButtonProps={wrappedButtonProps}
                >
                    <Box sx={{
                        width: "100%",
                    }}>
                        {isLoadingCourses ? (
                            <CenterLoadingSpinner />
                        ) : (
                            <Box sx={{
                                height: "300px",
                            }}>
                                <CourseSelector courses={publishedCourses} showFiltering />
                            </Box>
                        )}
                    </Box>
                </LandingPageCommonSection>
                <Box sx={{
                    marginBottom: 3,
                }}>
                    <LandingPageCommonSection
                        title={"Get some sleep - we're on it."}
                        textSections={[
                            <Typography>
                                Most tee times don't become available until midnight. You can rest easy knowing Green Fee Pro will help you land the perfect tee time. We'll send you a confirmation email once we've successfully booked your tee time.
                            </Typography>
                        ]}
                        placeContent="left"
                        wrappedButtonProps={wrappedButtonProps}
                    >
                        <Relaxing size={size} />
                    </LandingPageCommonSection>
                </Box>
            </Box>
        </UnauthenticatedAppContainer>
    );
}