import { SelectChangeEvent } from "@mui/material";
import { ChangeEvent, useState } from "react";
import { StringValidator } from "../../../../../../../classes/stringValidator";
import { useControl } from "../../../../../../../hooks/useControl";
import {
  FindElementByTextNavigateToAncestorAnchorHrefStep,
  Step,
  StepType,
} from "../../../../../../../types";
import { allControlsAreValid } from "../../../../../../../utils/allControlsAreValid";

export function useFindElementByTextNavigateToAncestorHrefStep(
  onClickCreateStep: (step: Step) => void,
) {
  const [elementOptions] = useState(() => {
    return [
      {
        value: "span",
        label: "span",
      },
    ];
  });
  const elementOptionsControl = useControl({
    value: null,
    onChange: (event: SelectChangeEvent<null | "span">) => {
      return event.target.value as null | "span";
    },
    validatorError: (element: null | "span") => {
      if (element === null) {
        return "This is a required field";
      }

      return "";
    },
  });

  const textControl = useControl({
    value: "",
    onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      return event.target.value;
    },
    validatorError: (city: string) => {
      return new StringValidator()
        .required("This field is required")
        .validate(city);
    },
  });

  function onClickCreateStepInternal() {
    const navigationStep: FindElementByTextNavigateToAncestorAnchorHrefStep = {
      type: StepType.FindElementByTextNavigateToAncestorAnchorHref,
      metadata: {
        element: elementOptionsControl.value as "span",
        text: textControl.value,
      },
    };

    onClickCreateStep(navigationStep);
  }

  const canSubmitForm = allControlsAreValid(elementOptionsControl, textControl);
  const onSubmit = (function () {
    if (!canSubmitForm) {
      return undefined;
    }

    const onSubmitInternal: React.FormEventHandler<HTMLFormElement> = (
      event,
    ) => {
      event.preventDefault();
      onClickCreateStepInternal();
    };

    return onSubmitInternal;
  })();

  return {
    elementOptions,
    elementOptionsControl,
    textControl,
    onSubmit,
    canSubmitForm,
    onClickCreateStepInternal,
  };
}
