import { Box, SxProps, Theme } from "@mui/material";

interface FullScreenPageProps {
    children: React.ReactNode;
    sx?: SxProps<Theme>;
}

function FullScreenPage({ children, sx }: FullScreenPageProps) {
    return (
        <Box sx={{
            width: "100vw",
            height: "100dvh",
            ...sx,
        }}>
            {children}
        </Box>
    );
}

export default FullScreenPage;