import { Box, Typography } from "@mui/material";
import { useState, useCallback, ReactNode } from "react";
import WrappedTable, {
  IWrappedHeaderActionButton,
  IWrappedTableColumn,
} from "../../../../components/wrappedTable";
import { CourseStatus, FullCourseMetadata } from "../../../../types";
import CourseActions from "./courseActions";

interface ICoursesTableProps {
  onClickEditCourse: (
    fullCourseMetadataToEdit: FullCourseMetadata,
  ) => () => void;
  items: FullCourseMetadata[];
  headerActionButton: IWrappedHeaderActionButton;
  isLoadingCourses: boolean;
  reactNodeInlineWithActionButton: ReactNode;
}

function CoursesTable({
  onClickEditCourse,
  items,
  headerActionButton,
  isLoadingCourses,
  reactNodeInlineWithActionButton,
}: ICoursesTableProps) {
  const [columns] = useState<IWrappedTableColumn<FullCourseMetadata>[]>(() => {
    return [
      {
        text: "Name",
        key: "name",
        createContent: (item: FullCourseMetadata) => {
          return (
            <Box sx={{
              display: "flex",
              flexDirection: "column",
            }}>
              <Typography><strong>{item.name}</strong></Typography>
              <Typography variant="caption">{item.city}, {item.state}</Typography>
            </Box>
          )
        },
      },
      {
        text: "Course ID",
        key: "course_id",
        createContent: (item: FullCourseMetadata) => {
          return <Typography>{item.courseID}</Typography>
        }
      },
      {
        text: "Scheduling Software",
        key: "software",
        createContent: (item: FullCourseMetadata) => {
          return <Typography>{item.teeTimeSchedulingSoftware}</Typography>;
        },
      },
      {
        text: "Status",
        key: "status",
        createContent: (item: FullCourseMetadata) => {
          const statusText = (function () {
            if (item.status === CourseStatus.ReadyToPublish) {
              return "Ready To Publish";
            }
            return item.status;
          })();
          return <Typography>{statusText}</Typography>;
        },
      },
      {
        text: "Actions",
        key: "actions",
        createContent: (item: FullCourseMetadata) => {
          const onClickEdit = onClickEditCourse(item);
          return (
            <CourseActions
              onClickEdit={onClickEdit}
              fullCourseMetadata={item}
            />
          );
        },
      },
    ];
  });

  const createDataKey = useCallback((item: FullCourseMetadata) => {
    return item.PK;
  }, []);

  return (
    <WrappedTable
      title="Courses"
      headerActionButton={headerActionButton}
      items={items}
      columns={columns}
      createDataKey={createDataKey}
      isLoading={isLoadingCourses}
      noItemsText="No courses match the filter criteria"
      reactNodeInlineWithActionButton={reactNodeInlineWithActionButton}
    />
  );
}

export default CoursesTable;
