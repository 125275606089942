import { Box } from "@mui/material";
import FullTestRunsTable from "./fullTestRunsTable";
import { useFullTests } from "./useFullTests";

function FullTests() {
  const { items, isLoadingTests } = useFullTests();

  return (
    <Box
      sx={{
        padding: 2,
      }}
    >
      <FullTestRunsTable items={items} isLoading={isLoadingTests} />
    </Box>
  );
}

export default FullTests;
