import { Box } from "@mui/material";
import { useCommonCourseDialog } from "./useCommonCourseDialog";
import WrappedSelect from "../../../../components/wrappedSelect";
import Steps from "./steps";
import WrappedButton from "../../../../components/wrappedButton";
import WrappedTextField from "../../../../components/wrappedTextField";
import SchedulingOffset from "./schedulingOffset";
import WrappedDialog from "../../../../components/wrappedDialog";
import { FullCourseMetadata, TeeTimeSchedulingSoftware } from "../../../../types";
import ChronoCourseOptions from "./chronoCourseOptions";

interface ICommonCourseDialog {
  open: boolean;
  onClose: () => void;
  title: string;
  buttonText: string;
  fullCourseMetadata: FullCourseMetadata | null;
  onUpsertSuccess: (fullCourseMetadata: FullCourseMetadata) => void;
}

function CommonCourseDialog({
  open,
  onClose,
  title,
  buttonText,
  fullCourseMetadata,
  onUpsertSuccess,
}: ICommonCourseDialog) {
  const {
    nameControl,
    cityControl,
    stateControl,
    stateOptions,
    canSubmitForm,
    onClickActionButton,
    teeTimeSchedulingSoftwareControl,
    teeTimeSoftwareOptions,
    timezoneControl,
    timezoneOptions,
    steps,
    setSteps,
    schedulingOffset,
    onChangeSchedulingOffset,
    isUpsertingCourse,
    teeTimeHoleControl,
    teeTimeHoleOptions,
    courseStatusControl,
    courseStatusOptions,
    chronoCourseOptions,
    onUpdateChronoCourseOptions,
    onRemoveChronoCourseOption,
    onAddChronoCourseOption,
    courseIDControl,
  } = useCommonCourseDialog(open, onClose, fullCourseMetadata, onUpsertSuccess);

  return (
    <WrappedDialog
      open={open}
      onCloseExplicit={onClose}
      title={title}
      sx={{
        width: "800px",
        padding: 0,
      }}
      rightFooterContent={
        <WrappedButton
          onClick={onClickActionButton}
          disabled={!canSubmitForm || isUpsertingCourse}
          showSpinner={isUpsertingCourse}
        >
          {buttonText}
        </WrappedButton>
      }
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <WrappedTextField
            value={nameControl.value}
            label="Name"
            onChange={nameControl.onChange}
            error={nameControl.hasError}
            helperText={nameControl.shownErrorMessage}
          />
          <WrappedSelect
            label="Status"
            onChange={courseStatusControl.onChange}
            value={courseStatusControl.value}
            options={courseStatusOptions}
            hasError={courseStatusControl.hasError}
            shownErrorMessage={courseStatusControl.shownErrorMessage}
          />
          <WrappedTextField
            value={cityControl.value}
            label="City"
            onChange={cityControl.onChange}
            error={cityControl.hasError}
            helperText={cityControl.shownErrorMessage}
          />
          <WrappedSelect
            label="State"
            onChange={stateControl.onChange}
            value={stateControl.value}
            options={stateOptions}
            hasError={stateControl.hasError}
            shownErrorMessage={stateControl.shownErrorMessage}
          />
          <WrappedSelect
            label="Timezone"
            onChange={timezoneControl.onChange}
            value={timezoneControl.value}
            options={timezoneOptions}
            hasError={timezoneControl.hasError}
            shownErrorMessage={timezoneControl.shownErrorMessage}
          />
          <WrappedTextField
            value={courseIDControl.value}
            label="Course ID (Used For Link Creation)"
            onChange={courseIDControl.onChange}
            error={courseIDControl.hasError}
            helperText={courseIDControl.shownErrorMessage}
          />
          <WrappedSelect
            label="Hole Options"
            onChange={teeTimeHoleControl.onChange}
            value={teeTimeHoleControl.value}
            options={teeTimeHoleOptions}
            hasError={teeTimeHoleControl.hasError}
            shownErrorMessage={teeTimeHoleControl.shownErrorMessage}
          />
          <WrappedSelect
            label="Tee Time Software"
            onChange={teeTimeSchedulingSoftwareControl.onChange}
            value={teeTimeSchedulingSoftwareControl.value}
            options={teeTimeSoftwareOptions}
            hasError={teeTimeSchedulingSoftwareControl.hasError}
            shownErrorMessage={
              teeTimeSchedulingSoftwareControl.shownErrorMessage
            }
          />
          {teeTimeSchedulingSoftwareControl.value === TeeTimeSchedulingSoftware.Chrono && (
            <ChronoCourseOptions
              courseOptions={chronoCourseOptions}
              onChange={onUpdateChronoCourseOptions}
              onRemove={onRemoveChronoCourseOption}
              onAdd={onAddChronoCourseOption}
            />
          )}
          <SchedulingOffset
            schedulingOffset={schedulingOffset}
            onChangeSchedulingOffset={onChangeSchedulingOffset}
          />
          <Steps steps={steps} setSteps={setSteps} />
        </Box>
      </Box>
    </WrappedDialog>
  );
}

export default CommonCourseDialog;
