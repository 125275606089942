import { ChangeEvent, useEffect, useState } from "react";
import { StringValidator } from "../../classes/stringValidator";
import { useControl } from "../../hooks/useControl";
import * as AWSCognitoIdentity from "amazon-cognito-identity-js";
import * as AWS from "aws-sdk/global";
import { cognitoUserSingleton } from "../../classes/cognitoUserSingleton";
import { userPool } from "../../classes/userPool";
import { useNavigate } from "react-router-dom";

export function useResetPassword() {
    const emailControl = useControl({
        value: "",
        onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            return event.target.value;
        },
        validatorError: (name: string) => {
            return new StringValidator()
                .email()
                .required("An email is required")
                .validate(name);
        },
    });

    const formIsValid = emailControl.isValid;

    const navigate = useNavigate();
    const [isTriggeringPasswordReset, setIsTriggeringPasswordReset] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    function clearErrorMessage() {
        setErrorMessage("");
    }

    function onClickResetPassword() {
        setIsTriggeringPasswordReset(true);
    }

    useEffect(() => {
        if (!isTriggeringPasswordReset) return;

        let didCancel = false;

        const userData = {
            Username: emailControl.value,
            Pool: userPool,
        };
        cognitoUserSingleton.cognitoUser = new AWSCognitoIdentity.CognitoUser(
            userData,
        );
        cognitoUserSingleton.cognitoUser.forgotPassword({
            onSuccess: (data) => {
                navigate("/enter-new-password");
            },
            onFailure: (error) => {
                console.log("error: ", error);
                setErrorMessage("There was an unexpected error while resetting your password.")
                setIsTriggeringPasswordReset(false);
            },
        });

        return () => {
            didCancel = true;
        };
    }, [isTriggeringPasswordReset]);

    const onSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault();
        setIsTriggeringPasswordReset(true);
    }

    return {
        emailControl,
        formIsValid,
        onClickResetPassword,
        onSubmit,
        errorMessage,
        clearErrorMessage,
        isTriggeringPasswordReset,
    };
}
