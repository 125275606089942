import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ScheduleTeeTimeBase } from '../types'

export interface TeeTimesState {
    teeTimes: ScheduleTeeTimeBase[]
}

const initialState: TeeTimesState = {
    teeTimes: [],
}

export const teeTimesSlice = createSlice({
    name: 'teeTimes',
    initialState,
    reducers: {
        setTeeTimes: (state, action: PayloadAction<ScheduleTeeTimeBase[]>) => {
            state.teeTimes = action.payload;
        },
        addTeeTime: (state, action: PayloadAction<ScheduleTeeTimeBase>) => {
            state.teeTimes = [action.payload, ...state.teeTimes];
        },
        deleteTeeTime: (state, action: PayloadAction<string>) => {
            state.teeTimes = state.teeTimes.filter((compareTeeTime) => {
                return compareTeeTime.PK !== action.payload;
            });
        }
    },
})

// Action creators are generated for each case reducer function
export const { setTeeTimes, addTeeTime, deleteTeeTime } = teeTimesSlice.actions

export default teeTimesSlice.reducer;