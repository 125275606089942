import {
  Box,
  Dialog,
  DialogTitle,
  Divider,
  IconButton,
  SxProps,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface IWrappedDialogProps {
  open: boolean;
  onClose?: () => void;
  onCloseExplicit?: () => void;
  title: string;
  children: React.ReactNode;
  leftFooterContent?: React.ReactNode;
  rightFooterContent?: React.ReactNode;
  sx?: SxProps;
}

function WrappedDialog({
  open,
  onClose,
  title,
  children,
  sx,
  onCloseExplicit,
  leftFooterContent,
  rightFooterContent,
}: IWrappedDialogProps) {
  const showFooter = !!leftFooterContent || !!rightFooterContent;
  return (
    <Dialog onClose={onClose} open={open} maxWidth={false}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
        }}
      >
        <Box
          sx={{
            position: "relative",
            flex: "0 0 auto",
          }}
        >
          <DialogTitle>{title}</DialogTitle>
          {!!onCloseExplicit && (
            <Box
              sx={{
                position: "absolute",
                top: 8,
                right: 8,
              }}
            >
              <IconButton aria-label="delete" onClick={onCloseExplicit}>
                <CloseIcon />
              </IconButton>
            </Box>
          )}
          <Divider />
        </Box>
        <Box
          sx={{
            padding: 1,
            overflow: "auto",
            flexGrow: 1,
          }}
        >
          <Box sx={sx}>{children}</Box>
        </Box>
        {showFooter && (
          <Box>
            <Divider />
            <Box
              sx={{
                padding: 1,
                flex: "0 0 auto",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box>{leftFooterContent}</Box>
              <Box>{rightFooterContent}</Box>
            </Box>
          </Box>
        )}
      </Box>
    </Dialog>
  );
}

export default WrappedDialog;
