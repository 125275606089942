import { Box, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import WrappedTable, {
  IWrappedTableColumn,
} from "../../../components/wrappedTable";
import { User } from "../../../types";
import { useUsers } from "./useUsers";

function Users() {
  const { isLoading, users } = useUsers();

  const [columns] = useState<IWrappedTableColumn<User>[]>(() => {
    return [
      {
        text: "Name",
        key: "name",
        createContent: (item: User) => {
          return (
            <Typography>{item.firstName + " " + item.lastName}</Typography>
          );
        },
      },
      {
        text: "Email",
        key: "email",
        createContent: (item: User) => {
          return <Typography>{item.email}</Typography>;
        },
      },
      {
        text: "Admin Role",
        key: "admin_role",
        createContent: (item: User) => {
          return <Typography>{item.adminRole}</Typography>;
        },
      },
    ];
  });

  const createDataKey = useCallback((item: User) => {
    return item.PK;
  }, []);

  return (
    <Box
      sx={{
        padding: 2,
      }}
    >
      <WrappedTable
        title="Users"
        items={users}
        columns={columns}
        createDataKey={createDataKey}
        isLoading={isLoading}
        noItemsText="There are currently no users"
      />
    </Box>
  );
}

export default Users;
