export type IStringValidatorRule =
  | IStringRule
  | IRegexRule
  | ILengthRule
  | IURLRule
  | ICustomRule;

export interface IStringRule {
  type: "must-be-string";
  errorMessage: string;
}

export interface IRegexRule {
  type: "regex";
  regex: RegExp;
  errorMessage: string;
}

export interface ILengthRule {
  type: "length";
  min?: number;
  max?: number;
  errorMessage: string;
}

export interface IURLRule {
  type: "url";
  errorMessage: string;
}

export interface ICustomRule {
  type: "custom";
  isValidCallback: (value: any) => boolean;
  errorMessage: string;
}

export class StringValidator {
  public validatorRules: IStringValidatorRule[] = [
    {
      type: "must-be-string",
      errorMessage: "Value must be string",
    },
  ];

  public email(errorMessage?: string) {
    this.validatorRules.push({
      type: "regex",
      regex:
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
      errorMessage: errorMessage || "Invalid Email",
    });

    return this;
  }

  public onlyAThroughZ(errorMessage?: string) {
    this.validatorRules.push({
      type: "regex",
      regex: /^$|^[a-zA-Z]+$/,
      errorMessage: errorMessage || "Only a through z is allowed",
    });

    return this;
  }

  public integer(errorMessage?: string) {
    this.validatorRules.push({
      type: "regex",
      regex:
        /^[0-9]*$/,
      errorMessage: errorMessage || "Only integers are allowed",
    });

    return this;
  }

  public required(errorMessage?: string) {
    this.validatorRules.push({
      type: "length",
      min: 1,
      errorMessage: errorMessage || "Value is required",
    });

    return this;
  }

  public min(length: number, errorMessage?: string) {
    this.validatorRules.push({
      type: "length",
      min: length,
      errorMessage:
        errorMessage || `Value must be at least ${length} characters`,
    });

    return this;
  }

  public max(length: number, errorMessage: string) {
    this.validatorRules.push({
      type: "length",
      max: length,
      errorMessage,
    });

    return this;
  }

  public url() {
    this.validatorRules.push({
      type: "url",
      errorMessage: "Invalid URL",
    });

    return this;
  }

  public customValidator(
    isValidCallback: (value: any) => boolean,
    errorMessage: string,
  ) {
    this.validatorRules.push({
      type: "custom",
      isValidCallback,
      errorMessage,
    });

    return this;
  }

  public validate(value: string) {
    for (let i = 0; i < this.validatorRules.length; i++) {
      const rule = this.validatorRules[i];

      if (rule.type === "must-be-string") {
        if (typeof value !== "string") {
          return rule.errorMessage;
        }
      }

      if (rule.type === "regex") {
        if (!rule.regex.test(value)) {
          return rule.errorMessage;
        }
      }

      if (rule.type === "length") {
        const matchesMin = rule.min === undefined || value.length >= rule.min;
        const matchesMax = rule.max === undefined || value.length <= rule.max;

        if (!matchesMin || !matchesMax) {
          return rule.errorMessage;
        }
      }

      if (rule.type === "url") {
        const expression =
          /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;
        const regex = new RegExp(expression);
        const isValid = regex.test(value);
        if (!isValid) {
          return rule.errorMessage;
        }
      }

      if (rule.type === "custom") {
        const isValid = rule.isValidCallback(value);
        if (!isValid) {
          return rule.errorMessage;
        }
      }
    }

    return "";
  }
}
