import { useEffect, useState } from "react";
import { Api } from "../../../../../../api";
import { ScheduleTeeTimeBase, ScheduleTeeTimeCreateRequest } from "../../../../../../types";

export function useScheduleTeeTimeDialog(onSubmitButtonSuccess: (
  teeTime: ScheduleTeeTimeBase
) => void) {
  const [request, setRequest] = useState<ScheduleTeeTimeCreateRequest | null>(
    null,
  );
  function onClickSubmitForm(
    scheduleTeeTimeCreateRequest: ScheduleTeeTimeCreateRequest,
  ) {
    setRequest(scheduleTeeTimeCreateRequest);
  }

  useEffect(() => {
    if (!request) {
      return;
    }

    let didCancel = false;

    Api.scheduledTeeTime
      .createScheduledTeeTime(request)
      .then((teeTime) => {
        if (didCancel) {
          return;
        }
        onSubmitButtonSuccess(teeTime);
      })
      .catch(() => {
        if (didCancel) {
          return;
        }
      })
      .finally(() => {
        if (didCancel) {
          return;
        }
        setRequest(null);
      });

    return () => {
      didCancel = true;
    };
  }, [request]);

  return {
    onClickSubmitForm,
    actionInProgress: !!request,
  };
}
