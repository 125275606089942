import { ChangeEvent } from "react";
import { StringValidator } from "../../../../../../../classes/stringValidator";
import { useControl } from "../../../../../../../hooks/useControl";
import { RouteStep, Step, StepType } from "../../../../../../../types";

export function useNavigationStep(onClickCreateStep: (step: Step) => void) {
  const urlControl = useControl({
    value: "",
    onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      return event.target.value;
    },
    validatorError: (city: string) => {
      return new StringValidator()
        .required("The URL is required.")
        .url()
        .validate(city);
    },
  });

  function onClickCreateStepInternal() {
    const navigationStep: RouteStep = {
      type: StepType.Route,
      metadata: {
        url: urlControl.value,
      },
    };

    onClickCreateStep(navigationStep);
  }

  const onSubmit = (function () {
    if (!urlControl.isValid) {
      return undefined;
    }

    const onSubmitInternal: React.FormEventHandler<HTMLFormElement> = (
      event,
    ) => {
      event.preventDefault();
      onClickCreateStepInternal();
    };

    return onSubmitInternal;
  })();

  return {
    urlControl,
    onClickCreateStepInternal,
    onSubmit,
  };
}
