import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";

interface WrappedRadioProps {
    label?: string;
    options: IWrappedRadioOption[];
    onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void,
    value: any,
}

export interface IWrappedRadioOption {
    value: any;
    label: string;
    disabled?: boolean;
}

function WrappedRadio({
    label,
    options,
    onChange,
    value,
}: WrappedRadioProps) {
    return (
        <FormControl>
            {!!label && (
                <FormLabel>{label}</FormLabel>
            )}
            <RadioGroup onChange={onChange} value={value}>
                {options.map(({ value, label, disabled }, index) => {
                    return (
                        <FormControlLabel disabled={disabled} value={value} control={<Radio />} label={label} key={index} />
                    );
                })}
            </RadioGroup>
        </FormControl>
    )
}

export default WrappedRadio;