import { Box, Typography } from "@mui/material";
import { CenterLoadingSpinner } from "../../../components/centerLoadingSpinner";
import { LogDisplay } from "../../../components/logDisplay";
import Status from "../../../components/status";
import { TimeSlotsDisplay } from "../../../components/timeSlotsDisplay";
import WrappedTextField from "../../../components/wrappedTextField";
import { FullCourseMetadata, ScheduleTeeTimeBase, Status as StatusType } from "../../../types";
import CommonTeeTimeFields from "../courses/coursesTable/courseActions/commonTeeTimeDialog/commonTeeTimeFields";
import ImageSliders from "../fullTest/imageSliders";
import { useScheduledTeeTime } from "./useScheduledTeeTime";

function ScheduledTeeTime() {
  const { isLoading, scheduledTeeTime, course, imageSliderProps, logs, timeSlots, bookingTimeDisplay, orText } =
    useScheduledTeeTime();

  return (
    <Box
      sx={{
        padding: 2,
      }}
    >
      {isLoading || course === null ? (
        <CenterLoadingSpinner />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              flexBasis: 0,
              flexShrink: 0,
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 1,
              }}
            >
              <Typography variant="h6">
                Scheduled Tee Time For {(course as FullCourseMetadata).name}{orText}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Status
                  status={(scheduledTeeTime as ScheduleTeeTimeBase).status}
                  tooltip={
                    (scheduledTeeTime as ScheduleTeeTimeBase).statusReason
                  }
                />
              </Box>
            </Box>
            <CommonTeeTimeFields
              fullCourseMetadata={course as FullCourseMetadata}
              isFullTestRun={false}
              scheduleTeeTimeBase={scheduledTeeTime as ScheduleTeeTimeBase}
              disabled
              requireUsernameAndPassword
            />
            {(scheduledTeeTime as ScheduleTeeTimeBase).status !== StatusType.PENDING && (
              <WrappedTextField
                value={bookingTimeDisplay}
                label="Booked Tee Time"
                disabled
              />
            )}
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              flexBasis: 0,
              flexShrink: 0,
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <ImageSliders
              imageSliders={imageSliderProps}
            />
          </Box>
          {!!logs && (
            <LogDisplay
              logsWithTime={logs.logs}
              timezone={course.timezone}
            />
          )}
          {!!timeSlots && (
            <TimeSlotsDisplay timeSlots={timeSlots} />
          )}
        </Box>
      )}
    </Box>
  );
}

export default ScheduledTeeTime;

