import axios from "axios";
import { environmentVariables } from "../environmentVariables";
import {
  FullCourseMetadata,
  SimpleCourse,
  UpsertCourseMetadata,
} from "../types";

export interface ICourseAPI {
  getAdminCourses(): Promise<{
    Items: FullCourseMetadata[];
    Count: number;
  }>;

  upsertCourse(
    upsertCourseMetadata: UpsertCourseMetadata,
  ): Promise<FullCourseMetadata>;

  getCourse(coursePK: string): Promise<FullCourseMetadata>;

  getPublishedCourses(): Promise<{
    Items: SimpleCourse[];
    Count: number;
  }>;
}

export class CourseAPI implements ICourseAPI {
  public async getAdminCourses() {
    const axiosResponse = await axios.get<{
      Items: FullCourseMetadata[];
      Count: number;
    }>(`${environmentVariables.baseAuthenticatedApiUrl}/admin-courses`);

    return axiosResponse.data;
  }

  public async upsertCourse(upsertCourseMetadata: UpsertCourseMetadata) {
    const axiosResponse = await axios.post<FullCourseMetadata>(
      `${environmentVariables.baseAuthenticatedApiUrl}/course`,
      upsertCourseMetadata,
    );

    return axiosResponse.data;
  }

  public async getCourse(coursePK: string) {
    const axiosResponse = await axios.get<FullCourseMetadata>(
      `${environmentVariables.baseAuthenticatedApiUrl}/course`,
      {
        params: {
          PK: coursePK,
        },
      },
    );

    return axiosResponse.data;
  }

  public async getPublishedCourses() {
    const axiosResponse = await axios.get<{
      Items: SimpleCourse[];
      Count: number;
    }>(`${environmentVariables.basePublicApiUrl}/published-courses`);

    return axiosResponse.data;
  }
}
