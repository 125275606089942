import { Box, Typography } from "@mui/material";
import { ClickElementWithTextStep } from "../../../../../../types";

interface IDisplayClickElementWithTextStep {
  step: ClickElementWithTextStep;
}

function DisplayClickElementWithTextStep({
  step,
}: IDisplayClickElementWithTextStep) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Typography>
        Click element ({step.metadata?.element}) with text: "
        {step.metadata?.text}"
      </Typography>
    </Box>
  );
}

export default DisplayClickElementWithTextStep;
