import axios from "axios";
import { environmentVariables } from "../environmentVariables";
import { SignUpRequest, User } from "../types";

export interface IUserAPI {
  signUp(request: SignUpRequest): Promise<User>;
  getAllUsers(): Promise<{
    Items: User[];
    Count: number;
  }>;

  getUser(): Promise<User>;
}

export class UserAPI implements IUserAPI {
  public async signUp(request: SignUpRequest) {
    const axiosResponse = await axios.post<User>(
      `${environmentVariables.basePublicApiUrl}/user`,
      request,
    );
    return axiosResponse.data;
  }

  public async getAllUsers() {
    const axiosResponse = await axios.get<{
      Items: User[];
      Count: number;
    }>(`${environmentVariables.baseAuthenticatedApiUrl}/users`);

    return axiosResponse.data;
  }

  public async getUser() {
    const axiosResponse = await axios.get<User>(
      `${environmentVariables.baseAuthenticatedApiUrl}/user`,
    );
    return axiosResponse.data;
  }
}
