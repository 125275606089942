import { Box, Typography } from "@mui/material";
import { DateTime } from "luxon";
import BoxWithBorder from "../boxWithBorder";
import { TimeSlots } from "../../types";

interface TimeSlotsDisplayProps {
    timeSlots?: TimeSlots;
}

export function TimeSlotsDisplay({
    timeSlots,
}: TimeSlotsDisplayProps) {
    if (!timeSlots) {
        return null;
    }

    return (
        <BoxWithBorder sx={{
            display: "flex",
            flexDirection: "column",
            padding: 0,
            gap: 0,
        }}>
            <Box sx={{
                padding: 2,
            }}>
                <Typography variant="h6">Allotted Time Slots</Typography>
            </Box>
            {timeSlots.map(({ startTimeInclusive, endTimeExclusive }, index) => {
                const backgroundColor = index % 2 === 0 ? "grey.200" : "transparent";
                const start = DateTime.fromISO(startTimeInclusive).toLocaleString(DateTime.TIME_SIMPLE);
                const end = DateTime.fromISO(endTimeExclusive).toLocaleString(DateTime.TIME_SIMPLE);

                return (
                    <Box key={index} sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                        backgroundColor,
                        paddingLeft: 2,
                        paddingRight: 2,
                        paddingTop: 1,
                        paddingBottom: 1,
                        borderTop: 1,
                        borderColor: "grey.400"
                    }}>
                        <Typography>{start} - {end}</Typography>
                    </Box>
                )
            })}
        </BoxWithBorder>
    );
}