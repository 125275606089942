import { ChangeEvent, useEffect, useState } from "react";
import { StringValidator } from "../../classes/stringValidator";
import { useControl } from "../../hooks/useControl";
import { cognitoUserSingleton } from "../../classes/cognitoUserSingleton";
import { useNavigate } from "react-router-dom";

export function useEnterNewPassword() {
    const passwordControl = useControl({
        value: "",
        onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            return event.target.value;
        },
        validatorError: (name: string) => {
            return new StringValidator()
                .min(8, "A password must be at least 8 characters")
                .required("A password is required")
                .validate(name);
        },
    });

    const verificationCodeControl = useControl({
        value: "",
        onChange: (
            event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => {
            return event.target.value;
        },
        validatorError: (email: string) => {
            return new StringValidator()
                .required("This field is required")
                .validate(email);
        },
    });

    const email = cognitoUserSingleton.cognitoUser.getUsername();

    const [isConfirmingNewPassword, setIsConfirmingNewPassword] = useState(
        false
    );
    function triggerConfirmNewPassword() {
        setIsConfirmingNewPassword(true);
    }

    const onSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault();
        setIsConfirmingNewPassword(true);
    }

    const navigate = useNavigate();
    useEffect(() => {
        if (!isConfirmingNewPassword) return;

        let didCancel = false;

        cognitoUserSingleton.cognitoUser.confirmPassword(
            verificationCodeControl.value,
            passwordControl.value,
            {
                onSuccess: () => {
                    if (didCancel) return;
                    setIsConfirmingNewPassword(false);
                    navigate("/login");
                },
                onFailure: (error) => {
                    if (didCancel) return;
                    setIsConfirmingNewPassword(false);
                    setErrorMessage("There was an unexpected error while resetting your password.")
                    console.log("error: ", error);
                },
            }
        );

        return () => {
            didCancel = true;
        };
    }, [isConfirmingNewPassword]);

    const formIsValid = verificationCodeControl.isValid && passwordControl.isValid;
    const [errorMessage, setErrorMessage] = useState("");
    function clearErrorMessage() {
        setErrorMessage("");
    }

    return {
        passwordControl,
        verificationCodeControl,
        email,
        triggerConfirmNewPassword,
        onSubmit,
        isConfirmingNewPassword,
        formIsValid,
        errorMessage,
        clearErrorMessage,
    }
}
