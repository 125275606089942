import { Box, Typography } from "@mui/material";
import { useState, useCallback, useMemo } from "react";
import WrappedTable, {
  IWrappedTableColumn,
} from "../../../../components/wrappedTable";
import { FullCourseTestRun } from "../../../../types";
import { DateTime } from "luxon";
import Status from "../../../../components/status";
import { orderBy } from "lodash";
import FullTestRunTableActions from "./fullTestRunTableActions";
import SuccessIcon from "@mui/icons-material/CheckCircle";

interface ICoursesTableProps {
  items: FullCourseTestRun[];
  isLoading: boolean;
}

function FullTestRunsTable({ items, isLoading }: ICoursesTableProps) {
  const [columns] = useState<IWrappedTableColumn<FullCourseTestRun>[]>(() => {
    return [
      {
        text: "Course",
        key: "name",
        createContent: (item: FullCourseTestRun) => {
          return (
            <Box sx={{
              display: "flex",
              flexDirection: "row",
              gap: 1,
            }}>
              <Status status={item.status} tooltip={item.bookingAttemptData?.errorCode || ""} />
              <Typography>{item.courseName}</Typography>
            </Box>
          )
        },
      },
      {
        text: "Tee Time Date",
        key: "tee_time_date",
        createContent: (item: FullCourseTestRun) => {
          const teeTimeDate = DateTime.fromISO(item.teeTime.rangeStartInclusive).toLocaleString(
            DateTime.DATETIME_MED,
          );
          return <Typography>{teeTimeDate}</Typography>;
        },
      },
      {
        text: "Automated",
        key: "generated_automatically",
        createContent: (item: FullCourseTestRun) => {
          if (!!item.isAutomaticallyGenerated) {
            return <SuccessIcon color="primary" />
          }

          return null;
        }
      },
      {
        text: "Dev Script",
        key: "dev_script",
        createContent: (item: FullCourseTestRun) => {
          if (!!item.runWithDevelopmentScript) {
            return <SuccessIcon color="primary" />
          }

          return null;
        }
      },
      {
        text: "Actions",
        key: "actions",
        createContent: (item: FullCourseTestRun) => {
          return <FullTestRunTableActions fullCourseTestRun={item} />;
        },
      },
    ];
  });

  const createDataKey = useCallback((item: FullCourseTestRun) => {
    return item.PK;
  }, []);

  const orderedItems = useMemo(() => {
    return orderBy(items, "created").reverse();
  }, [items]);

  return (
    <WrappedTable
      title="Full Test Runs"
      items={orderedItems}
      columns={columns}
      createDataKey={createDataKey}
      isLoading={isLoading}
      noItemsText="All full test runs have expired."
    />
  );
}

export default FullTestRunsTable;
