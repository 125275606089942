import { Box, Tooltip, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

interface AddDeleteConditionWrapperProps {
    onClickAddBefore: () => void,
    onClickAddAfter: () => void,
    onClickDelete?: () => void,
}

function AddDeleteConditionWrapper({
    onClickAddAfter,
    onClickAddBefore,
    onClickDelete,
}: AddDeleteConditionWrapperProps) {
    return (
        <Box
            sx={{
                border: 1,
                borderRadius: 1,
                padding: 1,
                borderColor: "grey.400",
                display: "flex",
                flexDirection: "row",
                gap: 1,
            }}
        >
            <Box>
                <Tooltip title="Add Condition Before">
                    <IconButton onClick={onClickAddBefore}>
                        <AddIcon />
                    </IconButton>
                </Tooltip>
            </Box>
            {!!onClickDelete && (
                <Box>
                    <Tooltip title="Delete Condition">
                        <IconButton aria-label="delete" onClick={onClickDelete}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            )}
            <Box>
                <Tooltip title="Add Condition After">
                    <IconButton onClick={onClickAddAfter}>
                        <AddIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        </Box>
    )
}

export default AddDeleteConditionWrapper;