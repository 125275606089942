import { PaymentMethod } from "@stripe/stripe-js";
import { DateTime } from "luxon";
import BoxWithBorder from "../../../components/boxWithBorder";
import DataDescriptor from "../../../components/dataDescriptor";
import { CoursePaymentOption, CourseTransitOption, TeeTimeHoleOption, Status } from "../../../types";

interface TeeTimeDetailsProps {
    courseName?: string;
    courseCity?: string;
    courseState?: string;
    players?: 1 | 2 | 3 | 4 | null;
    date: DateTime<true> | DateTime<false> | null;
    rangeStart: DateTime<true> | DateTime<false> | null;
    rangeEnd: DateTime<true> | DateTime<false> | null;
    holeOption: TeeTimeHoleOption;
    transitOption: CourseTransitOption;
    paymentOption: CoursePaymentOption;
    username?: string;
    password?: string;
    card?: PaymentMethod.Card;
    status?: Status;
    backup?: string;
}

function TeeTimeDetails({
    courseName,
    courseCity,
    courseState,
    players,
    date,
    rangeStart,
    rangeEnd,
    holeOption,
    transitOption,
    paymentOption,
    username,
    password,
    status,
    backup,
}: TeeTimeDetailsProps) {
    const timeText = `${date?.toFormat("LLLL d")}, ${rangeStart?.toLocaleString(DateTime.TIME_SIMPLE)} to ${rangeEnd?.toLocaleString(DateTime.TIME_SIMPLE)}`;
    const courseInfoWithLocation = !!courseCity && !!courseState ? `${courseName} (${courseCity}, ${courseState})` : `${courseName}`;
    const convenienceFeeText = status === Status.ERROR ? "None" : `$${(players || 1) * 2}.00`;
    const holesText = `${holeOption === TeeTimeHoleOption.Nine ? 9 : 18}`;
    const transitText = transitOption === CourseTransitOption.Cart ? "Cart" : "Walking";
    const paymentText = paymentOption === CoursePaymentOption.InPerson ? "At The Course" : "Online";
    const backupText = !!backup ? `${backup}` : "None";

    return (
        <BoxWithBorder sx={{
            display: "flex",
            flexDirection: "column",
            gap: 0,
        }}>
            <DataDescriptor descriptor="Primary" text={courseInfoWithLocation} />
            <DataDescriptor descriptor="Backup" text={backupText} />
            {date && (
                <DataDescriptor descriptor="Range" text={timeText} />
            )}
            {players && (
                <DataDescriptor descriptor="Players" text={players.toString()} />
            )}
            <DataDescriptor descriptor="Holes" text={`${holesText}`} />
            <DataDescriptor descriptor="Transit Preference" text={transitText} />
            <DataDescriptor descriptor="Payment Preference" text={paymentText} />
            {!!username && (
                <DataDescriptor descriptor="Username" text={`${username}`} />
            )}
            {!!password && (
                <DataDescriptor descriptor="Password" text={`${password}`} />
            )}
            <DataDescriptor descriptor="Convenience Fee" text={convenienceFeeText} />
        </BoxWithBorder>
    )
}

export default TeeTimeDetails;