import { IconButton, Link, Menu, MenuItem } from "@mui/material";
import { useAccountIcon } from "./useAccountIcon";
import AccountBoxIcon from '@mui/icons-material/AccountBox';

function AccountIcon() {
    const {
        isOpen,
        onClickAccountIcon,
        onCloseAccountIcon,
        anchorEl,
        onClickLogout,
        mailTo,
    } = useAccountIcon();

    return (
        <>
            <IconButton onClick={onClickAccountIcon}>
                <AccountBoxIcon color="primary" />
            </IconButton>
            <Menu anchorEl={anchorEl} open={isOpen} onClose={onCloseAccountIcon}>
                <MenuItem onClick={onClickLogout} sx={{
                    minWidth: "200px",
                }}>Logout</MenuItem>
                <Link href={mailTo} underline="none" color="inherit">
                    <MenuItem sx={{
                        minWidth: "200px",
                    }}>Support</MenuItem>
                </Link>
            </Menu>
        </>
    )
}

export default AccountIcon;