import { Box, IconButton, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Step, StepType } from "../../../../../../types";
import DisplayNavigationStep from "../displayNavigationStep";
import CreateStepButton from "../createStepButton";
import DisplayFindElementByTextStep from "../displayFindElementByTextStep";
import DisplayFindElementByTextNavigateToAncestorAnchorHref from "../displayFindElementByTextNavigateToAncestorAnchorHref";
import DisplayClickElementWithTextStep from "../displayClickElementByTextStep";
import DisplayScheduleForeUpTeeTimeStep from "../displayScheduleForeUpTeeTimeStep";
import DisplayScheduleChronoTeeTimeStep from "../displayScheduleChronoTeeTimeStep";
import DisplayTeeItUpTeeTimeStep from "../displayScheduleTeeItUpTeeTimeStep";
import DisplayConditionalClickElementWithTextStep from "../displayConditionalClickElementByTextStep";
import BoxWithBorder from "../../../../../../components/boxWithBorder";

interface IAddDeleteStepWrapper {
  step: Step;
  index: number;
  wrappedOnClickCreateStep(index: null | number): (step: Step) => void;
  onClickDeleteStep: () => void;
}

function AddDeleteStepWrapper({
  step,
  wrappedOnClickCreateStep,
  onClickDeleteStep,
  index,
}: IAddDeleteStepWrapper) {
  const onClickCreateBefore = wrappedOnClickCreateStep(index);
  const onClickCreateAfter = wrappedOnClickCreateStep(index + 1);

  return (
    <Box
      sx={{
        display: "flex",
        gap: 2,
      }}
    >
      <Box>
        <BoxWithBorder sx={{
          display: "flex",
          flexDirection: "row",
        }}>
          <Box>
            <CreateStepButton
              text="Add Step Before"
              useIcon
              onClickCreateStep={onClickCreateBefore}
            />
          </Box>
          <Box>
            <Tooltip title="Delete Step">
              <IconButton aria-label="delete" onClick={onClickDeleteStep}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Box>
            <CreateStepButton
              text="Add Step After"
              useIcon
              onClickCreateStep={onClickCreateAfter}
            />
          </Box>
        </BoxWithBorder>
      </Box>
      <>
        {step.type === StepType.Route && <DisplayNavigationStep step={step} />}
        {step.type === StepType.FindElementByText && (
          <DisplayFindElementByTextStep step={step} />
        )}
        {step.type ===
          StepType.FindElementByTextNavigateToAncestorAnchorHref && (
            <DisplayFindElementByTextNavigateToAncestorAnchorHref step={step} />
          )}
        {step.type === StepType.ClickElementWithText && (
          <DisplayClickElementWithTextStep step={step} />
        )}
        {step.type === StepType.ScheduleForeUpTeeTime && (
          <DisplayScheduleForeUpTeeTimeStep />
        )}
        {step.type === StepType.ScheduleChronoUpTeeTime && (
          <DisplayScheduleChronoTeeTimeStep />
        )}
        {step.type === StepType.ScheduleTeeItUpTeeTime && (
          <DisplayTeeItUpTeeTimeStep />
        )}
        {step.type === StepType.ConditionalClickElementWithText && (
          <DisplayConditionalClickElementWithTextStep step={step} />
        )}
      </>
    </Box>
  );
}

export default AddDeleteStepWrapper;
