import { useEffect, useState } from "react";
import { Api } from "../../../../api";

export function useImageSlider(folderKey: string) {
  const [isLoadingImages, setIsLoadingImages] = useState(true);
  const [signedURLsWithMetadata, setSignedURLsWithMetadata] = useState<
    {
      description: string;
      signedURL: string;
      lastModified: string | null;
    }[]
  >([]);
  useEffect(() => {
    if (!isLoadingImages) {
      return;
    }
    let didCancel = false;

    Api.signedUrls
      .getSignedUrls(folderKey)
      .then((signedUrlsFromData) => {
        if (didCancel) {
          return;
        }

        setSignedURLsWithMetadata(signedUrlsFromData);
      })
      .finally(() => {
        if (didCancel) {
          return;
        }

        setIsLoadingImages(false);
      });

    return () => {
      didCancel = true;
    };
  }, [isLoadingImages]);

  const [index, setIndex] = useState(0);
  function onClickLeftButton() {
    setIndex((previousIndex) => previousIndex - 1);
  }
  function onClickRightButton() {
    setIndex((previousIndex) => previousIndex + 1);
  }
  function onClickGoToFirstPage() {
    setIndex(0);
  }
  function onClickGoToLastPage() {
    setIndex(signedURLsWithMetadata.length - 1);
  }

  return {
    onClickLeftButton,
    onClickRightButton,
    onClickGoToLastPage,
    onClickGoToFirstPage,
    index,
    signedURLsWithMetadata,
    isLoadingImages,
  };
}
