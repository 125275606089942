import { SelectChangeEvent } from "@mui/material";
import { orderBy } from "lodash";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { Api } from "../../../api";
import { IWrappedHeaderActionButton } from "../../../components/wrappedTable";
import { useControl } from "../../../hooks/useControl";
import { CourseStatus, FullCourseMetadata, TeeTimeSchedulingSoftware } from "../../../types";

export function useCourses() {
  const [isLoadingCourses, setIsLoadingCourses] = useState(true);
  const [items, setItems] = useState<FullCourseMetadata[]>([]);
  useEffect(() => {
    if (!isLoadingCourses) {
      return;
    }

    let didCancel = false;

    Api.course
      .getAdminCourses()
      .then(function ({ Items }) {
        if (didCancel) {
          return;
        }

        const orderedCourses = orderBy(Items, (course) => {
          const updatedTitle = course.name.replace("The", "")
            .replace(" ", "")
            .replace("the", "");
          return updatedTitle;
        });
        setItems(orderedCourses);
      })
      .catch(function (error) {
        if (didCancel) {
          return;
        }
        // handle error
        console.log(error);
      })
      .finally(function () {
        if (didCancel) {
          return;
        }

        setIsLoadingCourses(false);
      });

    return () => {
      didCancel = true;
    };
  }, [isLoadingCourses]);

  const [createDialogIsOpen, setCreateDialogIsOpen] = useState(false);
  const onClickCreateCourse = useCallback(() => {
    setCreateDialogIsOpen(true);
  }, [setCreateDialogIsOpen]);
  function onCloseCreateCourseDialog() {
    setCreateDialogIsOpen(false);
  }

  const [fullCourseMetadata, setFullCourseMetadata] =
    useState<FullCourseMetadata | null>(null);
  const [editDialogIsOpen, setEditDialogIsOpen] = useState(false);
  function onClickEditCourse(fullCourseMetadataToEdit: FullCourseMetadata) {
    return () => {
      setFullCourseMetadata(fullCourseMetadataToEdit);
      setEditDialogIsOpen(true);
    };
  }
  function onCloseEditCourseDialog() {
    setEditDialogIsOpen(false);
  }

  function onCreateCourseSuccess(fullCourseMetadata: FullCourseMetadata) {
    setItems((previousItems) => {
      return [...previousItems, fullCourseMetadata];
    });
  }
  function onUpdateCourseSuccess(updatedCourseMetadata: FullCourseMetadata) {
    setItems((previousItems) => {
      return previousItems.map((previousItem) => {
        if (previousItem.PK === updatedCourseMetadata.PK) {
          return updatedCourseMetadata;
        }

        return previousItem;
      });
    });
  }

  const headerActionButton = useMemo<IWrappedHeaderActionButton>(() => {
    return {
      text: "Create Course",
      onClick: onClickCreateCourse,
    };
  }, [onClickCreateCourse]);

  const [bookingSoftwareOptions] = useState(() => {
    return [
      {
        value: "All",
        label: "All",
      },
      {
        value: TeeTimeSchedulingSoftware.ForeUp,
        label: "ForeUp",
      },
      {
        value: TeeTimeSchedulingSoftware.Chrono,
        label: "Chrono",
      },
    ];
  });

  const bookingSoftwareControl = useControl({
    value: "All",
    onChange: (event: SelectChangeEvent<"All" | TeeTimeSchedulingSoftware>) => {
      return event.target.value as "All" | TeeTimeSchedulingSoftware;
    },
  });

  const [courseStatusOptions] = useState(() => {
    return [
      {
        value: "All",
        label: "All",
      },
      {
        value: CourseStatus.Draft,
        label: "Draft",
      },
      {
        value: CourseStatus.Published,
        label: "Published",
      },
    ];
  });

  const courseStatusControl = useControl({
    value: "All",
    onChange: (event: SelectChangeEvent<"All" | CourseStatus>) => {
      return event.target.value as "All" | CourseStatus;
    },
  });

  const searchControl = useControl({
    value: "",
    onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      return event.target.value;
    },
  });

  const [hideArchived, setHideArchived] = useState(true);
  const onChangeHideArchived: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void = (
    _unused,
    checked,
  ) => {
    setHideArchived(checked);
  }

  const filteredItems = useMemo(() => {
    return items.filter((item) => {
      // Filter on the booking software
      if (bookingSoftwareControl.value === "All") {
        return true;
      } else {
        return item.teeTimeSchedulingSoftware === bookingSoftwareControl.value;
      }
    }).filter((item) => {
      if (courseStatusControl.value === "All") {
        return true;
      } else {
        return item.status === courseStatusControl.value;
      }
    }).filter((item) => {
      const value = searchControl.value.trim().toLowerCase();
      if (value === "") {
        return true;
      } else {
        return item.name.toLowerCase().includes(value) || item.city.toLowerCase().includes(value) || item.state.toLowerCase().includes(value);
      }
    }).filter((item) => {
      if (hideArchived) {
        return item.status !== CourseStatus.Archived;
      } else {
        return true;
      }
    })
  }, [items, bookingSoftwareControl.value, courseStatusControl.value, searchControl.value, hideArchived]);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleOpenFilterPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseFilterPopover = () => {
    setAnchorEl(null);
  };
  const filterPopoverIsOpen = Boolean(anchorEl);

  return {
    isLoadingCourses,
    createDialogIsOpen,
    onCloseCreateCourseDialog,
    headerActionButton,
    fullCourseMetadata,
    editDialogIsOpen,
    onClickEditCourse,
    onCloseEditCourseDialog,
    onCreateCourseSuccess,
    onUpdateCourseSuccess,
    bookingSoftwareControl,
    bookingSoftwareOptions,
    filteredItems,
    filterPopoverIsOpen,
    handleOpenFilterPopover,
    handleCloseFilterPopover,
    anchorEl,
    courseStatusOptions,
    courseStatusControl,
    searchControl,
    hideArchived,
    onChangeHideArchived,
  };
}
