import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { Box, Button, FormHelperText, LinearProgress, Typography, useTheme } from "@mui/material";
import WrappedButton from "../../../components/wrappedButton";
import { useNewUserTeeTime } from "./useNewUserTeeTime";
import { PaymentElement } from '@stripe/react-stripe-js';

function NewUserTeeTime() {
    const theme = useTheme();
    const {
        activeStepIndex,
        onClickBack,
        onClickNext,
        activeStep,
        disableNext,
        playerControl,
        disableSubmitButton,
        createTeeTimeInProgress,
        hasVerifiedInformation,
        stripe,
        elements,
        onSubmitSetPaymentMethod,
        isSubmittingDefaultPaymentMethod,
        stripePaymentMethodError,
        hasExistingPaymentMethod,
        isExistingTeeTime,
        linearProgressValue,
        paymentIsActive,
    } = useNewUserTeeTime();

    return (
        <Box sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
        }}>
            <Box sx={{
                display: "flex",
                flexGrow: 1,
                flexDirection: "column",
                overflow: "auto",
                padding: 2,
            }}>
                <Typography variant="h6" sx={{
                    paddingBottom: 2,
                }}>{activeStep.title}</Typography>
                {activeStep.element}
                <Box sx={{
                    display: paymentIsActive && !hasExistingPaymentMethod ? "auto" : "none",
                }}>
                    <Box sx={{
                        display: "flex",
                        gap: 2,
                        flexDirection: "column",
                    }}>
                        <Typography>A ${(playerControl.value || 1) * 2}.00 transaction fee will be charged to this card after successfully scheduling the tee time.</Typography>
                        <PaymentElement />
                        {stripePaymentMethodError && (
                            <FormHelperText error={true}>{stripePaymentMethodError}</FormHelperText>
                        )}
                    </Box>
                </Box>
            </Box>
            <Box sx={{
                flexBasis: "auto",
                flexGrow: 0,
                flexShrink: 0,
                backgroundColor: theme.palette.grey["100"],
                display: "flex",
                flexDirection: "column",
            }}>
                <Box sx={{
                    flexGrow: 1,
                }}>
                    <LinearProgress variant="determinate" value={linearProgressValue} />
                </Box>
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: 1,
                }}>
                    <Box>
                        <Button size="small" onClick={onClickBack} disabled={activeStepIndex === 0 || createTeeTimeInProgress}>
                            <KeyboardArrowLeft />
                            Back
                        </Button>
                    </Box>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 1,
                    }}>
                        <Button size="small" onClick={onClickNext} disabled={disableNext || createTeeTimeInProgress}>
                            Next
                            <KeyboardArrowRight />
                        </Button>
                        <WrappedButton
                            onClick={onSubmitSetPaymentMethod}
                            showSpinner={isSubmittingDefaultPaymentMethod || createTeeTimeInProgress}
                            color="primary"
                            variant="contained"
                            disabled={!stripe || !elements || disableSubmitButton || isSubmittingDefaultPaymentMethod || createTeeTimeInProgress || !hasVerifiedInformation}
                        >
                            {isExistingTeeTime ? "Update" : "Book"}
                        </WrappedButton>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default NewUserTeeTime;