import { SelectChangeEvent } from "@mui/material";
import { DateTime } from "luxon";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { NumberValidator } from "../../../../../../classes/numberValidator";
import { StringValidator } from "../../../../../../classes/stringValidator";
import { IWrappedSelectOption } from "../../../../../../components/wrappedSelect";
import { useControl } from "../../../../../../hooks/useControl";
import {
  CoursePaymentOption,
  CourseTransitOption,
  ScheduleTeeTimeBase,
  ScheduleTeeTimeCreateRequest,
  SimpleCourse,
  TeeTimeHoleOption,
} from "../../../../../../types";
import { allControlsAreValid } from "../../../../../../utils/allControlsAreValid";
import { getOffsetByPlayer } from "./getOffsetByPlayer";
import { firstAvailableMoment } from "./firstAvailableMoment";

export function useCommonTeeTimeDialog({
  isFullTestRun,
  requireUsernameAndPassword,
  fullCourseMetadata,
  onClickSubmitForm,
  scheduleTeeTimeBase,
  disabled,
  allowBookNow,
  didUseDevelopmentTestScript,
  onClickPlayers,
  onClickHoles,
  onClickTransitOption,
  onClickPaymentOption,
  onClickDate,
  onRangeEndChange,
  onRangeStartChange,
}: {
  isFullTestRun: boolean,
  requireUsernameAndPassword: boolean,
  fullCourseMetadata?: SimpleCourse,
  onClickSubmitForm?: (
    scheduleTeeTimeCreateRequest: ScheduleTeeTimeCreateRequest,
  ) => void,
  scheduleTeeTimeBase?: Partial<ScheduleTeeTimeBase>,
  disabled?: boolean,
  allowBookNow?: boolean,
  didUseDevelopmentTestScript?: boolean,
  onClickPlayers?: () => void,
  onClickHoles?: () => void,
  onClickTransitOption?: () => void,
  onClickPaymentOption?: () => void,
  onClickDate?: () => void,
  onRangeStartChange?: () => void,
  onRangeEndChange?: () => void,
}) {
  const playerOptions = useMemo(() => {
    return [
      getOffsetByPlayer({
        fullCourseMetadata,
        players: 1,
      }),
      getOffsetByPlayer({
        fullCourseMetadata,
        players: 2,
      }),
      getOffsetByPlayer({
        fullCourseMetadata,
        players: 3,
      }),
      getOffsetByPlayer({
        fullCourseMetadata,
        players: 4,
      }),
    ]
      .map(({ canSchedule }, index) => {
        const players = index + 1;
        return {
          displayData: {
            value: players as 1 | 2 | 3 | 4,
            label: players.toString(),
          },
          canSchedule,
        };
      })
      .filter(({ canSchedule }) => canSchedule)
      .map(({ displayData }) => displayData);
  }, [fullCourseMetadata]);

  const playerControl = useControl({
    value: scheduleTeeTimeBase?.players ?? null,
    onChange: (event: SelectChangeEvent<null | 1 | 2 | 3 | 4>) => {
      if (rangeStartControl.value === null) {
      }

      const value = event.target.value;
      if (value === null) {
        return null;
      }

      return Number(event.target.value) as 1 | 2 | 3 | 4;
    },
    validatorError: (players: null | 1 | 2 | 3 | 4) => {
      return new NumberValidator()
        .required(true, "The number of players is required")
        .validate(players);
    },
    onChangeSideEffect: onClickPlayers,
  });

  const initialDateValue = scheduleTeeTimeBase?.rangeStartInclusive
    ? DateTime.fromISO(scheduleTeeTimeBase?.rangeStartInclusive)
    : null;
  const dateControl = useControl({
    value: initialDateValue,
    onChange: (value: DateTime<boolean> | null) => {
      return value;
    },
    validatorError: (value: DateTime<boolean> | null) => {
      if (value === null) {
        return "This field is required";
      }

      if (playerControl.value === null) {
        return "";
      }

      const startOfFirstAvailableDay = firstAvailableMoment({
        players: playerControl.value,
        simpleCourse: fullCourseMetadata,
      }).startOf("day").toUTC();

      const startOfSelectedDay = value.startOf("day").toUTC();
      if (isFullTestRun && startOfSelectedDay <= startOfFirstAvailableDay) {
        return "For the test run, the selected date must be an already available date";
      }
      if (!isFullTestRun && !allowBookNow && startOfSelectedDay < startOfFirstAvailableDay) {
        return "The selected date is not available for this number of players";
      }

      return "";
    },
    validationDependencies: [playerControl],
    onChangeSideEffect: onClickDate,
  });

  const initialRangeStartDate = scheduleTeeTimeBase?.rangeStartInclusive
    ? DateTime.fromISO(scheduleTeeTimeBase?.rangeStartInclusive)
    : DateTime.now().set({
      // 10 am
      hour: 10,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
  const rangeStartControl = useControl({
    value: initialRangeStartDate,
    onChange: (value: DateTime<boolean>) => {
      return value;
    },
    onChangeSideEffect: onRangeStartChange,
  });

  const initialRangeEndDate = scheduleTeeTimeBase?.rangeEndInclusive
    ? DateTime.fromISO(scheduleTeeTimeBase?.rangeEndInclusive)
    : DateTime.now().set({
      // 2 pm
      hour: 14,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
  const rangeEndControl = useControl({
    // 7 pm
    value: initialRangeEndDate,
    onChange: (value: DateTime<boolean>) => {
      return value;
    },
    validatorError: (value: DateTime<boolean>) => {
      if (value.toUTC() <= rangeStartControl.value.toUTC()) {
        return "Invalid range end";
      }

      return "";
    },
    validationDependencies: [rangeStartControl],
    onChangeSideEffect: onRangeEndChange,
  });

  const fiveAM = DateTime.now().set({
    // 5 am
    hour: 5,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
  const eightPM = DateTime.now().set({
    // 8 pm
    hour: 20,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
  const onChangeRangeWithSlider = (_: Event, value: number | number[], activeThumb: number) => {
    if (!Array.isArray(value)) {
      return;
    }

    // one hour min distance
    const minDistance = 60 * 60;
    const [rangeStartTimeInSeconds, rangeEndTimeInSeconds] = value;


    if (rangeEndTimeInSeconds - rangeStartTimeInSeconds < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(rangeStartTimeInSeconds, eightPM.toSeconds() - minDistance);
        rangeStartControl.onChange(
          DateTime.fromSeconds(clamped)
        );
        rangeEndControl.onChange(
          DateTime.fromSeconds(clamped + minDistance),
        );
      } else {
        const clamped = Math.max(rangeEndTimeInSeconds, fiveAM.toSeconds() + minDistance);
        rangeStartControl.onChange(
          DateTime.fromSeconds(clamped - minDistance)
        );
        rangeEndControl.onChange(
          DateTime.fromSeconds(clamped),
        );
      }
    } else {
      rangeStartControl.onChange(
        DateTime.fromSeconds(rangeStartTimeInSeconds),
      );
      rangeEndControl.onChange(
        DateTime.fromSeconds(rangeEndTimeInSeconds),
      );
    }
  }

  function shouldDisableDate(day: DateTime<boolean>): boolean {
    if (disabled) {
      return false;
    }

    if (playerControl.value === null) {
      return true;
    }

    const firstAvailableMomentDateTime = firstAvailableMoment({
      simpleCourse: fullCourseMetadata,
      players: playerControl.value,
    });

    const startOfFirstAvailableDay = firstAvailableMomentDateTime.startOf("day");
    const firstMomentOfToday = DateTime.now().startOf("day");
    const startOfDay = day.startOf("day");

    const isInPast = startOfDay.toUTC() < firstMomentOfToday.toUTC();
    if (isFullTestRun) {
      return startOfDay.toUTC() >= startOfFirstAvailableDay || isInPast;
    } else if (!allowBookNow) {
      return startOfDay.toUTC() < startOfFirstAvailableDay.toUTC() || isInPast;
    } else {
      return isInPast;
    }
  }

  const dateHelperText = (function () {
    if (isFullTestRun) {
      return "Disabled dates are not yet available for full tests.";
    } else if (allowBookNow) {
      return "";
    } else {
      return "Disabled dates are already available on the course website.";
    }
  })();

  const teeTimeHoleOptions = useMemo(() => {
    const options: IWrappedSelectOption[] = [];
    function addNineHoles() {
      options.push({
        value: TeeTimeHoleOption.Nine,
        label: "9",
      });
    }
    function add18Holes() {
      options.push({
        value: TeeTimeHoleOption.Eighteen,
        label: "18",
      });
    }

    if (!fullCourseMetadata) {
      addNineHoles();
      add18Holes();
      return options;
    }

    const courseAllowsBoth =
      fullCourseMetadata.teeTimeHoleOption === TeeTimeHoleOption.Both;
    const courseAllowsNine =
      fullCourseMetadata.teeTimeHoleOption === TeeTimeHoleOption.Nine;
    const courseAllowsEighteen =
      fullCourseMetadata.teeTimeHoleOption === TeeTimeHoleOption.Eighteen;
    if (courseAllowsBoth || courseAllowsNine) {
      addNineHoles();
    }

    if (courseAllowsBoth || courseAllowsEighteen) {
      add18Holes();
    }

    return options;
  }, [fullCourseMetadata]);
  const teeTimeHoleControl = useControl({
    value: scheduleTeeTimeBase?.holes ?? null,
    onChange: (event: SelectChangeEvent<null | TeeTimeHoleOption>) => {
      return event.target.value as null | TeeTimeHoleOption;
    },
    validatorError: (teeTimeHoleOption: null | TeeTimeHoleOption) => {
      if (teeTimeHoleOption === null) {
        return "This is a required field";
      }

      return "";
    },
    onChangeSideEffect: onClickHoles,
  });

  const courseTransitOptions: IWrappedSelectOption[] = [
    {
      label: "Walk",
      value: CourseTransitOption.Walking,
    },
    {
      label: "Cart",
      value: CourseTransitOption.Cart,
    }
  ];
  const courseTransitControl = useControl({
    value: scheduleTeeTimeBase?.transitOption ?? null,
    onChange: (event: SelectChangeEvent<null | CourseTransitOption>) => {
      return event.target.value as null | CourseTransitOption;
    },
    validatorError: (courseTransitOption: null | CourseTransitOption) => {
      if (courseTransitOption === null) {
        return "This is a required field";
      }

      return "";
    },
    onChangeSideEffect: onClickTransitOption,
  });

  const paymentOptions: IWrappedSelectOption[] = [{
    value: CoursePaymentOption.InPerson,
    label: "Pay At The Course",
  }, {
    value: CoursePaymentOption.Online,
    label: "Pay Online",
  }];
  const paymentOptionControl = useControl({
    value: scheduleTeeTimeBase?.paymentOption ?? null,
    onChange: (event: SelectChangeEvent<CoursePaymentOption | null>) => {
      return event.target.value as CoursePaymentOption | null;
    },
    validatorError: (courseTransitOption: CoursePaymentOption | null) => {
      if (courseTransitOption === null) {
        return "This is a required field";
      }

      return "";
    },
    onChangeSideEffect: onClickPaymentOption,
  });

  const usernameControl = useControl({
    value: scheduleTeeTimeBase?.login ?? "",
    onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      return event.target.value;
    },
    validatorError: (username: string) => {
      const stringValidator = new StringValidator();
      if (requireUsernameAndPassword) {
        return stringValidator.required("").validate(username);
      }

      return stringValidator.validate(username);
    },
  });

  const passwordControl = useControl({
    value: scheduleTeeTimeBase?.password ?? "",
    onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      return event.target.value;
    },
    validatorError: (password: string) => {
      const stringValidator = new StringValidator();
      if (requireUsernameAndPassword) {
        return stringValidator.required("").validate(password);
      }

      return stringValidator.validate(password);
    },
  });

  useEffect(() => {
    // If the schedule tee time base changes we may need to override whats
    // been previously set for the username and password. This happens when
    // we query for credentials.

    if (scheduleTeeTimeBase?.password !== undefined) {
      passwordControl.resetControlState(scheduleTeeTimeBase?.password);
    }

    if (scheduleTeeTimeBase?.login !== undefined) {
      usernameControl.resetControlState(scheduleTeeTimeBase?.login);
    }
  }, [scheduleTeeTimeBase]);

  const [runWithDevelopmentScript, setRunWithDevelopmentScript] = useState(!!didUseDevelopmentTestScript);
  const onChangeRunWithDevelopmentScript: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void = (
    _unused,
    checked,
  ) => {
    setRunWithDevelopmentScript(checked);
  }

  const canSubmitForm = allControlsAreValid(
    playerControl,
    dateControl,
    rangeStartControl,
    rangeEndControl,
    teeTimeHoleControl,
    courseTransitControl,
    paymentOptionControl,
    usernameControl,
    passwordControl,
  );

  function onClickSubmitInternal() {
    if (!fullCourseMetadata) {
      return;
    }

    const { day, month, year } = dateControl.value as DateTime<boolean>;

    const { minute: rangeStartInclusiveMinute, hour: rangeStartInclusiveHour } =
      rangeStartControl.value as DateTime<boolean>;

    const { minute: rangeEndInclusiveMinute, hour: rangeEndInclusiveHour } =
      rangeEndControl.value as DateTime<boolean>;

    const players = playerControl.value as 1 | 2 | 3 | 4;

    const scheduleTeeTimeRequest: ScheduleTeeTimeCreateRequest = {
      day,
      month,
      year,
      rangeStartInclusive: {
        minute: rangeStartInclusiveMinute,
        hour: rangeStartInclusiveHour,
      },
      rangeEndInclusive: {
        minute: rangeEndInclusiveMinute,
        hour: rangeEndInclusiveHour,
      },
      players,
      holes: teeTimeHoleControl.value as TeeTimeHoleOption,
      transitOption: courseTransitControl.value as CourseTransitOption,
      paymentOption: paymentOptionControl.value as CoursePaymentOption,
      coursePK: fullCourseMetadata.PK,
      runWithDevelopmentScript,
    };

    if (usernameControl.value && passwordControl.value) {
      scheduleTeeTimeRequest.login = usernameControl.value;
      scheduleTeeTimeRequest.password = passwordControl.value;
    }

    if (onClickSubmitForm) {
      onClickSubmitForm(scheduleTeeTimeRequest);
    }
  }

  return {
    onChangeRangeWithSlider,
    fiveAM,
    eightPM,
    playerOptions,
    playerControl,
    rangeStartControl,
    rangeEndControl,
    dateControl,
    shouldDisableDate,
    dateHelperText,
    teeTimeHoleControl,
    teeTimeHoleOptions,
    courseTransitOptions,
    courseTransitControl,
    paymentOptions,
    paymentOptionControl,
    canSubmitForm,
    onClickSubmitInternal,
    usernameControl,
    passwordControl,
    runWithDevelopmentScript,
    onChangeRunWithDevelopmentScript,
  };
}
