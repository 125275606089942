import { Box, IconButton, List, ListItem, ListItemButton, Typography } from "@mui/material";
import BoxWithBorder from "../../../components/boxWithBorder";
import WrappedTextField from "../../../components/wrappedTextField";
import { SimpleCourse } from "../../../types";
import { useCourseSelector } from "./useCourseSelector";
import CloseIcon from "@mui/icons-material/Close";

interface CourseSelectorProps {
  courses: SimpleCourse[];
  onClickCourse?: (course: SimpleCourse) => void;
  onClearCourse?: () => void;
  activeCoursePK?: string;
  showFiltering?: boolean;
  autofocus?: boolean,
}

function CourseSelector({
  courses,
  onClickCourse,
  activeCoursePK,
  showFiltering,
  onClearCourse,
  autofocus,
}: CourseSelectorProps) {
  const {
    filteredCourses,
    searchControl,
    activeCourse,
  } = useCourseSelector(courses, activeCoursePK);

  function createListItemFromSimpleCourse(course: SimpleCourse) {
    const selected = activeCoursePK === course.PK;
    const onClick = !!onClickCourse ? () => onClickCourse(course) : undefined;
    return (
      <ListItem
        key={course.PK}
        disablePadding
        onClick={onClick}
        sx={{
          paddingTop: 0,
          paddingBottom: 0,
        }}
      >
        <ListItemButton selected={selected}>
          <Box sx={{
            display: "flex",
            flexDirection: "column",
          }}>
            <Typography><strong>{course.name}</strong></Typography>
            <Typography variant="caption">{course.city}, {course.state}</Typography>
          </Box>
        </ListItemButton>
      </ListItem>
    );
  }

  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      gap: 1,
      height: "100%",
    }}>
      {!!activeCourse ? (
        <Box sx={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
          alignItems: "center",
          paddingLeft: 2,
        }}>
          <Box sx={{
            display: "flex",
            flexDirection: "column",
          }}>
            <Typography><strong>{activeCourse.name}</strong></Typography>
            <Typography variant="caption">{activeCourse.city}, {activeCourse.state}</Typography>
          </Box>

          <IconButton onClick={onClearCourse}>
            <CloseIcon />
          </IconButton>
        </Box>
      ) : (
        <>
          {!!showFiltering && (
            <Box sx={{
              flexBasis: "auto",
              flexGrow: 0,
              flexShrink: 0,
            }}>
              <WrappedTextField
                value={searchControl.value}
                label="Search Name, City, or State"
                onChange={searchControl.onChange}
                error={searchControl.hasError}
                helperText={searchControl.shownErrorMessage}
                autoFocus={autofocus}
              />
            </Box>
          )}
          {!!filteredCourses.length ? (
            <BoxWithBorder
              sx={{
                flexGrow: 1,
                padding: 0,
                overflow: "auto",
              }}
            >
              <List>
                {filteredCourses.map((course) => {
                  return createListItemFromSimpleCourse(course);
                })}
              </List>
            </BoxWithBorder>
          ) : (
            <Typography
              variant="h6"
              sx={{
                textAlign: "center",
                paddingTop: 2,
              }}
            >
              No courses pass the filter
            </Typography>
          )}
        </>
      )}
    </Box>
  );
}

export default CourseSelector;
