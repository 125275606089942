import { Api } from "../../../api";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FullCourseMetadata, FullCourseTestRun, Status } from "../../../types";
import { DateTime } from "luxon";

export function useFullTest() {
  const { fullTestPK } = useParams();
  const PK = `FULL_COURSE_TEST_RUN#${fullTestPK}`;

  const [fullTestRun, setFullTestRun] = useState<null | FullCourseTestRun>(
    null,
  );
  const [course, setCourse] = useState<null | FullCourseMetadata>(null);
  useEffect(() => {
    // This effect is intended to only run once. However the getFullTest
    // function will run every 10 seconds until the full test is complete.

    let didCancel = false;
    const now = DateTime.now();
    const fiveMinutesFromNow = DateTime.now().plus({ minutes: 5 });

    function getFullTest() {
      Api.onDemandTestRun.getFullTest(PK).then((fullTestRunFromData) => {
        if (didCancel) {
          return;
        }

        setFullTestRun(fullTestRunFromData);
        const updatedTime = DateTime.now();
        // The max time it will refresh is 5 minutes.
        if (updatedTime > fiveMinutesFromNow) {
          return;
        }

        // If the status is success or error, we don't need to keep refreshing
        if (fullTestRunFromData.status !== Status.PENDING) {
          return;
        }

        // Otherwise try again in 10 seconds.
        setTimeout(() => {
          getFullTest();
        }, 10000);
      });
    }

    getFullTest();

    return () => {
      didCancel = true;
    };
  }, []);

  useEffect(() => {
    if (fullTestRun === null) {
      return;
    }

    let didCancel = false;

    Api.course.getCourse(fullTestRun.coursePK).then((courseFromData) => {
      if (didCancel) {
        return;
      }

      setCourse(courseFromData);
    });

    return () => {
      didCancel = true;
    };
  }, [fullTestRun]);
  const bookingTimeDisplay = fullTestRun?.bookingAttemptData?.time ? `${DateTime.fromISO(fullTestRun.bookingAttemptData.time).setZone(fullTestRun.teeTime.timezone).toLocaleString(DateTime.TIME_SIMPLE)}` : "";

  return {
    course,
    fullTestRun,
    isLoading: course === null || fullTestRun === null,
    bookingTimeDisplay,
  };
}
