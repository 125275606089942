import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import { useState } from "react";

interface IRouteData {
  text: string;
  route: string;
}

function Admin() {
  const navigate = useNavigate();

  const [topRoutes] = useState<IRouteData[]>(() => {
    return [
      {
        text: "Courses",
        route: "/app/admin/courses",
      },
      {
        text: "Full Tests",
        route: "/app/admin/full-tests",
      },
      {
        text: "Scheduled Tee Times",
        route: "/app/admin/scheduled-tee-times",
      },
      {
        text: "Users",
        route: "/app/admin/users",
      },
    ];
  });

  const [bottomRoutes] = useState<IRouteData[]>(() => {
    return [
      {
        text: "User Portal",
        route: "/app/user/tee-times",
      },
    ];
  });

  function onClickListItem(route: string) {
    return () => {
      navigate(route);
    };
  }

  const drawerWidth = 250;
  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      <Box
        sx={{
          width: drawerWidth,
        }}
      >
        <Drawer
          open
          variant="permanent"
          sx={{
            flex: "0 0 auto",
            height: "100vh",
          }}
        >
          <Box
            sx={{
              width: drawerWidth,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100vh",
            }}
            role="presentation"
          >
            <Box
              sx={{
                flexGrow: "0 0 auto",
              }}
            >
              <List>
                {topRoutes.map(({ text, route }) => (
                  <ListItem
                    key={text}
                    disablePadding
                    onClick={onClickListItem(route)}
                  >
                    <ListItemButton>
                      <ListItemText primary={text} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Box>
            <Box
              sx={{
                flexGrow: "0 0 auto",
              }}
            >
              <List>
                {bottomRoutes.map(({ text, route }) => (
                  <ListItem
                    key={text}
                    disablePadding
                    onClick={onClickListItem(route)}
                  >
                    <ListItemButton>
                      <ListItemText primary={text} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Box>
          </Box>
        </Drawer>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
}

export default Admin;
