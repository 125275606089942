import axios from "axios";
import { environmentVariables } from "../environmentVariables";
import { SavedUsernameAndPassword } from "../types";

export interface ICredentialsAPI {
    getCredentialsForUser(): Promise<{
        Items: SavedUsernameAndPassword[];
        Count: number;
    }>
}

export class CredentialsAPI implements ICredentialsAPI {
    public async getCredentialsForUser() {
        const axiosResponse = await axios.get<{
            Items: SavedUsernameAndPassword[];
            Count: number;
        }>(`${environmentVariables.baseAuthenticatedApiUrl}/credentials`);

        return axiosResponse.data;
    }
}