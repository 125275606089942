import { Status as StatusType } from "../../types";

export function colorFromStatus(status: StatusType) {
    if (status === StatusType.PENDING) {
        return "warning";
    }

    if (status === StatusType.ERROR) {
        return "error";
    }

    if (status === StatusType.SUCCESS) {
        return "success";
    }
}