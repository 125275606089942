import { Box, FormControlLabel, Switch, Typography } from "@mui/material";
import WrappedTextField from "../../../../../../components/wrappedTextField";
import { useSharedSchedulingOffset } from "./useSharedSchedulingOffset";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { CourseSchedulingOffset } from "../../../../../../types";

interface ISharedSchedulingOffsetProps {
  isAll?: boolean;
  schedulingOffset: CourseSchedulingOffset;
  onChangeSchedulingOffset: (
    courseSchedulingOffset: CourseSchedulingOffset,
  ) => void;
}

function SharedSchedulingOffset({
  isAll,
  schedulingOffset,
  onChangeSchedulingOffset,
}: ISharedSchedulingOffsetProps) {
  const {
    canSchedule,
    onChange,
    daysOffsetControl,
    timeControl,
    nextAvailableDay,
    timeDisplay,
  } = useSharedSchedulingOffset(schedulingOffset, onChangeSchedulingOffset);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 1,
        }}
      >
        {!isAll && (
          <FormControlLabel
            control={
              <Switch
                checked={canSchedule}
                onChange={onChange}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label="Can Schedule"
          />
        )}
        {canSchedule && (
          <>
            <Box>
              <WrappedTextField
                label="Days Offset"
                type="number"
                onChange={daysOffsetControl.onChange}
                error={daysOffsetControl.hasError}
                helperText={daysOffsetControl.shownErrorMessage}
                value={daysOffsetControl.value}
              />
            </Box>
            <Box>
              <TimePicker
                label="First Available Moment"
                value={timeControl.value}
                onChange={timeControl.onChange}
                minutesStep={30}
              />
            </Box>
          </>
        )}
      </Box>
      {canSchedule && (
        <Typography>
          At {timeDisplay} today, tee times became available for{" "}
          {nextAvailableDay}.
        </Typography>
      )}
    </Box>
  );
}

export default SharedSchedulingOffset;
