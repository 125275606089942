import { useEffect, useState } from "react";
import { Api } from "../../../../../../api";
import { ScheduleTeeTimeBase } from "../../../../../../types";

export function useDeleteTeeTimeDialog(
  teeTime: ScheduleTeeTimeBase,
  onDeleteSuccess: (PK: string) => void,
  onClose: () => void,
) {
  const [isDeletingTeeTime, setIsDeletingTeeTime] = useState(false);
  function onClickDelete() {
    setIsDeletingTeeTime(true);
  }
  useEffect(() => {
    if (!isDeletingTeeTime) {
      return;
    }

    let didCancel = false;

    Api.scheduledTeeTime
      .deleteScheduleTeeTime(teeTime.PK, teeTime.SK)
      .then(() => {
        if (didCancel) {
          return;
        }

        onDeleteSuccess(teeTime.PK);
        onClose();
      })
      .catch(() => {
        if (didCancel) {
          return;
        }
      })
      .finally(() => {
        if (didCancel) {
          return;
        }

        setIsDeletingTeeTime(false);
      });

    return () => {
      didCancel = true;
    };
  }, [isDeletingTeeTime]);

  return {
    onClickDelete,
    isDeletingTeeTime,
  };
}
