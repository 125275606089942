import { Box, Typography } from "@mui/material";
import { RouteStep } from "../../../../../types";

interface IDisplayNavigationStepProps {
  step: RouteStep;
}

function DisplayNavigationStep({ step }: IDisplayNavigationStepProps) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Typography>Navigate to {step.metadata?.url}</Typography>
    </Box>
  );
}

export default DisplayNavigationStep;
