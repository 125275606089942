import axios from "axios";
import { environmentVariables } from "../environmentVariables";
import {
  FullCourseTestRun,
  ScheduleTeeTimeCreateRequest,
} from "../types";

export interface IOnDemandTestRunAPI {
  createFullTest(
    scheduleTeeTimeCreateRequest: ScheduleTeeTimeCreateRequest,
  ): Promise<FullCourseTestRun>;
  getFullTest(testRunPK: string): Promise<FullCourseTestRun>;
  getAllFullTestRuns(): Promise<{
    Items: FullCourseTestRun[];
    Count: number;
  }>;
}

export class OnDemandTestRunAPI implements IOnDemandTestRunAPI {
  public async createFullTest(
    scheduleTeeTimeCreateRequest: ScheduleTeeTimeCreateRequest,
  ): Promise<FullCourseTestRun> {
    const axiosResponse = await axios.post<FullCourseTestRun>(
      `${environmentVariables.baseAuthenticatedApiUrl}/full-test-run`,
      scheduleTeeTimeCreateRequest,
    );

    return axiosResponse.data;
  }

  public async getFullTest(testRunPK: string): Promise<FullCourseTestRun> {
    const axiosResponse = await axios.get<FullCourseTestRun>(
      `${environmentVariables.baseAuthenticatedApiUrl}/full-test`,
      {
        params: {
          PK: testRunPK,
        },
      },
    );

    return axiosResponse.data;
  }

  public async getAllFullTestRuns() {
    const axiosResponse = await axios.get<{
      Items: FullCourseTestRun[];
      Count: number;
    }>(`${environmentVariables.baseAuthenticatedApiUrl}/full-test-runs`);

    return axiosResponse.data;
  }
}
