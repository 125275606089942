import { ChangeEvent, useEffect, useState } from "react";
import { StringValidator } from "../../classes/stringValidator";
import { useControl } from "../../hooks/useControl";
import * as AWSCognitoIdentity from "amazon-cognito-identity-js";
import * as AWS from "aws-sdk/global";
import { cognitoUserSingleton } from "../../classes/cognitoUserSingleton";
import { userPool } from "../../classes/userPool";
import { useNavigate } from "react-router-dom";

export function useLogin() {
  const passwordControl = useControl({
    value: "",
    onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      return event.target.value;
    },
    validatorError: (name: string) => {
      return new StringValidator()
        .min(8, "A password must be at least 8 characters")
        .required("A password is required")
        .validate(name);
    },
  });

  const emailControl = useControl({
    value: "",
    onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      return event.target.value;
    },
    validatorError: (name: string) => {
      return new StringValidator()
        .email()
        .required("An email is required")
        .validate(name);
    },
  });

  const formIsValid = emailControl.isValid && passwordControl.isValid;

  const navigate = useNavigate();
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  function clearErrorMessage() {
    setErrorMessage("");
  }
  useEffect(() => {
    if (!isSigningIn) return;

    let didCancel = false;

    const authenticationData = {
      Username: emailControl.value,
      Password: passwordControl.value,
    };
    const authenticationDetails = new AWSCognitoIdentity.AuthenticationDetails(
      authenticationData,
    );

    const userData = {
      Username: emailControl.value,
      Pool: userPool,
    };
    cognitoUserSingleton.cognitoUser = new AWSCognitoIdentity.CognitoUser(
      userData,
    );
    cognitoUserSingleton.cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: function (result) {
        if (didCancel) return;

        AWS.config.region = "us-west-2";
        const token = result.getIdToken().getJwtToken();

        localStorage.setItem("token", token);
        localStorage.setItem("userEmail", emailControl.value);
        navigate("/app/user/tee-times");
      },
      onFailure: function (err) {
        if (didCancel) return;
        setIsSigningIn(false);

        console.log("err: ", err);

        if (!err || !err.code) return;

        if (err.code === "UserNotConfirmedException") {
          setErrorMessage("Your email needs to be verified by clicking the link in the confirmation email.")
        }

        if (err.code === "NotAuthorizedException" || err.code === "UserNotFoundException") {
          setErrorMessage("Incorrect username or password");
        }
      },
      newPasswordRequired: (userAttributes, requiredAttributes) => {
        console.log("new password required");
        console.log("user attributes: ", userAttributes);
        console.log("required attributes: ", requiredAttributes);
      },
    });

    return () => {
      didCancel = true;
    };
  }, [isSigningIn]);

  function onClickLogIn() {
    setIsSigningIn(true);
  }

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    setIsSigningIn(true);
  }

  function onClickResetPassword() {
    navigate("/reset-password");
  }

  return {
    emailControl,
    passwordControl,
    formIsValid,
    isSigningIn,
    onClickLogIn,
    onClickResetPassword,
    onSubmit,
    errorMessage,
    clearErrorMessage,
  };
}
