import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import { AppHeaderWithScrollableContent } from "../../components/appHeaderWithScrollableContent";
import WrappedButton from "../../components/wrappedButton";
import AccountIcon from "./accountIcon";
import { useUser } from "./useUser";

export function User() {
  const {
    isAdmin,
    goToAdminPortal,
    onClickNewTeeTime,
    onClickSubmitForm,
    actionInProgress,
    showAdminButton,
    isOnTeeTimesPage,
    onClickBackToTeeTimeList,
    isLoadingCourses,
  } = useUser();

  return (
    <AppHeaderWithScrollableContent leftHeaderContent={(
      <Box
        sx={{
          display: "flex",
          gap: 1,
        }}
      >
        {isAdmin && showAdminButton && (
          <WrappedButton
            onClick={goToAdminPortal}
            color="primary"
            variant="text"
          >
            Go To Admin Portal
          </WrappedButton>
        )}
        {isOnTeeTimesPage ? (
          <WrappedButton variant="contained" onClick={onClickNewTeeTime} disabled={isLoadingCourses}>Book Tee Time</WrappedButton>
        ) : (
          <WrappedButton onClick={onClickBackToTeeTimeList} disabled={isLoadingCourses}>Back to Tee Time List</WrappedButton>
        )}
      </Box>
    )} rightHeaderContent={(
      <AccountIcon />
    )}>
      <Outlet />
    </AppHeaderWithScrollableContent>
  )
}
