import { Api } from "../../../api";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { FullCourseMetadata, ScheduleTeeTimeBase, ScheduleTeeTimeLogs, Status } from "../../../types";
import { DateTime } from "luxon";

export function useScheduledTeeTime() {
  const { scheduledTeeTimePK } = useParams();
  const PK = `SCHEDULED_TEE_TIME#${scheduledTeeTimePK}`;

  const [scheduledTeeTime, setScheduledTeeTime] =
    useState<null | ScheduleTeeTimeBase>(null);
  const [course, setCourse] = useState<null | FullCourseMetadata>(null);
  useEffect(() => {
    if (scheduledTeeTime !== null) {
      return;
    }

    let didCancel = false;

    Api.scheduledTeeTime
      .getScheduledTeeTime(PK)
      .then((scheduledTeeTimeFromData) => {
        if (didCancel) {
          return;
        }

        setScheduledTeeTime(scheduledTeeTimeFromData);
      });

    return () => {
      didCancel = true;
    };
  }, [scheduledTeeTime]);

  const isLoadingLogs = scheduledTeeTime !== null && scheduledTeeTime.status !== Status.PENDING;
  const [logs, setLogs] = useState<ScheduleTeeTimeLogs | null>(null);
  useEffect(() => {
    if (!isLoadingLogs) {
      return;
    }

    let didCancel = false;

    const scheduledTeeTimeLogsPK = `SCHEDULE_TEE_TIME_LOG#${scheduledTeeTimePK}`;
    Api.scheduledTeeTime.getScheduledTeeTimeLogs(scheduledTeeTimeLogsPK).then((logs) => {
      if (didCancel) {
        return;
      }

      setLogs(logs);
    });

    return () => {
      didCancel = true;
    }
  }, [isLoadingLogs]);

  useEffect(() => {
    if (scheduledTeeTime === null) {
      return;
    }

    let didCancel = false;

    Api.course.getCourse(scheduledTeeTime.coursePK).then((courseFromData) => {
      if (didCancel) {
        return;
      }

      setCourse(courseFromData);
    });

    return () => {
      didCancel = true;
    };
  }, [scheduledTeeTime]);

  const imageSliderProps = useMemo(() => {
    return [{
      folderKey: PK,
      headerText: "Select Best Tee Time",
    }]
  }, [scheduledTeeTime]);
  const timeSlots = scheduledTeeTime?.timeSlots;
  const bookingTimeDisplay = scheduledTeeTime?.bookedTime ? `${DateTime.fromISO(scheduledTeeTime.bookedTime).setZone(scheduledTeeTime.timezone).toLocaleString(DateTime.TIME_SIMPLE)}` : "";
  const orText = !!scheduledTeeTime?.backupTeeTimes?.length ? ` or ${scheduledTeeTime.backupTeeTimes[0].courseName}` : "";

  return {
    course,
    scheduledTeeTime,
    isLoading: course === null || scheduledTeeTime === null,
    imageSliderProps,
    logs,
    timeSlots,
    bookingTimeDisplay,
    orText,
  };
}
