import { Box, Typography } from "@mui/material";

interface DataDescriptorProps {
  descriptor: string;
  text: string;
}

function DataDescriptor({ descriptor, text }: DataDescriptorProps) {
  return (
    <Box>
      <Typography
        component="span"
      >
        <strong>
          {descriptor}:{" "}
        </strong>
      </Typography>
      <Typography component="span">{text}</Typography>
    </Box>
  );
}

export default DataDescriptor;
