import { orderBy } from "lodash";
import { DateTime } from "luxon";
import { useState, useEffect, useCallback } from "react";
import { Api } from "../../../api";
import { ScheduleTeeTimeBase } from "../../../types";

export function useAllScheduledTeeTimes() {
  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState<ScheduleTeeTimeBase[]>([]);
  const onDeleteSuccess = useCallback(
    (PK: string) => {
      setItems((previousItems) => {
        return previousItems.filter((teeTime) => {
          return teeTime.PK !== PK;
        });
      });
    },
    [items, setItems],
  );
  useEffect(() => {
    if (!isLoading) {
      return;
    }

    let didCancel = false;

    Api.scheduledTeeTime
      .getAllScheduledTeeTimes()
      .then(function ({ Items }) {
        if (didCancel) {
          return;
        }

        setItems(Items);
      })
      .catch(function (error) {
        if (didCancel) {
          return;
        }
        // handle error
        console.log(error);
      })
      .finally(function () {
        if (didCancel) {
          return;
        }

        setIsLoading(false);
      });

    return () => {
      didCancel = true;
    };
  }, [isLoading]);

  return {
    items,
    isLoading,
    onDeleteSuccess,
  };
}
