import { Box, Typography } from "@mui/material";
import { ChangeEvent } from "react";
import BoxWithBorder from "../../../../../components/boxWithBorder";
import WrappedButton from "../../../../../components/wrappedButton";
import WrappedTextField from "../../../../../components/wrappedTextField";

interface IChronoCourseOptionsProps {
    courseOptions: string[],
    onChange: (index: number) => (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => string,
    onRemove: (index: number) => () => void
    onAdd: () => void,
}

function ChronoCourseOptions({
    courseOptions,
    onChange,
    onRemove,
    onAdd,
}: IChronoCourseOptionsProps) {
    const ignoreCourseOptions = courseOptions.every((option) => !option.trim());
    return (
        <BoxWithBorder sx={{
            display: "flex",
            flexDirection: "column",
            padding: 0,
            gap: 0,
        }}>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                padding: 2,
                gap: 2,
            }}>
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 2,
                }}>
                    <Typography variant="h6">Chrono Course Filter</Typography>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                    }}>
                        <WrappedButton onClick={onAdd}>Add Option</WrappedButton>
                    </Box>
                </Box>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                }}>
                    {courseOptions.map((courseOption, index) => {
                        return (
                            <Box sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: 2,
                            }}>
                                <Box sx={{
                                    width: "200px",
                                }}>
                                    <WrappedTextField
                                        value={courseOption}
                                        onChange={onChange(index)}
                                    />
                                </Box>
                                <Box sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                }}>
                                    <WrappedButton onClick={onRemove(index)} color="error" >Remove Option</WrappedButton>
                                </Box>
                            </Box>
                        )
                    })}
                </Box>
            </Box>
        </BoxWithBorder>
    )
}

export default ChronoCourseOptions;