import { useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import { HttpErrorCode } from "../../types";

/**
 * Automatically add the auth token to each request. When you receive an unauthorized status,
 * navigate back to the login page.
 */
export const useAxiosInterceptor = () => {
  const navigate = useNavigate();
  useLayoutEffect(() => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    Axios.interceptors.response.use(undefined, function (error) {
      if (error.response.status === HttpErrorCode.Unauthoried) {
        navigate("/login");
      }

      return Promise.reject(error);
    });

    Axios.interceptors.request.use(async (config) => {
      config.headers["AuthHeader"] = localStorage.getItem("token") || "";

      return Promise.resolve(config);
    });
  }, []);
};
