import { cloneDeep, isEqual } from "lodash";
import { useState } from "react";
import { CourseSchedulingOffset, OffsetByPlayers } from "../../../../../types";

export function useSchedulingOffset(
  onChangeSchedulingOffset: (offsetByPlayers: OffsetByPlayers) => void,
  schedulingOffset: OffsetByPlayers,
) {
  const [isCustom, setIsCustom] = useState(() => {
    return (
      !isEqual(schedulingOffset[1], schedulingOffset[2]) ||
      !isEqual(schedulingOffset[2], schedulingOffset[3]) ||
      !isEqual(schedulingOffset[3], schedulingOffset[4])
    );
  });
  function onChangeIsCustom(
    _event: React.MouseEvent<HTMLElement, MouseEvent>,
    isCustom: boolean,
  ) {
    setIsCustom(isCustom);
    if (!isCustom) {
      onChangeAllSchedulingOffset(schedulingOffset[1]);
    }
  }

  function onChangeAllSchedulingOffset(
    courseSchedulingOffset: CourseSchedulingOffset,
  ) {
    const offsetByPlayers: OffsetByPlayers = {
      1: cloneDeep(courseSchedulingOffset),
      2: cloneDeep(courseSchedulingOffset),
      3: cloneDeep(courseSchedulingOffset),
      4: cloneDeep(courseSchedulingOffset),
    };

    onChangeSchedulingOffset(offsetByPlayers);
  }

  return {
    isCustom,
    onChangeIsCustom,
    onChangeAllSchedulingOffset,
  };
}
