import { Box, Typography } from "@mui/material";

function DisplayTeeItUpTeeTimeStep() {
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
            }}
        >
            <Typography>Schedule Tee It Up tee time</Typography>
        </Box>
    );
}

export default DisplayTeeItUpTeeTimeStep;
