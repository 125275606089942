import { Box, Link, Typography } from "@mui/material";
import WrappedTextField from "../../../components/wrappedTextField";
import { IControl } from "../../../hooks/useControl";
import { SimpleCourse, TeeTimeSchedulingSoftware } from "../../../types";

interface CourseCredentialsStepProps {
    passwordControl: IControl<string, React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>>,
    usernameControl: IControl<string, React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>>,
    simpleCourse?: SimpleCourse,
}

function CourseCredentialsStep({
    passwordControl,
    usernameControl,
    simpleCourse,
}: CourseCredentialsStepProps) {
    const credentialsContent = (function () {
        if (simpleCourse?.teeTimeSchedulingSoftware === TeeTimeSchedulingSoftware.ForeUp) {
            const href = `https://foreupsoftware.com/index.php/booking/index/${simpleCourse.courseID}#/teetimes`;
            return (
                <Typography>The credentials used here would be the same ones used on the <Link href={href} target="_blank" rel="noopener" underline="none">course website ({simpleCourse.name})</Link>. If you don't have an account with the course you can <Link href={href} target="_blank" rel="noopener" underline="none">create one here</Link>.</Typography>
            )
        }

        if (simpleCourse?.teeTimeSchedulingSoftware === TeeTimeSchedulingSoftware.Chrono) {
            const href = "https://www.chronogolf.com/login";
            return (
                <Typography>The credentials used here would be the same ones used on the <Link href={href} target="_blank" rel="noopener" underline="none">course website ({simpleCourse.name})</Link>. If you don't have an account with the course you can <Link href={href} target="_blank" rel="noopener" underline="none">create one here</Link>.</Typography>
            )
        }
    })();

    return (
        <Box sx={{
            display: "flex",
            gap: 2,
            flexDirection: "column",
        }}>
            <WrappedTextField
                label="Username"
                type="text"
                onChange={usernameControl.onChange}
                error={usernameControl.hasError}
                helperText={usernameControl.shownErrorMessage}
                value={usernameControl.value}
            />
            <WrappedTextField
                label="Password"
                type="text"
                onChange={passwordControl.onChange}
                error={passwordControl.hasError}
                helperText={passwordControl.shownErrorMessage}
                value={passwordControl.value}
            />
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
            }}>
                <Typography><strong>Additional Details</strong></Typography>
                {credentialsContent}
            </Box>
        </Box>
    )
}

export default CourseCredentialsStep;