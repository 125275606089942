import { useState } from "react";

export function useCourseActions(
  onClickEdit: () => void,
) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const onClose = () => {
    setAnchorEl(null);
  };

  function onClickEditWrapped() {
    onClose();
    onClickEdit();
  }

  const [scheduleTeeTimeDialogIsOpen, setScheduleTeeTimeDialogIsOpen] =
    useState(false);
  function onClickScheduleTeeTime() {
    onClose();
    setScheduleTeeTimeDialogIsOpen(true);
  }

  function onCloseScheduleTeeTimeDialog() {
    setScheduleTeeTimeDialogIsOpen(false);
  }

  const [fullTestRunDialogIsOpen, setFullTestRunDialogIsOpen] = useState(false);
  function onClickRunFullTest() {
    setFullTestRunDialogIsOpen(true);
  }
  function onCloseFullTestRunDialog() {
    setFullTestRunDialogIsOpen(false);
  }

  return {
    onClick,
    anchorEl,
    open,
    onClose,
    onClickEditWrapped,
    onClickRunFullTest,
    onClickScheduleTeeTime,
    scheduleTeeTimeDialogIsOpen,
    onCloseScheduleTeeTimeDialog,
    fullTestRunDialogIsOpen,
    onCloseFullTestRunDialog,
  };
}
