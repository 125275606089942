import { useState, useLayoutEffect } from "react";
import { isEqual } from "lodash";

export interface IUseControlProps<ValueType, EventType> {
  value: ValueType;
  validatorError?: (value: ValueType) => string;
  isTouched?: boolean; // defaults to false
  skipValidateOnMount?: boolean; // default to true
  onChange: (eventType: EventType) => ValueType;
  validationDependencies?: any[];
  onChangeSideEffect?: () => void;
}

export interface IControl<ValueType, EventType> {
  value: ValueType;
  isValid: boolean;
  isTouched: boolean;
  onChange: (eventType: EventType) => void;
  resetControlState: (value?: ValueType) => void;
  isDirty: boolean;
  resetValue: () => void;
  hasError: boolean; // control is touched and error message exists
  shownErrorMessage: string; // returns error message if the control is touched
  errorMessage: string; // returns the error message even if the control is not touched
}

export function useControl<ValueType, EventType>(
  props: IUseControlProps<ValueType, EventType>,
) {
  const [initialValue, setInitialValue] = useState(props.value);
  const [value, setValue] = useState(props.value);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [isTouched, setIsTouched] = useState(!!props.isTouched);
  const [errorMessage, setErrorMessage] = useState("");

  function resetValue() {
    setValue(initialValue);
  }

  function onChange(eventType: EventType) {
    const valueAfterChange = props.onChange(eventType);
    setValue(valueAfterChange);
    setIsTouched(true);

    // The side effect here allows us to do something after the
    // control value has changed.
    if (!!props.onChangeSideEffect) {
      props.onChangeSideEffect();
    }
  }

  const additionalValidationDependencies = props.validationDependencies || [];
  useLayoutEffect(() => {
    if (!isInitialRender || !props.skipValidateOnMount) {
      const errorAfterChange = props.validatorError
        ? props.validatorError(value)
        : "";
      setErrorMessage(errorAfterChange);
    }

    if (isInitialRender) {
      setIsInitialRender(false);
    }
  }, [value, ...additionalValidationDependencies]);

  function resetControlState(value?: ValueType) {
    const valueToUse = value === undefined ? initialValue : value;
    setInitialValue(valueToUse);
    setValue(valueToUse);
    setIsTouched(false);
  }

  const hasError = isTouched && !!errorMessage;
  const shownErrorMessage = isTouched ? errorMessage : "";
  return {
    value,
    isValid: !errorMessage,
    isTouched,
    isDirty: !isEqual(initialValue, value),
    onChange,
    resetValue,
    resetControlState,
    hasError,
    shownErrorMessage,
  } as IControl<ValueType, EventType>;
}
