import { Box, Typography } from "@mui/material";
import { UnauthenticatedAppContainer } from "../../components/unauthenticatedAppContainer";
import WrappedButton from "../../components/wrappedButton";
import WrappedSnackbar from "../../components/wrappedSnackbar";
import WrappedTextField from "../../components/wrappedTextField";
import { useEnterNewPassword } from "./useEnterNewPassword";

function EnterNewPassword() {
    const {
        passwordControl,
        verificationCodeControl,
        email,
        triggerConfirmNewPassword,
        onSubmit,
        isConfirmingNewPassword,
        formIsValid,
        errorMessage,
        clearErrorMessage,
    } = useEnterNewPassword();

    return (
        <UnauthenticatedAppContainer>
            <Box
                sx={{
                    flexGrow: "1",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Box sx={{
                    width: "100%",
                    maxWidth: "600px",
                    paddingLeft: 2,
                    paddingRight: 2,
                }}>
                    <form onSubmit={onSubmit}>
                        <Box
                            sx={{
                                flexDirection: "column",
                                display: "flex",
                                gap: 2,
                            }}
                        >
                            <Box
                                sx={{
                                    marginBottom: 2,
                                    gap: 2,
                                }}
                            >
                                <Typography variant="h5">Update Password</Typography>
                            </Box>
                            <Typography>
                                A verification code has been sent to{" "}
                                <strong>{email}</strong>. To
                                reset your password, enter the verification code as
                                well as your updated password.
                            </Typography>
                            <WrappedTextField
                                value={verificationCodeControl.value}
                                label="Verification Code"
                                onChange={verificationCodeControl.onChange}
                                error={verificationCodeControl.hasError}
                            />
                            <WrappedTextField
                                value={passwordControl.value}
                                label="Password"
                                type="password"
                                onChange={passwordControl.onChange}
                                error={passwordControl.hasError}
                                helperText={passwordControl.shownErrorMessage}
                            />
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <WrappedButton
                                    onClick={triggerConfirmNewPassword}
                                    disabled={!formIsValid || isConfirmingNewPassword}
                                    showSpinner={isConfirmingNewPassword}
                                    variant="text"
                                    type="submit"
                                >
                                    Reset Password
                                </WrappedButton>
                                <WrappedSnackbar
                                    open={!!errorMessage}
                                    onClose={clearErrorMessage}
                                    message={errorMessage}
                                />
                            </Box>
                        </Box>
                    </form>
                </Box>
            </Box>
        </UnauthenticatedAppContainer>
    );
}

export default EnterNewPassword;
