import { Box, Checkbox, FormControlLabel, FormHelperText } from "@mui/material";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import BoxWithBorder from "../../../../../../components/boxWithBorder";
import WrappedButton from "../../../../../../components/wrappedButton";
import WrappedDialog from "../../../../../../components/wrappedDialog";
import WrappedSelect from "../../../../../../components/wrappedSelect";
import WrappedTextField from "../../../../../../components/wrappedTextField";
import {
  FullCourseMetadata,
  ScheduleTeeTimeBase,
  ScheduleTeeTimeCreateRequest,
} from "../../../../../../types";
import { useCommonTeeTimeDialog } from "./useCommonTeeTimeDialog";

interface ICommonTeeTimeDialogProps {
  onClose: () => void;
  fullCourseMetadata: FullCourseMetadata;
  onClickSubmitForm?: (
    scheduleTeeTimeCreateRequest: ScheduleTeeTimeCreateRequest,
  ) => void;
  isFullTestRun: boolean;
  title: string;
  actionInProgress: boolean;
  actionButtonText?: string;
  requireUsernameAndPassword?: boolean;
  disabled?: boolean;
  scheduleTeeTimeBase?: ScheduleTeeTimeBase;
  allowBookNow?: boolean;
  didUseDevelopmentTestScript?: boolean;
}

function CommonTeeTimeDialog({
  onClose,
  fullCourseMetadata,
  isFullTestRun,
  title,
  onClickSubmitForm,
  actionInProgress,
  actionButtonText,
  requireUsernameAndPassword,
  disabled,
  scheduleTeeTimeBase,
  allowBookNow,
  didUseDevelopmentTestScript,
}: ICommonTeeTimeDialogProps) {
  const {
    playerControl,
    playerOptions,
    rangeEndControl,
    rangeStartControl,
    dateControl,
    shouldDisableDate,
    teeTimeHoleControl,
    teeTimeHoleOptions,
    courseTransitControl,
    courseTransitOptions,
    paymentOptionControl,
    paymentOptions,
    canSubmitForm,
    onClickSubmitInternal,
    usernameControl,
    passwordControl,
    runWithDevelopmentScript,
    onChangeRunWithDevelopmentScript,
  } = useCommonTeeTimeDialog({
    isFullTestRun,
    requireUsernameAndPassword: !!requireUsernameAndPassword,
    fullCourseMetadata,
    onClickSubmitForm,
    scheduleTeeTimeBase,
    disabled,
    allowBookNow,
    didUseDevelopmentTestScript,
  });

  return (
    <WrappedDialog
      open={true}
      onCloseExplicit={onClose}
      title={title}
      sx={{
        width: "600px",
        padding: 0,
      }}
      rightFooterContent={
        !disabled &&
        !!actionButtonText && (
          <WrappedButton
            onClick={onClickSubmitInternal}
            disabled={!canSubmitForm || actionInProgress}
            showSpinner={actionInProgress}
          >
            {actionButtonText}
          </WrappedButton>
        )
      }
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          paddingTop: 1,
          paddingBottom: 1,
        }}
      >
        <WrappedSelect
          label="Players"
          onChange={playerControl.onChange}
          value={playerControl.value}
          options={playerOptions}
          hasError={playerControl.hasError}
          shownErrorMessage={playerControl.shownErrorMessage}
          disabled={disabled}
        />
        {playerControl.value && (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <DatePicker
                onChange={dateControl.onChange}
                shouldDisableDate={shouldDisableDate}
                label="What Day Do You Want To Golf?"
                disabled={disabled}
                value={dateControl.value}
              />
              {dateControl.shownErrorMessage && (
                <FormHelperText error>
                  {dateControl.shownErrorMessage}
                </FormHelperText>
              )}
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 1,
                paddingTop: 2,
              }}
            >
              <TimePicker
                sx={{
                  flexGrow: 1,
                }}
                label="Earliest You Want To Start Golfing"
                value={rangeStartControl.value}
                onChange={rangeStartControl.onChange}
                disabled={disabled}
              />
              <TimePicker
                sx={{
                  flexGrow: 1,
                }}
                label="Latest You Want To Start Golfing"
                value={rangeEndControl.value}
                onChange={rangeEndControl.onChange}
                disabled={disabled}
              />
            </Box>
            <WrappedSelect
              label="Holes"
              onChange={teeTimeHoleControl.onChange}
              value={teeTimeHoleControl.value}
              options={teeTimeHoleOptions}
              hasError={teeTimeHoleControl.hasError}
              shownErrorMessage={teeTimeHoleControl.shownErrorMessage}
              disabled={disabled}
            />
            <WrappedSelect
              label="Transit Option"
              onChange={courseTransitControl.onChange}
              value={courseTransitControl.value}
              options={courseTransitOptions}
              hasError={courseTransitControl.hasError}
              shownErrorMessage={courseTransitControl.shownErrorMessage}
              disabled={disabled}
            />
            <WrappedSelect
              label="Payment Option"
              onChange={paymentOptionControl.onChange}
              value={paymentOptionControl.value}
              options={paymentOptions}
              hasError={paymentOptionControl.hasError}
              shownErrorMessage={paymentOptionControl.shownErrorMessage}
              disabled={disabled}
            />
            {requireUsernameAndPassword && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 2,
                }}
              >
                <WrappedTextField
                  label="Username"
                  type="text"
                  onChange={usernameControl.onChange}
                  error={usernameControl.hasError}
                  helperText={usernameControl.shownErrorMessage}
                  value={usernameControl.value}
                  disabled={disabled}
                />
                <WrappedTextField
                  label="Password"
                  type="text"
                  onChange={passwordControl.onChange}
                  error={passwordControl.hasError}
                  helperText={passwordControl.shownErrorMessage}
                  value={passwordControl.value}
                  disabled={disabled}
                />
              </Box>
            )}
            {isFullTestRun && (
              <BoxWithBorder sx={{
                display: "flex",
                flexDirection: "row",
              }}>
                <FormControlLabel control={<Checkbox checked={runWithDevelopmentScript} onChange={onChangeRunWithDevelopmentScript} />} label={"Run With Development Script"} />
              </BoxWithBorder>
            )}
          </>
        )}
      </Box>
    </WrappedDialog>
  );
}

export default CommonTeeTimeDialog;
