import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Api } from "../../api";
import { StringValidator } from "../../classes/stringValidator";
import { useControl } from "../../hooks/useControl";

export function useSignUp() {
  const firstNameControl = useControl({
    value: "",
    onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      return event.target.value;
    },
    validatorError: (name: string) => {
      return new StringValidator()
        .required("A first name is required")
        .validate(name);
    },
  });

  const lastNameControl = useControl({
    value: "",
    onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      return event.target.value;
    },
    validatorError: (name: string) => {
      return new StringValidator()
        .required("A last name is required")
        .validate(name);
    },
  });

  const passwordControl = useControl({
    value: "",
    onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      return event.target.value;
    },
    validatorError: (name: string) => {
      return new StringValidator()
        .min(8, "A password must be at least 8 characters")
        .required("A password is required")
        .validate(name);
    },
  });

  const emailControl = useControl({
    value: "",
    onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      return event.target.value;
    },
    validatorError: (name: string) => {
      return new StringValidator()
        .email()
        .required("An email is required")
        .validate(name);
    },
  });

  const formIsValid =
    firstNameControl.isValid &&
    lastNameControl.isValid &&
    emailControl.isValid &&
    passwordControl.isValid;

  const [isCreatingUser, setIsCreatingUser] = useState(false);
  const [successSnackbarIsOpen, setSuccessSnackbarIsOpen] = useState(false);
  function onCloseSuccessSnackbar() {
    setSuccessSnackbarIsOpen(false);
  }
  useEffect(() => {
    if (!isCreatingUser) {
      return;
    }

    let didCancel = false;

    Api.user
      .signUp({
        password: passwordControl.value,
        email: emailControl.value,
        firstName: firstNameControl.value,
        lastName: lastNameControl.value,
      })
      .then(() => {
        if (didCancel) {
          return;
        }

        setSuccessSnackbarIsOpen(true);
      })
      .catch(() => {
        if (didCancel) {
          return;
        }
      })
      .finally(() => {
        if (didCancel) {
          return;
        }

        setIsCreatingUser(false);
      });

    return () => {
      didCancel = true;
    };
  }, [isCreatingUser]);

  function onClickCreate() {
    setIsCreatingUser(true);
  }

  const navigate = useNavigate();
  function onClickLogIn() {
    navigate("/login");
  }


  return {
    firstNameControl,
    lastNameControl,
    emailControl,
    passwordControl,
    formIsValid,
    isCreatingUser,
    onClickCreate,
    successSnackbarIsOpen,
    onCloseSuccessSnackbar,
    onClickLogIn,
  };
}
