import { Box, Button, ButtonProps } from "@mui/material";
import { CenterLoadingSpinner } from "../centerLoadingSpinner";

export interface IWrappedButtonProps extends ButtonProps {
  showSpinner?: boolean;
}

// Wrapping this button in a box to easier formatting
function WrappedButton({ showSpinner, ...rest }: IWrappedButtonProps) {
  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      {showSpinner && (
        <Box
          sx={{
            position: "absolute",
            height: "100%",
            width: "100%",
          }}
        >
          <CenterLoadingSpinner />
        </Box>
      )}
      <Button {...rest} />
    </Box>
  );
}

export default WrappedButton;
