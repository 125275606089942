import { Box, Checkbox, DialogTitle, FormControlLabel, Popover, Typography } from "@mui/material";
import CreateCourseDialog from "./createCourseDialog";
import { useCourses } from "./useCourses";
import EditCourseDialog from "./editCourseDialog";
import CoursesTable from "./coursesTable";
import WrappedButton from "../../../components/wrappedButton";
import FilterListIcon from '@mui/icons-material/FilterList';
import WrappedSelect from "../../../components/wrappedSelect";
import WrappedTextField from "../../../components/wrappedTextField";
import BoxWithBorder from "../../../components/boxWithBorder";

function Courses() {
  const {
    isLoadingCourses,
    filteredItems,
    createDialogIsOpen,
    onCloseCreateCourseDialog,
    headerActionButton,
    fullCourseMetadata,
    editDialogIsOpen,
    onClickEditCourse,
    onCloseEditCourseDialog,
    onCreateCourseSuccess,
    onUpdateCourseSuccess,
    filterPopoverIsOpen,
    anchorEl,
    handleCloseFilterPopover,
    handleOpenFilterPopover,
    bookingSoftwareControl,
    bookingSoftwareOptions,
    courseStatusControl,
    courseStatusOptions,
    searchControl,
    hideArchived,
    onChangeHideArchived,
  } = useCourses();

  const reactNodeInlineWithActionButton = (
    <Box>
      <WrappedButton variant="outlined" endIcon={<FilterListIcon />} onClick={handleOpenFilterPopover}>
        Filter
      </WrappedButton>
      <Popover
        open={filterPopoverIsOpen}
        anchorEl={anchorEl}
        onClose={handleCloseFilterPopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          width: "500px",
          padding: 1,
        }}>
          <DialogTitle sx={{
            padding: 0,
            margin: 0,
            paddingBottom: 2,
          }}>Filter Options</DialogTitle>
          <Box sx={{
            display: "flex",
            flexDirection: "row",
            gap: 1,
          }}>
            <WrappedSelect
              label="Booking Software"
              onChange={bookingSoftwareControl.onChange}
              value={bookingSoftwareControl.value}
              options={bookingSoftwareOptions}
              hasError={bookingSoftwareControl.hasError}
              shownErrorMessage={bookingSoftwareControl.shownErrorMessage}
            />
            <WrappedSelect
              label="Course Status"
              onChange={courseStatusControl.onChange}
              value={courseStatusControl.value}
              options={courseStatusOptions}
              hasError={courseStatusControl.hasError}
              shownErrorMessage={courseStatusControl.shownErrorMessage}
            />
          </Box>
          <WrappedTextField
            value={searchControl.value}
            label="Search Name, City, or State"
            onChange={searchControl.onChange}
            error={searchControl.hasError}
            helperText={searchControl.shownErrorMessage}
          />
          <BoxWithBorder sx={{
            display: "flex",
            flexDirection: "row",
          }}>
            <FormControlLabel control={<Checkbox checked={hideArchived} onChange={onChangeHideArchived} />} label="Hide Archived Courses" />
          </BoxWithBorder>
        </Box>
      </Popover>
    </Box>
  )

  return (
    <Box
      sx={{
        padding: 2,
      }}
    >
      <CoursesTable
        onClickEditCourse={onClickEditCourse}
        items={filteredItems}
        headerActionButton={headerActionButton}
        isLoadingCourses={isLoadingCourses}
        reactNodeInlineWithActionButton={reactNodeInlineWithActionButton}
      />
      <CreateCourseDialog
        open={createDialogIsOpen}
        onClose={onCloseCreateCourseDialog}
        onCreateCourseSuccess={onCreateCourseSuccess}
      />
      <EditCourseDialog
        open={editDialogIsOpen}
        onClose={onCloseEditCourseDialog}
        fullCourseMetadata={fullCourseMetadata}
        onUpdateCourseSuccess={onUpdateCourseSuccess}
      />
    </Box>
  );
}

export default Courses;
