import { Status as StatusType } from "../../types";
import PendingIcon from "@mui/icons-material/Pending";
import ErrorIcon from "@mui/icons-material/Error";
import SuccessIcon from "@mui/icons-material/CheckCircle";
import { Tooltip } from "@mui/material";
import { colorFromStatus } from "../../utils/colorFromStatus";

interface IStatusProps {
  status: StatusType;
  useDefaultColor?: boolean;
  tooltip?: string;
}

function Status({ status, tooltip, useDefaultColor }: IStatusProps) {
  const color = (function () {
    if (useDefaultColor) {
      return undefined;
    }

    return colorFromStatus(status);
  })();

  const icon = (function () {
    if (status === StatusType.PENDING) {
      return <PendingIcon color={color} />;
    }

    if (status === StatusType.ERROR) {
      return <ErrorIcon color={color} />;
    }

    if (status === StatusType.SUCCESS) {
      return <SuccessIcon color={color} />;
    }

    return <div />;
  })();

  if (!tooltip) {
    return icon;
  }

  return <Tooltip title={tooltip}>{icon}</Tooltip>;
}

export default Status;
