import { ChangeEvent, useEffect, useState } from "react";
import { NumberValidator } from "../../../../../../classes/numberValidator";
import { useControl } from "../../../../../../hooks/useControl";
import { DateTime } from "luxon";
import { CourseSchedulingOffset } from "../../../../../../types";
import { isEqual } from "lodash";

export function useSharedSchedulingOffset(
  schedulingOffset: CourseSchedulingOffset,
  onChangeSchedulingOffset: (
    courseSchedulingOffset: CourseSchedulingOffset,
  ) => void,
) {
  const [canSchedule, setCanSchedule] = useState(
    () => schedulingOffset.canSchedule,
  );
  const onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void = (_event, checked) => {
    setCanSchedule(checked);
  };

  const daysOffsetControl = useControl({
    value: schedulingOffset.daysOffset,
    onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      return Number(event.target.value);
    },
    validatorError: (value: null | number) => {
      return new NumberValidator()
        .required(true, "An offset is required")
        .integer(true, "Must be an integer")
        .validate(value);
    },
  });

  const timeControl = useControl({
    value: DateTime.fromObject({
      hour: schedulingOffset.hoursOffset,
      minute: schedulingOffset.minutesOffset,
      second: schedulingOffset.secondsOffset,
    }),
    onChange: (value: DateTime<boolean> | null) => {
      return value;
    },
    validatorError: (value: DateTime<boolean> | null) => {
      if (value === null) {
        return "The time is required";
      }

      if (value.minute !== 0 && value.minute !== 30) {
        return "The only allowed times are on the hour or half hour mark.";
      }

      return "";
    },
  });

  const { nextAvailableDay, timeDisplay } = (function () {
    if (timeControl.value === null || daysOffsetControl.value === null) {
      return {
        nextAvailableDay: "",
        timeDisplay: "",
      };
    }

    const nextAvailableDay = timeControl.value
      .plus({
        days: daysOffsetControl.value,
      })
      .toLocaleString(DateTime.DATE_FULL);
    const timeDisplay = timeControl.value.toLocaleString(
      DateTime.TIME_WITH_SECONDS,
    );

    return {
      nextAvailableDay,
      timeDisplay,
    };
  })();

  useEffect(() => {
    if (!timeControl.value) {
      return;
    }

    const { hour, minute, second } = timeControl.value;
    const potentialNextSchedulingOffset: CourseSchedulingOffset = {
      canSchedule,
      daysOffset: daysOffsetControl.value,
      hoursOffset: hour,
      minutesOffset: minute,
      secondsOffset: second,
    };

    if (!isEqual(potentialNextSchedulingOffset, schedulingOffset)) {
      onChangeSchedulingOffset(potentialNextSchedulingOffset);
    }
  }, [canSchedule, daysOffsetControl, timeControl]);

  return {
    canSchedule,
    onChange,
    daysOffsetControl,
    timeControl,
    nextAvailableDay,
    timeDisplay,
  };
}
