import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { cognitoUserSingleton } from "../../../classes/cognitoUserSingleton";

export function useAccountIcon() {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isOpen = Boolean(anchorEl);
    const onClickAccountIcon = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const onCloseAccountIcon = () => {
        setAnchorEl(null);
    };

    const navigate = useNavigate();
    function onClickLogout() {
        cognitoUserSingleton.cognitoUser.signOut();
        localStorage.clear();
        navigate("/login");
    }
    const mailTo = "mailto:info@greenfeepro.com";

    return {
        isOpen,
        onClickAccountIcon,
        onCloseAccountIcon,
        onClickLogout,
        anchorEl,
        mailTo,
    }
}