import axios from "axios";
import { environmentVariables } from "../environmentVariables";
import { PaymentMethod } from '@stripe/stripe-js';

export interface IStripeAPI {
    createPaymentIntent(): Promise<{
        client_secret: string,
    }>;

    getExistingPaymentMethods(): Promise<{
        paymentMethods: PaymentMethod[],
    }>;
}

export class StripeAPI implements IStripeAPI {
    public async createPaymentIntent() {
        const axiosResponse = await axios.post<{
            client_secret: string,
        }>(
            `${environmentVariables.baseAuthenticatedApiUrl}/setup-intent`,
        );
        return axiosResponse.data;
    }

    public async getExistingPaymentMethods(): Promise<{ paymentMethods: PaymentMethod[]; }> {
        const axiosResponse = await axios.get<{
            paymentMethods: PaymentMethod[],
        }>(
            `${environmentVariables.baseAuthenticatedApiUrl}/payment-methods`,
        );
        return axiosResponse.data;
    }
}
