import {
  ScheduleForeUpTeeTimeStep,
  Step,
  StepType,
} from "../../../../../../../types";

export function useScheduleForeUpTeeTimeStep(
  onClickCreateStep: (step: Step) => void,
) {
  function onSubmit() {
    const scheduleForeUpTeeTimeStep: ScheduleForeUpTeeTimeStep = {
      type: StepType.ScheduleForeUpTeeTime,
    };

    onClickCreateStep(scheduleForeUpTeeTimeStep);
  }

  return {
    onSubmit,
  };
}
