import React from "react";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ThemeProvider, PaletteMode } from "@mui/material";
import { indigo, pink, grey } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";
import { App } from "./app";
import { BreakpointProvider } from "../hooks/useBreakpoint";
import Admin from "./admin";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import Courses from "./admin/courses";
import AllScheduleTeeTimes from "./admin/allScheduledTeeTimes";
import FullTests from "./admin/fullTests";
import Users from "./admin/users";
import SignUp from "./signUp";
import Login from "./login";
import FullTest from "./admin/fullTest";
import UserTeeTimes from "./user/userTeeTimes";
import { User } from "./user";
import ScheduledTeeTime from "./admin/scheduledTeeTime";
import NotFound from "./notFound";
import { store } from '../redux/store'
import { Provider as ReduxProvider } from 'react-redux';
import { Home } from "./landingPage";
import NewUserTeeTime from "./user/newUserTeeTime";
import ResetPassword from "./resetPassword";
import EnterNewPassword from "./enterNewPassword";
import BackupTeeTimes from "./user/backupTeeTimes";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />
  },
  {
    path: "/sign-up",
    element: <SignUp />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/reset-password",
    element: <ResetPassword />
  },
  {
    path: "/enter-new-password",
    element: <EnterNewPassword />,
  },
  {
    path: "app",
    element: <App />,
    children: [
      {
        path: "admin",
        element: <Admin />,
        children: [
          {
            path: "courses",
            element: <Courses />,
          },
          {
            path: "full-tests/:fullTestPK",
            element: <FullTest />,
          },
          {
            path: "full-tests",
            element: <FullTests />,
          },
          {
            path: "scheduled-tee-times/:scheduledTeeTimePK",
            element: <ScheduledTeeTime />,
          },
          {
            path: "scheduled-tee-times",
            element: <AllScheduleTeeTimes />,
          },
          {
            path: "users",
            element: <Users />,
          },
        ],
      },
      {
        path: "user",
        element: <User />,
        children: [
          {
            path: "tee-times",
            element: <UserTeeTimes />,
          },
          {
            path: "book-tee-time",
            element: <NewUserTeeTime />,
          },
          {
            path: "backup-tee-times",
            element: <BackupTeeTimes />,
          }
        ],
      },
    ],
  },
  {
    path: "*",
    element: <NotFound />,
  }
]);

export const ColorModeContext = React.createContext({
  toggleColorMode: () => { },
});

export function WrappedApp() {
  const [mode, setMode] = React.useState<PaletteMode>(
    localStorage.getItem("mode") === "dark" ? "dark" : "light",
  );
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          const updatedMode = prevMode === "light" ? "dark" : "light";
          localStorage.setItem("mode", updatedMode);
          return updatedMode;
        });
      },
    }),
    [],
  );

  // THEME COLORS FOR LATER
  // MAIN: 6C63FF
  // DARKER: 483DFF
  // DARKEST: 2619FC
  // LIGHTER: 8F88FF
  // LIGHTEST: B9B5FF
  const theme = React.useMemo(() => {
    const theme = createTheme({
      palette: {
        mode,
        ...(mode === "light"
          ? {
            primary: {
              main: "#6C63FF",
            },
            secondary: {
              main: pink[500],
            },
            background: {
              default: grey[100],
            },
          }
          : {
            primary: {
              main: indigo["A200"],
            },
            secondary: {
              main: pink["A200"],
            },
          }),
      },
    });
    return theme;
  }, [mode]);

  const queries = {
    max768: "(max-width: 768px)",
    between768And993: "(min-width: 769px) and (max-width: 992px)",
    min993: "(min-width: 993px)",
    min1200: "(min-width: 1200px)",
  };

  return (
    <ColorModeContext.Provider value={colorMode}>
      <BreakpointProvider queries={queries}>
        <ReduxProvider store={store}>
          <ThemeProvider theme={theme}>
            <RouterProvider router={router} />
          </ThemeProvider>
        </ReduxProvider>
      </BreakpointProvider>
    </ColorModeContext.Provider>
  );
}
