import { Box, Typography } from "@mui/material";
import BoxWithBorder from "../../../../../../components/boxWithBorder";
import { OffsetByPlayers } from "../../../../../../types";
import SharedSchedulingOffset from "../sharedSchedulingOffset";
import { useCustomSchedulingOffset } from "./useCustomShedulingOffset";

interface ICustomSchedulingOffsetProps {
  schedulingOffset: OffsetByPlayers;
  onChangeSchedulingOffset: (offsetByPlayers: OffsetByPlayers) => void;
}

function CustomSchedulingOffset({
  schedulingOffset,
  onChangeSchedulingOffset,
}: ICustomSchedulingOffsetProps) {
  const {
    playerOneSchedulingOffset,
    playerTwoSchedulingOffset,
    playerThreeSchedulingOffset,
    playerFourSchedulingOffset,
    onChangePlayerOne,
    onChangePlayerTwo,
    onChangePlayerThree,
    onChangePlayerFour,
  } = useCustomSchedulingOffset(schedulingOffset, onChangeSchedulingOffset);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <BoxWithBorder
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="h6">One Player</Typography>
        <SharedSchedulingOffset
          schedulingOffset={playerOneSchedulingOffset}
          onChangeSchedulingOffset={onChangePlayerOne}
        />
      </BoxWithBorder>
      <BoxWithBorder
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="h6">Two Players</Typography>
        <SharedSchedulingOffset
          schedulingOffset={playerTwoSchedulingOffset}
          onChangeSchedulingOffset={onChangePlayerTwo}
        />
      </BoxWithBorder>
      <BoxWithBorder
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="h6">Three Players</Typography>
        <SharedSchedulingOffset
          schedulingOffset={playerThreeSchedulingOffset}
          onChangeSchedulingOffset={onChangePlayerThree}
        />
      </BoxWithBorder>
      <BoxWithBorder
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="h6">Four Players</Typography>
        <SharedSchedulingOffset
          schedulingOffset={playerFourSchedulingOffset}
          onChangeSchedulingOffset={onChangePlayerFour}
        />
      </BoxWithBorder>
    </Box>
  );
}

export default CustomSchedulingOffset;
