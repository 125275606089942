import { ReactNode, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Api } from "../../../api";
import { useBreakpoint } from "../../../hooks/useBreakpoint";
import { RootState } from "../../../redux/store";
import { BackupTeeTime, CoursePaymentOption, CourseTransitOption, ScheduleTeeTimeBase, ScheduleTeeTimeCreateRequest, ScheduleTeeTimeEditRequestV2, SimpleCourse, TeeTimeHoleOption, TeeTimeSchedulingSoftware } from "../../../types";
import { useCommonTeeTimeDialog } from "../../admin/courses/coursesTable/courseActions/commonTeeTimeDialog/useCommonTeeTimeDialog";
import { StripeError, PaymentMethod } from '@stripe/stripe-js';
import {
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import { Box, FormHelperText, Typography, Link, Checkbox, FormControlLabel, Slider } from "@mui/material";
import CourseSelector from "../courseSelector";
import WrappedRadio from "../../../components/wrappedRadio";
import WrappedTextField from "../../../components/wrappedTextField";
import BoxWithBorder from "../../../components/boxWithBorder";
import TeeTimeDetails from "../teeTimeDetails";
import { addTeeTime } from "../../../redux/teeTimes";
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { DateTime } from "luxon";

interface TabMetadata {
    title: string,
    nextDisabled: boolean,
    key: string,
    element?: ReactNode,
}

export function useNewUserTeeTime() {
    const breakpoints = useBreakpoint();
    const navigate = useNavigate();
    const [request, setRequest] = useState<ScheduleTeeTimeCreateRequest | null>(null);
    function onClickCreateOrEditTeeTime(scheduleTeeTimeCreateRequest: ScheduleTeeTimeCreateRequest) {
        setRequest(scheduleTeeTimeCreateRequest);
    }

    const [searchParams] = useSearchParams();
    const existingTeeTime = useSelector((state: RootState) => {
        const teeTimePK = searchParams.get("teeTime");
        if (teeTimePK === null) {
            return undefined;
        }

        return state.teeTimes.teeTimes.find((teeTime) => {
            return teeTime.PK.includes(teeTimePK);
        });
    });

    const simpleCourseFromRedux = useSelector((state: RootState) => {
        if (!existingTeeTime) {
            return undefined;
        }

        return state.simpleCourses.courses.find((course) => {
            return course.PK === existingTeeTime.coursePK;
        });
    });

    const dispatch = useDispatch();
    useEffect(() => {
        if (request === null) {
            return;
        }

        let didCancel = false;

        let apiCall: Promise<ScheduleTeeTimeBase>;
        if (existingTeeTime) {
            const courses: {
                PK: string,
                login: string,
                password: string,
            }[] = [{
                PK: request.coursePK,
                login: request.login || "",
                password: request.password || "",
            }];

            if (!!existingTeeTime.backupTeeTimes?.length) {
                const backup = existingTeeTime.backupTeeTimes[0];
                courses.push({
                    PK: backup.coursePK,
                    login: backup.login,
                    password: backup.password,
                });
            }
            const scheduleTeeTimeEditRequestV2: ScheduleTeeTimeEditRequestV2 = {
                ...request,
                courses,
                teeTimePKToDelete: existingTeeTime.PK,
            }

            apiCall = Api.scheduledTeeTime.editScheduledTeeTimeV2(scheduleTeeTimeEditRequestV2);
        } else {
            apiCall = Api.scheduledTeeTime.createScheduledTeeTime(request);
        }

        apiCall.then((scheduleTeeTimeBase) => {
            if (didCancel) {
                return;
            }

            if (existingTeeTime) {
                navigate("/app/user/tee-times");
            } else {
                const addTeeTimeAction = addTeeTime(scheduleTeeTimeBase);
                dispatch(addTeeTimeAction);
                const teeTimeIDOnly = scheduleTeeTimeBase.PK.replace("SCHEDULED_TEE_TIME#", "");
                navigate(`/app/user/backup-tee-times?teeTime=${teeTimeIDOnly}&isAfterTeeTimeBooking=true`);
            }
        }).finally(() => {
            if (didCancel) {
                return;
            }

            setRequest(null);
        });

        return () => {
            didCancel = true;
        }
    }, [request])

    const [simpleCourse, setSimpleCourse] = useState<SimpleCourse | undefined>(simpleCourseFromRedux);
    function onClickCourse(course: SimpleCourse) {
        setSimpleCourse(course);
        delayedOnClickNext();
    }
    function onClearCourse() {
        setSimpleCourse(undefined);
    }

    const credentials = useSelector((state: RootState) => {
        if (!simpleCourse) {
            return null;
        }

        const usernameAndPassword = state.credentials.credentials.find((credential) => {
            if (simpleCourse.teeTimeSchedulingSoftware === TeeTimeSchedulingSoftware.ForeUp) {
                const dynamoCourseID = simpleCourse.PK.replace("COURSE#", "");
                return credential.PK.includes(dynamoCourseID);
            }

            if (simpleCourse.teeTimeSchedulingSoftware === TeeTimeSchedulingSoftware.Chrono) {
                return credential.PK === "PASSWORD_CHRONO";
            }

            return false;
        });

        return usernameAndPassword || null;
    });

    const teeTimeOverrides: Partial<ScheduleTeeTimeBase> = useMemo(() => {
        // Use the existing tee time for the control overrides if
        // an existing tee time is available.
        if (!!existingTeeTime) {
            return existingTeeTime;
        }

        // Otherwise try to populate the initial login credentials for the
        // user.
        if (!!credentials) {
            return {
                login: credentials.login,
                password: credentials.password,
            }
        }

        return {
            login: "",
            password: "",
        };
    }, [credentials, existingTeeTime]);
    const commonTeeTimeFields = useCommonTeeTimeDialog({
        isFullTestRun: false,
        requireUsernameAndPassword: true,
        fullCourseMetadata: simpleCourse,
        onClickSubmitForm: onClickCreateOrEditTeeTime,
        scheduleTeeTimeBase: teeTimeOverrides,
        onClickPlayers: delayedOnClickNext,
        onClickHoles: delayedOnClickNext,
        onClickTransitOption: delayedOnClickNext,
        onClickPaymentOption: delayedOnClickNext,
        onClickDate: delayedOnClickNext,
    });
    const { onClickSubmitInternal } = commonTeeTimeFields;

    const {
        onChangeRangeWithSlider,
        fiveAM,
        eightPM,
        playerControl,
        rangeEndControl,
        rangeStartControl,
        dateControl,
        teeTimeHoleControl,
        courseTransitControl,
        paymentOptionControl,
        usernameControl,
        passwordControl,
        canSubmitForm,
        playerOptions,
        shouldDisableDate,
        teeTimeHoleOptions,
        courseTransitOptions,
        paymentOptions,
    } = commonTeeTimeFields;

    const teeTimeReminderMessage = "I have verified a card is on file with the course (if available)."
    const [hasVerifiedInformation, setHasVerifiedInformation] = useState(!!existingTeeTime);
    const onChangeHasVerifiedInformation: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void = (
        _unused,
        checked,
    ) => {
        setHasVerifiedInformation(checked);
        if (checked) {
            delayedOnClickNext();
        }
    }

    const simpleCourses = useSelector((state: RootState) => {
        return state.simpleCourses.courses;
    });
    const simpleCoursesAfterFilter = useMemo(() => {
        const hasBackup = !!existingTeeTime?.backupTeeTimes?.length;
        if (!hasBackup) {
            return simpleCourses;
        }

        return simpleCourses.filter((course) => {
            const backups = existingTeeTime.backupTeeTimes as BackupTeeTime[];
            return course.PK !== backups[0].coursePK;
        })
    }, [simpleCourses, existingTeeTime]);

    const credentialsContent = (function () {
        if (simpleCourse?.teeTimeSchedulingSoftware === TeeTimeSchedulingSoftware.ForeUp) {
            const href = `https://foreupsoftware.com/index.php/booking/index/${simpleCourse.courseID}#/teetimes`;
            return (
                <Typography>The credentials used here would be the same ones used on the <Link href={href} target="_blank" rel="noopener" underline="none">course website ({simpleCourse.name})</Link>. If you don't have an account with the course you can <Link href={href} target="_blank" rel="noopener" underline="none">create one here</Link>.</Typography>
            )
        }

        if (simpleCourse?.teeTimeSchedulingSoftware === TeeTimeSchedulingSoftware.Chrono) {
            const href = "https://www.chronogolf.com/login";
            return (
                <Typography>The credentials used here would be the same ones used on the <Link href={href} target="_blank" rel="noopener" underline="none">course website ({simpleCourse.name})</Link>. If you don't have an account with the course you can <Link href={href} target="_blank" rel="noopener" underline="none">create one here</Link>.</Typography>
            )
        }
    })();

    const cardInstructionsContent = (function () {
        if (simpleCourse?.teeTimeSchedulingSoftware === TeeTimeSchedulingSoftware.ForeUp) {
            return (
                <Box>
                    <Typography><strong>1.</strong> Log in to the <Link href={`https://foreupsoftware.com/index.php/booking/index/${simpleCourse.courseID}#/teetimes`} target="_blank" rel="noopener" underline="none">course page ({simpleCourse.name})</Link></Typography>
                    <Typography><strong>2.</strong> Verify a card is on file or <Link href={`https://foreupsoftware.com/index.php/booking/index/${simpleCourse.courseID}#/account/billing/payment-methods`} target="_blank" rel="noopener" underline="none">add a card ({simpleCourse.name})</Link> if none is present.</Typography>
                </Box>
            );
        }

        if (simpleCourse?.teeTimeSchedulingSoftware === TeeTimeSchedulingSoftware.Chrono) {
            return (
                <Box>
                    <Typography><strong>1.</strong> Log in to the <Link href="https://www.chronogolf.com/login" target="_blank" rel="noopener" underline="none">course page ({simpleCourse.name})</Link></Typography>
                    <Typography><strong>2.</strong> Verify a card is on file or <Link href={`https://www.chronogolf.com/dashboard/#/club-credit-cards/${simpleCourse.courseID}`} target="_blank" rel="noopener" underline="none">add a card ({simpleCourse.name})</Link> if none is present.</Typography>
                </Box>
            )
        }
    })();


    const [existingPaymentMethods, setExistingPaymentMethods] = useState<PaymentMethod[]>([]);
    const hasExistingPaymentMethod = existingPaymentMethods.length !== 0;
    const backup = (function () {
        const internalBackups = existingTeeTime?.backupTeeTimes
        if (!internalBackups) {
            return undefined;
        }

        if (internalBackups[0]) {
            return internalBackups[0].courseName;
        }
    })();
    const steps: TabMetadata[] = [{
        title: "Course",
        nextDisabled: !simpleCourse,
        key: "course-selector",
        element: (
            <Box sx={{
                height: "400px",
            }}>
                <CourseSelector courses={simpleCoursesAfterFilter} onClickCourse={onClickCourse} activeCoursePK={simpleCourse?.PK || ""} showFiltering onClearCourse={onClearCourse} />
            </Box>
        ),
    },
    {
        title: `Players For ${simpleCourse?.name}`,
        nextDisabled: !playerControl.isValid,
        key: "players",
        element: (
            <Box sx={{
                paddingLeft: 2,
            }}>
                <WrappedRadio
                    options={playerOptions}
                    onChange={playerControl.onChange}
                    value={playerControl.value}
                />
            </Box>
        )
    }, {
        title: "Tee Time Day",
        nextDisabled: !dateControl.isValid,
        key: "date-time-and-range",
        element: (
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                gap: 4,
            }}>
                <Box sx={{
                    paddingTop: 1,
                }}>
                    <StaticDatePicker
                        onChange={dateControl.onChange}
                        shouldDisableDate={shouldDisableDate}
                        value={dateControl.value}
                        slotProps={{
                            actionBar: {
                                sx: {
                                    display: "none",
                                }
                            },
                            toolbar: {
                                sx: {
                                    display: "none",
                                }
                            },
                        }}
                    />
                    {dateControl.shownErrorMessage && (
                        <FormHelperText error>
                            {dateControl.shownErrorMessage}
                        </FormHelperText>
                    )}
                </Box>
            </Box>
        )
    }, {
        title: "Time Range",
        nextDisabled: !rangeStartControl.isValid || !rangeEndControl.isValid,
        key: "time-range",
        element: (
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                paddingLeft: 1,
            }}>
                <Typography>Between {rangeStartControl.value.toLocaleString(DateTime.TIME_SIMPLE)} and {rangeEndControl.value.toLocaleString(DateTime.TIME_SIMPLE)}</Typography>
                <Slider
                    value={[
                        rangeStartControl.value.toSeconds(),
                        rangeEndControl.value.toSeconds(),
                    ]}
                    onChange={onChangeRangeWithSlider}
                    min={fiveAM.toSeconds()}
                    // 30 minute intervals
                    step={60 * 30}
                    max={eightPM.toSeconds()}
                    disableSwap
                />
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                }}>
                    <Typography><strong>Additional Details</strong></Typography>
                    <Typography>The larger the time range, the more likely it is to land a tee time.</Typography>
                </Box>
            </Box>
        )
    }, {
        title: "Holes",
        nextDisabled: !teeTimeHoleControl.isValid,
        key: "holes",
        element: (
            <Box sx={{
                paddingLeft: 2,
            }}>
                <WrappedRadio
                    options={teeTimeHoleOptions}
                    onChange={teeTimeHoleControl.onChange}
                    value={teeTimeHoleControl.value}
                />
            </Box>
        )
    }, {
        title: "Cart Preference",
        nextDisabled: !courseTransitControl.isValid,
        key: "transit-preferences",
        element: (
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
            }}>
                <Box sx={{
                    paddingLeft: 2,
                }}>
                    <WrappedRadio
                        options={courseTransitOptions}
                        onChange={courseTransitControl.onChange}
                        value={courseTransitControl.value}
                    />
                </Box>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                }}>
                    <Typography><strong>Additional Details</strong></Typography>
                    <Typography>At the time of booking we do our best to honor this preference. However, not all courses allow both options.</Typography>
                </Box>
            </Box>
        )
    },
    {
        title: "Payment Preference",
        nextDisabled: !paymentOptionControl.isValid,
        key: "payment-preference",
        element: (
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
            }}>
                <Box sx={{
                    paddingLeft: 2,
                }}>
                    <WrappedRadio
                        options={paymentOptions}
                        onChange={paymentOptionControl.onChange}
                        value={paymentOptionControl.value}
                    />
                </Box>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                }}>
                    <Typography><strong>Additional Details</strong></Typography>
                    <Typography>At the time of booking we do our best to honor this preference. However, not all courses allow both options.</Typography>
                </Box>
            </Box>
        )
    },
    {
        title: `Credentials For ${simpleCourse?.name}`,
        nextDisabled: !usernameControl.isValid || !passwordControl.isValid,
        key: "credentials",
        element: (
            <Box sx={{
                display: "flex",
                gap: 2,
                flexDirection: "column",
            }}>
                <WrappedTextField
                    label="Username"
                    type="text"
                    onChange={usernameControl.onChange}
                    error={usernameControl.hasError}
                    helperText={usernameControl.shownErrorMessage}
                    value={usernameControl.value}
                />
                <WrappedTextField
                    label="Password"
                    type="text"
                    onChange={passwordControl.onChange}
                    error={passwordControl.hasError}
                    helperText={passwordControl.shownErrorMessage}
                    value={passwordControl.value}
                />
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                }}>
                    <Typography><strong>Additional Details</strong></Typography>
                    {credentialsContent}
                </Box>
            </Box>
        )
    },
    !existingTeeTime && {
        title: "Verify Card On File",
        nextDisabled: !hasVerifiedInformation,
        key: "card-on-file",
        element: (
            <Box sx={{
                display: "flex",
                gap: 2,
                flexDirection: "column",
            }}>
                <BoxWithBorder sx={{
                    display: "flex",
                    flexDirection: "row",
                }}>
                    <FormControlLabel control={<Checkbox checked={hasVerifiedInformation} onChange={onChangeHasVerifiedInformation} />} label={teeTimeReminderMessage} />
                </BoxWithBorder>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                }}>
                    <Typography><strong>Verification Instructions</strong></Typography>
                    {cardInstructionsContent}
                </Box>
            </Box>
        )
    },
    {
        title: "Confirm Details",
        nextDisabled: false,
        key: "details",
        element: (
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
            }}>
                <TeeTimeDetails
                    courseName={simpleCourse?.name}
                    backup={backup}
                    players={playerControl.value}
                    date={dateControl.value}
                    rangeStart={rangeStartControl.value}
                    rangeEnd={rangeEndControl.value}
                    holeOption={teeTimeHoleControl.value as TeeTimeHoleOption}
                    transitOption={courseTransitControl.value as CourseTransitOption}
                    paymentOption={paymentOptionControl.value as CoursePaymentOption}
                    username={usernameControl.value}
                    password={passwordControl.value}
                />
            </Box>
        )
    },
    !hasExistingPaymentMethod && {
        title: "Payment Method",
        nextDisabled: false,
        key: "payment",
    }]
        // This filter helps us dynamically add or remove tabs
        .filter((tab) => !!tab)
        .map((tab) => tab as TabMetadata);

    const [activeKey, setActiveKey] = useState(() => {
        return searchParams.get("initialActiveStepKey") || steps[0].key;
    });
    const activeStepIndex = steps.findIndex((step) => {
        return step.key === activeKey;
    })
    function onClickNext() {
        const nextKey = steps[activeStepIndex + 1].key;
        setActiveKey(nextKey);
    }
    function delayedOnClickNext() {
        setTimeout(() => {
            onClickNext();
        }, 300);
    }

    function onClickBack() {
        const lastKey = steps[activeStepIndex - 1].key;
        setActiveKey(lastKey);
    }

    const activeStep = steps[activeStepIndex];
    const isLastStep = activeStepIndex === steps.length - 1;
    const disableNext = isLastStep || activeStep.nextDisabled;
    const linearProgressValue = ((activeStepIndex + 1) / steps.length * 100);

    const stripe = useStripe();
    const elements = useElements();
    const [stickWithExistingCard, setStickWithExistingCard] = useState(true);
    const onChangeStickWithExistingCard: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void = (
        _unused,
        checked,
    ) => {
        setStickWithExistingCard(checked);
    }
    useEffect(() => {
        let didCancel = false;

        Api.stripe.getExistingPaymentMethods().then((response) => {
            if (didCancel) {
                return;
            }

            setExistingPaymentMethods(response.paymentMethods);
        });

        return () => {
            didCancel = true;
        }
    }, []);

    const [error, setError] = useState("");
    function handleStripeError(error: StripeError) {
        setIsSubmittingDefaultPaymentMethod(false);
        setError(error.message || "");
    }
    function handleError(error: string) {
        setIsSubmittingDefaultPaymentMethod(false);
        setError(error);
    }
    const [isSubmittingDefaultPaymentMethod, setIsSubmittingDefaultPaymentMethod] = useState(false);

    async function onSubmitSetPaymentMethod() {
        if (isSubmittingDefaultPaymentMethod || !stripe || !elements) {
            return;
        }

        // The user already has a payment method on file and doesn't want to change it
        if (existingPaymentMethods.length && stickWithExistingCard) {
            onClickSubmitInternal();
            return;
        }

        // The user wants to change the payment method they have on file
        // TODO: Double back to this once you allow for changing the default payment method
        // if (existingPaymentMethods.length && !stickWithExistingCard) {
        // TODO: Need to delete the existing card on file here
        // }

        setIsSubmittingDefaultPaymentMethod(true);

        try {
            const {
                error: initialSubmitError,
            } = await elements.submit();

            if (initialSubmitError) {
                handleStripeError(initialSubmitError);
                return;
            }
        } catch (error: any) {
            handleError(error.message);
            return;
        }

        let clientSecret = "";
        try {
            const createPaymentIntentResponse = await Api.stripe.createPaymentIntent();
            clientSecret = createPaymentIntentResponse.client_secret;
        } catch (error: any) {
            handleError(error.message);
            return;
        }

        try {
            const {
                error: confirmSetupError,
            } = await stripe.confirmSetup({
                elements,
                // We only allow card transactions in the app which means that
                // redirecting should never be required
                redirect: "if_required",
                clientSecret,
                confirmParams: {
                    return_url: 'https://greenfeepro.com/app/user/tee-times',
                }
            });
            if (confirmSetupError) {
                handleStripeError(confirmSetupError);
                return;
            }
        } catch (error: any) {
            handleError(error.message);
            return;
        }

        // If we reach this stage, the payment method has successfully been
        // saved to the customer's stripe profile and we can move forward with
        // creating the tee time booking.
        onClickSubmitInternal();
        setIsSubmittingDefaultPaymentMethod(false);
    }

    return {
        paymentIsActive: activeKey == "payment",
        activeStepIndex,
        steps,
        onClickNext,
        onClickBack,
        activeStep,
        disableNext,
        onClickCourse,
        simpleCourse,
        isLastStep,
        disableSubmitButton: !canSubmitForm || !isLastStep,
        simpleCourses,
        createTeeTimeInProgress: request !== null,
        breakpoints,
        teeTimeReminderMessage,
        hasVerifiedInformation,
        onChangeHasVerifiedInformation,
        stripe,
        elements,
        onSubmitSetPaymentMethod,
        isSubmittingDefaultPaymentMethod,
        stripePaymentMethodError: error,
        existingPaymentMethods,
        stickWithExistingCard,
        onChangeStickWithExistingCard,
        hasExistingPaymentMethod,
        card: existingPaymentMethods[0]?.card,
        isExistingTeeTime: !!existingTeeTime,
        linearProgressValue,
        ...commonTeeTimeFields
    }
}