import { Snackbar } from "@mui/material";
import WrappedButton from "../wrappedButton";

interface IWrappedSnackbarProps {
    message: string;
    actionMetadata?: {
        text: string;
        onClick: () => void,
    }
    open: boolean;
    onClose: () => void;
}

// Wrapping this button in a box to easier formatting
function WrappedSnackbar({
    message,
    actionMetadata,
    open,
    onClose,
}: IWrappedSnackbarProps) {
    const action = !!actionMetadata ? (
        <WrappedButton onClick={actionMetadata.onClick} color="secondary" variant="outlined">
            {actionMetadata.text}
        </WrappedButton>
    ) : null;
    return (
        <Snackbar
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
            }}
            open={open}
            onClose={onClose}
            message={message}
            action={action}
        />
    );
}

export default WrappedSnackbar;
