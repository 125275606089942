import { Box, BoxProps } from "@mui/material";
import { useTheme } from "@mui/material";

interface BoxWithBorderProps extends BoxProps {
  isError?: boolean,
  isPrimary?: boolean,
}

function BoxWithBorder({ sx, isError, isPrimary, ...rest }: BoxWithBorderProps) {
  const theme = useTheme();
  const borderColor = (function () {
    if (isError) {
      return theme.palette.error.main;
    }

    if (isPrimary) {
      return theme.palette.primary.main;
    }

    return "grey.400";
  })();

  return (
    <Box
      sx={{
        gap: 1,
        border: 1,
        borderRadius: 1,
        padding: 1,
        borderColor,
        ...sx,
      }}
      {...rest}
    />
  );
}

export default BoxWithBorder;
