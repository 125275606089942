import { DateTime } from "luxon";
import { ScheduleTeeTimeBase, SimpleCourse } from "../../../types";

/**
 * This function is used in the backup tee time selection process
 * to limit the selectable courses to only those where tee times
 * have not been made available.
 */
export function teeTimeWillBeAvailableForCourse(
    teeTime: ScheduleTeeTimeBase,
    course: SimpleCourse,
    now: DateTime<true> | DateTime<false>,
) {
    const offset = course.offsetByPlayers[teeTime.players];
    if (!offset.canSchedule) {
        return false;
    }

    const {
        day: desiredTeeTimeDay,
        year: desiredTeeTimeYear,
        month: desiredTeeTimeMonth,
    } = DateTime.fromISO(teeTime.rangeStartInclusive).setZone(teeTime.timezone);
    const startOfDesiredTeeTimeDay = DateTime.fromObject({
        year: desiredTeeTimeYear,
        day: desiredTeeTimeDay,
        month: desiredTeeTimeMonth,
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
    }).setZone(teeTime.timezone);
    const momentTeeTimeBecomesAvailable = startOfDesiredTeeTimeDay.minus({
        days: offset.daysOffset,
    }).plus({
        hours: offset.hoursOffset,
        minute: offset.minutesOffset,
        second: offset.secondsOffset,
    });
    if (now > momentTeeTimeBecomesAvailable) {
        return false;
    }

    return true;
}