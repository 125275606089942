import { useNavigate } from "react-router-dom";

export function useNotFound() {
    const navigate = useNavigate();

    function onClickGoToUserPortal() {
        navigate("/app/user/tee-times");
    }

    return {
        onClickGoToUserPortal,
    }
}