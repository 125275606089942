import { Box, Typography } from "@mui/material";
import BoxWithBorder from "../../../../../../../components/boxWithBorder";
import WrappedButton from "../../../../../../../components/wrappedButton";
import WrappedSelect from "../../../../../../../components/wrappedSelect";
import WrappedTextField from "../../../../../../../components/wrappedTextField";
import { Step } from "../../../../../../../types";
import AddDeleteConditionWrapper from "./addDeleteConditionWrapper";
import { useConditionalClickElementWithTextStep } from "./useConditionalClickElementWithTextStep";

interface IClickElementWithTextProps {
    onClickCreateStep: (step: Step) => void;
}

function ConditionalClickElementWithTextStep({
    onClickCreateStep,
}: IClickElementWithTextProps) {
    const {
        elementOptions,
        holeOptions,
        playerOptions,
        conditions,
        onClickCreateStepInternal,
        canSubmitForm,
        onChangePlayers,
        onChangeHoles,
        onChangeText,
        onClickDelete,
        onAddBefore,
        onAddAfter,
    } = useConditionalClickElementWithTextStep(onClickCreateStep);

    return (
        <Box
            sx={{
                display: "flex",
                gap: 2,
                flexDirection: "column",
            }}
        >
            {conditions.map(({
                players,
                holes,
                element,
                text,
            }, index) => {
                const conditionHeader = index === 0 ? "If" : "Else If";
                return (
                    <BoxWithBorder sx={{
                        display: "flex",
                        gap: 1,
                        flexDirection: "column",
                    }}>
                        <Box sx={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}>
                            <Typography variant="h6">{conditionHeader}</Typography>

                        </Box>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 1,
                        }}>
                            <WrappedSelect
                                label="Players"
                                onChange={onChangePlayers(index)}
                                value={players}
                                options={playerOptions}
                                hasError={false}
                                shownErrorMessage=""
                            />
                            <WrappedSelect
                                label="Holes"
                                onChange={onChangeHoles(index)}
                                value={holes}
                                options={holeOptions}
                                hasError={false}
                                shownErrorMessage=""
                            />
                            <WrappedSelect
                                label="Element"
                                onChange={() => null}
                                value={element}
                                options={elementOptions}
                                hasError={false}
                                shownErrorMessage=""
                            />
                        </Box>
                        <WrappedTextField
                            label="Text"
                            onChange={onChangeText(index)}
                            value={text}
                        />
                        <Box sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                        }}>
                            <AddDeleteConditionWrapper
                                onClickAddAfter={onAddAfter(index)}
                                onClickAddBefore={onAddBefore(index)}
                                onClickDelete={conditions.length > 1 ? onClickDelete(index) : undefined}
                            />
                        </Box>
                    </BoxWithBorder>
                );
            })}
            <WrappedButton disabled={!canSubmitForm} onClick={onClickCreateStepInternal}>
                Create Step
            </WrappedButton>
        </Box>
    );
}

export default ConditionalClickElementWithTextStep;
