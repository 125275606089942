import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, Menu, MenuItem } from "@mui/material";
import ScheduleTeeTimeDialog from "./scheduleTeeTimeDialog";
import { FullCourseMetadata } from "../../../../../types";
import { useCourseActions } from "./useCourseActions";
import FullTestRunDialog from "./fullTestRunDialog";

interface ICourseActions {
  onClickEdit: () => void;
  fullCourseMetadata: FullCourseMetadata;
}

function CourseActions({ onClickEdit, fullCourseMetadata }: ICourseActions) {
  const {
    onClick,
    anchorEl,
    open,
    onClose,
    onClickEditWrapped,
    onClickRunFullTest,
    onClickScheduleTeeTime,
    scheduleTeeTimeDialogIsOpen,
    onCloseScheduleTeeTimeDialog,
    fullTestRunDialogIsOpen,
    onCloseFullTestRunDialog,
  } = useCourseActions(onClickEdit);

  return (
    <>
      <IconButton aria-label="edit" onClick={onClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={onClose}>
        <MenuItem onClick={onClickEditWrapped}>Edit</MenuItem>
        <MenuItem onClick={onClickRunFullTest}>Run Full Test</MenuItem>
        <MenuItem onClick={onClickScheduleTeeTime}>Schedule Tee Time</MenuItem>
      </Menu>
      {scheduleTeeTimeDialogIsOpen && (
        <ScheduleTeeTimeDialog
          onClose={onCloseScheduleTeeTimeDialog}
          fullCourseMetadata={fullCourseMetadata}
          onSubmitButtonSuccess={onCloseScheduleTeeTimeDialog}
          allowBookNow
        />
      )}
      {fullTestRunDialogIsOpen && (
        <FullTestRunDialog
          onClose={onCloseFullTestRunDialog}
          fullCourseMetadata={fullCourseMetadata}
        />
      )}
    </>
  );
}

export default CourseActions;
