import { Box, Paper } from "@mui/material";
import { ReactNode } from "react";
import FullScreenPage from "../fullScreenPage";

interface AppHeaderWithScrollableContentProps {
    leftHeaderContent: ReactNode;
    rightHeaderContent: ReactNode;
    children: ReactNode;
}

export function AppHeaderWithScrollableContent({
    leftHeaderContent,
    rightHeaderContent,
    children,
}: AppHeaderWithScrollableContentProps) {
    return (
        <FullScreenPage sx={{
            display: "flex",
            flexDirection: "column",
        }}>
            <Paper
                sx={{
                    flexBasis: "auto",
                    flexGrow: 0,
                    flexShrink: 0,
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: 1,
                    zIndex: 1,
                }}
                elevation={6}
            >
                <Box>
                    {leftHeaderContent}
                </Box>
                <Box>
                    {rightHeaderContent}
                </Box>
            </Paper>
            <Box
                sx={{
                    display: "flex",
                    flexGrow: 1,
                    overflow: "auto",
                    flexDirection: "column",
                }}
            >
                {children}
            </Box>
        </FullScreenPage>
    );
}