import { Box, Tab, Tabs, Typography } from "@mui/material";
import { CenterLoadingSpinner } from "../../../components/centerLoadingSpinner";
import WrappedSelect from "../../../components/wrappedSelect";
import UserTeeTime from "./userTeeTime";
import { useUserTeeTimes } from "./useUserTeeTimes";

function UserTeeTimes() {
  const { isLoading, teeTimes, teeTimeOptionControl, teeTimeOptions } = useUserTeeTimes();

  const content = (function () {
    if (isLoading) {
      return <CenterLoadingSpinner />;
    } else {
      const noTeeTimeContent = teeTimes.length === 0 && (
        <Box sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
          <Box
            sx={{
              maxWidth: "500px",
              marginTop: 3,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                textAlign: "center",
              }}
            >
              {teeTimeOptionControl.value === "All" ? (
                'You currently have no tee times. To book a tee time, click the "Book Tee Time" button'
              ) : "No tee times match the filter."}
            </Typography>
          </Box>
        </Box>
      );

      const teeTimeListContent = teeTimes.map((teeTime) => {
        return <UserTeeTime key={teeTime.PK} teeTime={teeTime} />;
      });

      return (
        <Box
          sx={{
            display: "flex",
            gap: 4,
            flexGrow: 1,
            width: "100%",
            padding: 2,
            justifyContent: "center",
            paddingBottom: 2,
          }}
        >
          <Box
            sx={{
              width: "800px",
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <WrappedSelect
              label="Status"
              onChange={teeTimeOptionControl.onChange}
              value={teeTimeOptionControl.value}
              options={teeTimeOptions}
              hasError={teeTimeOptionControl.hasError}
              shownErrorMessage={teeTimeOptionControl.shownErrorMessage}
            />
            {noTeeTimeContent}
            {teeTimeListContent}
          </Box>
        </Box>
      );
    }
  })();

  return (
    <Box
      sx={{
        flexGrow: 1,
        flexShrink: 0,
        width: "100%",
        display: "flex",
      }}
    >
      {content}
    </Box>
  );
}

export default UserTeeTimes;
