import { Box } from "@mui/material";
import { ReactNode } from "react";
import { AppHeaderWithScrollableContent } from "../../components/appHeaderWithScrollableContent";
import WrappedButton from "../../components/wrappedButton";
import { Logo } from "./logo";
import { useUnauthenticatedAppContainer } from "./useUnauthenticatedAppContainer";

interface UnauthenticatedAppContainerProps {
    children: ReactNode;
}

export function UnauthenticatedAppContainer({ children }: UnauthenticatedAppContainerProps) {
    const {
        onClickLogin,
        onClickSignUp,
        onClickLogo,
        logoSize,
    } = useUnauthenticatedAppContainer();

    return (
        <AppHeaderWithScrollableContent leftHeaderContent={(
            <Box sx={{
                ":hover": {
                    "cursor": "pointer",
                }
            }} onClick={onClickLogo}>
                <Logo size={logoSize} />
            </Box>
        )} rightHeaderContent={(
            <Box sx={{
                display: "flex",
                flexDirection: "row",
                gap: 1,
            }}>
                <WrappedButton
                    onClick={onClickSignUp}
                    variant="text"
                >
                    Sign Up
                </WrappedButton>
                <WrappedButton
                    onClick={onClickLogin}
                    variant="outlined"
                >
                    Login
                </WrappedButton>
            </Box>
        )}>
            {children}
        </AppHeaderWithScrollableContent>
    );
}