import { CourseAPI, ICourseAPI } from "./course";
import { ICredentialsAPI, CredentialsAPI } from "./credentials";
import { IOnDemandTestRunAPI, OnDemandTestRunAPI } from "./onDemandTestRuns";
import { IScheduledTeeTimeAPI, ScheduledTeeTimeAPI } from "./scheduledTeeTime";
import { ISignedUrlsAPI, SignedURLApi } from "./signedUrls";
import { IStripeAPI, StripeAPI } from "./stripe";
import { IUserAPI, UserAPI } from "./user";

export interface IApi {
  course: ICourseAPI;
  onDemandTestRun: IOnDemandTestRunAPI;
  scheduledTeeTime: IScheduledTeeTimeAPI;
  user: IUserAPI;
  signedUrls: ISignedUrlsAPI;
  stripe: IStripeAPI;
  credentials: ICredentialsAPI;
}

class ApiClass implements IApi {
  public course = new CourseAPI();
  public onDemandTestRun = new OnDemandTestRunAPI();
  public scheduledTeeTime = new ScheduledTeeTimeAPI();
  public user = new UserAPI();
  public signedUrls = new SignedURLApi();
  public stripe = new StripeAPI();
  public credentials = new CredentialsAPI();
}

export const Api = new ApiClass();
