import { Box, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { LogWithTime } from "../../types";
import BoxWithBorder from "../boxWithBorder";

interface LogDisplayProps {
    logsWithTime: LogWithTime[];
    timezone: string;
}

export function LogDisplay({
    logsWithTime,
    timezone,
}: LogDisplayProps) {
    return (
        <BoxWithBorder sx={{
            display: "flex",
            flexDirection: "column",
            padding: 0,
            gap: 0,
        }}>
            <Box sx={{
                padding: 2,
            }}>
                <Typography variant="h6">Logs</Typography>
            </Box>
            {logsWithTime.map(({ text, time }, index) => {
                const displayTime = DateTime.fromISO(time).setZone(timezone).toLocaleString(
                    DateTime.TIME_WITH_SECONDS
                );
                const backgroundColor = index % 2 === 0 ? "grey.200" : "transparent";
                return (
                    <Box key={index} sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 1,
                        backgroundColor,
                        paddingLeft: 2,
                        paddingRight: 2,
                        paddingTop: 1,
                        paddingBottom: 1,
                        borderTop: 1,
                        borderColor: "grey.400"
                    }}>
                        <Typography>
                            <strong>
                                {displayTime}

                            </strong>
                        </Typography>
                        <Typography>
                            {text}
                        </Typography>
                    </Box>
                )
            })}
        </BoxWithBorder>
    )
}