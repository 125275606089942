import { Typography } from "@mui/material";
import { useState, useCallback } from "react";
import Status from "../../../../components/status";
import WrappedTable, {
  IWrappedTableColumn,
} from "../../../../components/wrappedTable";
import { ScheduleTeeTimeBase } from "../../../../types";
import DeleteTeeTimeDialog from "./scheduledTeeTimeActions/deleteTeeTimeDialog.tsx";
import { useScheduledTeeTimesTable } from "./useScheduledTeeTimesTable";
import ScheduledTeeTimeActions from "./scheduledTeeTimeActions";
import { DateTime } from "luxon";
import { orderBy } from "lodash";
import { runTimeFromTeeTime } from "../../../../utils/runTimeFromTeeTime";

interface IScheduldTeeTimesTableProps {
  items: ScheduleTeeTimeBase[];
  isLoading: boolean;
  onDeleteSuccess: (PK: string) => void;
}

function ScheduledTeeTimesTable({
  items,
  isLoading,
  onDeleteSuccess,
}: IScheduldTeeTimesTableProps) {
  const { itemToDelete, onClickDeleteInternal, onClickCloseDialog } =
    useScheduledTeeTimesTable();
  const [columns] = useState<IWrappedTableColumn<ScheduleTeeTimeBase>[]>(() => {
    return [
      {
        text: "User",
        key: "user",
        createContent: (item: ScheduleTeeTimeBase) => {
          return <Typography>{item.username}</Typography>;
        },
      },
      {
        text: "Status",
        key: "status",
        createContent: (item: ScheduleTeeTimeBase) => {
          return <Status status={item.status} tooltip={item.bookingErrorCode} />;
        },
      },
      {
        text: "Course",
        key: "software",
        createContent: (item: ScheduleTeeTimeBase) => {
          const orCourse = !!item.backupTeeTimes?.length ? ` or ${item.backupTeeTimes[0].courseName}` : "";
          return <Typography>{item.courseName}{orCourse}</Typography>;
        },
      },
      {
        text: "Tee Time Day",
        key: "tee_time",
        createContent: (item: ScheduleTeeTimeBase) => {
          const text = DateTime.fromISO(
            item.rangeStartInclusive,
          ).toLocaleString(DateTime.DATE_SHORT);

          return <Typography>{text}</Typography>;
        },
      },
      {
        text: "Run Time",
        key: "run_time",
        createContent: (item: ScheduleTeeTimeBase) => {
          const text = (function () {
            const dateTime = runTimeFromTeeTime(item);

            return dateTime.toLocaleString(DateTime.DATETIME_FULL);
          })();

          return <Typography>{text}</Typography>;
        },
      },
      {
        text: "Actions",
        key: "actions",
        createContent: (item: ScheduleTeeTimeBase) => {
          const onClickDelete = onClickDeleteInternal(item);
          return (
            <ScheduledTeeTimeActions
              onClickDelete={onClickDelete}
              scheduleTeeTimeBase={item}
            />
          );
        },
      },
    ];
  });

  const createDataKey = useCallback((item: ScheduleTeeTimeBase) => {
    return item.PK;
  }, []);

  const orderedItems = orderBy(items, "created", ["desc"]);

  return (
    <>
      <WrappedTable
        title="Scheduled Tee Times"
        items={orderedItems}
        columns={columns}
        createDataKey={createDataKey}
        isLoading={isLoading}
        noItemsText="There are currently no scheduled tee times"
      />
      {!!itemToDelete && (
        <DeleteTeeTimeDialog
          onClose={onClickCloseDialog}
          teeTime={itemToDelete}
          onDeleteSuccess={onDeleteSuccess}
        />
      )}
    </>
  );
}

export default ScheduledTeeTimesTable;
