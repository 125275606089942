import { SelectChangeEvent } from "@mui/material";
import { useState } from "react";
import {
    Condition,
    ConditionalClickElementWithTextStep,
    Step,
    StepType,
    TeeTimeHoleOption,
} from "../../../../../../../types";

export function useConditionalClickElementWithTextStep(
    onClickCreateStep: (step: Step) => void,
) {
    const [conditions, setConditions] = useState<Condition[]>([{
        text: "",
        element: "button",
        players: "ignore",
        holes: "ignore",
    }]);
    function onChangePlayers(index: number) {
        return (event: SelectChangeEvent<1 | 2 | 3 | 4 | "ignore">) => {
            setConditions((prevConditions) => {
                return prevConditions.map((condition, compareIndex) => {
                    if (index === compareIndex) {
                        return {
                            ...condition,
                            players: event.target.value as 1 | 2 | 3 | 4 | "ignore",
                        }
                    }
                    return condition;
                })
            })
        }
    }
    function onChangeHoles(index: number) {
        return (event: SelectChangeEvent<TeeTimeHoleOption | "ignore">) => {
            setConditions((prevConditions) => {
                return prevConditions.map((condition, compareIndex) => {
                    if (index === compareIndex) {
                        return {
                            ...condition,
                            holes: event.target.value as TeeTimeHoleOption | "ignore",
                        }
                    }
                    return condition;
                })
            })
        }
    }
    function onChangeText(index: number) {
        return (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setConditions((prevConditions) => {
                return prevConditions.map((condition, compareIndex) => {
                    if (index === compareIndex) {
                        return {
                            ...condition,
                            text: event.target.value,
                        }
                    }
                    return condition;
                })
            })
        }
    }
    function onClickDelete(index: number) {
        return () => {
            setConditions((prevConditions) => {
                return prevConditions.filter((condition, compareIndex) => {
                    return index !== compareIndex;
                })
            })
        }
    }
    function onAddBefore(index: number) {
        return () => {
            setConditions((prevConditions) => {
                const copy = [...prevConditions];
                copy.splice(index, 0, {
                    text: "",
                    element: "button",
                    players: "ignore",
                    holes: "ignore",
                });
                return copy;
            })
        }
    }
    function onAddAfter(index: number) {
        return () => {
            setConditions((prevConditions) => {
                const copy = [...prevConditions];
                copy.splice(index + 1, 0, {
                    text: "",
                    element: "button",
                    players: "ignore",
                    holes: "ignore",
                });
                return copy;
            })
        }
    }

    const [elementOptions] = useState(() => {
        return [
            {
                value: "button",
                label: "button",
            },
        ];
    });

    const [playerOptions] = useState(() => {
        return [
            {
                value: "ignore",
                label: "Ignore",
            },
            {
                value: 1,
                label: "1",
            },
            {
                value: 2,
                label: "2",
            },
            {
                value: 3,
                label: "3",
            },
            {
                value: 4,
                label: "4",
            }
        ];
    });

    const [holeOptions] = useState(() => {
        return [
            {
                value: "ignore",
                label: "Ignore",
            },
            {
                value: TeeTimeHoleOption.Nine,
                label: "9",
            },
            {
                value: TeeTimeHoleOption.Eighteen,
                label: "18",
            },
        ];
    });

    function onClickCreateStepInternal() {
        const navigationStep: ConditionalClickElementWithTextStep = {
            type: StepType.ConditionalClickElementWithText,
            metadata: {
                conditions,
            },
        };

        onClickCreateStep(navigationStep);
    }

    const canSubmitForm = (function () {
        return conditions.every((condition) => {
            const hasCondition = condition.players !== "ignore" || condition.holes !== "ignore";
            const hasText = condition.text !== "";
            return hasCondition && hasText;
        }) && !!conditions.length;
    })();

    return {
        elementOptions,
        holeOptions,
        playerOptions,
        conditions,
        canSubmitForm,
        onClickCreateStepInternal,
        onChangePlayers,
        onChangeHoles,
        onChangeText,
        onClickDelete,
        onAddBefore,
        onAddAfter,
    };
}
