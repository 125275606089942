import { Box } from "@mui/material";
import WrappedButton from "../../../../../../../components/wrappedButton";
import { Step } from "../../../../../../../types";
import { useScheduleForeUpTeeTimeStep } from "./useScheduleForeUpTeeTimeStep";

interface IScheduleForeUpTeeTimeProps {
  onClickCreateStep: (step: Step) => void;
}

function ScheduleForeUpTeeTimeStep({
  onClickCreateStep,
}: IScheduleForeUpTeeTimeProps) {
  const { onSubmit } = useScheduleForeUpTeeTimeStep(onClickCreateStep);

  return (
    <form onSubmit={onSubmit}>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          flexDirection: "column",
        }}
      >
        <WrappedButton type="button" onClick={onSubmit}>
          Create Step
        </WrappedButton>
      </Box>
    </form>
  );
}

export default ScheduleForeUpTeeTimeStep;
