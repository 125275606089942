import { DateTime } from "luxon";
import { ScheduleTeeTimeBase } from "../../types";

export function runTimeFromTeeTime(teeTime: ScheduleTeeTimeBase) {
  const [_unusedOne, year, month, _unusedTwo, day, hour, seconds] =
    teeTime.SK2.split("#");

  const yearNumber = Number(year.slice(1));
  const monthNumber = Number(month.slice(1));
  const dayNumber = Number(day.slice(1));
  const hourNumber = Number(hour.slice(1));
  const secondsNumber = Number(seconds.slice(1));

  const utcTime = DateTime.utc(
    yearNumber,
    monthNumber,
    dayNumber,
    hourNumber,
    secondsNumber,
    0,
  ).toISO();

  if (utcTime === null) {
    return "";
  }

  return DateTime.fromISO(utcTime, {
    zone: teeTime.timezone,
  });
}
